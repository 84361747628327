import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Select, Button, Input, Form } from "antd";
import { bankActions } from "services/Bank/BankSlice";
import { userActions } from "services/User/UserSlice";

const AddBankAccount = ({ bankAccount, user_id, handleCancel }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const [bank, setBank] = useState();
  const [typeAccount, setTypeAccount] = useState();



  const onChangeBank = (value) => {
    setBank(value);
  };

  const onChangeBankType = (value) => {
    setTypeAccount(value);
  };



  const onFinish = (values) => {
    let payload =
    {
      payload: {
        "number": values.number,
        "bank_account_type": { "id": typeAccount },
        "bank": { "id": bank }
      },
      user_id: user_id
    }
    dispatch(bankActions.addBankAccount(payload));

  };
  useEffect(() => {
    return () => {
      dispatch(userActions.getUser(user_id));

    }
  }, [])

  return (
    <div className="modal-rol">
      <div className="modal-rol-company">
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item  >
            <Select
              placeholder={"Banco"}
              notFoundContent={null}
              style={{ width: "80%", marginTop: "1rem" }}
              onChange={onChangeBank}
            >
              <Option value={1}>Bank of America</Option>
              <Option value={2}>Banco Agrario</Option>
              <Option value={3}>Banco AV Villas</Option>
              <Option value={4}>Banco Caja Social</Option>
              <Option value={5}>Banco de Occidente</Option>
              <Option value={6}>Banco Popular</Option>
              <Option value={7}>Bancolombia</Option>
              <Option value={8}>BBVA Colombia</Option>
              <Option value={9}>Banco de Bogotá</Option>
              <Option value={10}>Citi Colombia</Option>
              <Option value={11}>Colpatria</Option>
              <Option value={12}>Davivienda</Option>
              <Option value={13}>GNB Sudameris</Option>
              <Option value={14}>ITAU CORPBANCA COLOMBIA S.A</Option>
              <Option value={15}>EDIFICIOS DAVIVIENDA  </Option>
              <Option value={16}>AVANZA  </Option>
              <Option value={17}>AV VILLAS CENTRO DE PAGOS VIRTUAL OPCION PERSONAS   </Option>
              <Option value={18}>COLPATRIA PAGOS PSE EN LINEA - CONJUNTOS RESIDENCIALES </Option>
              <Option value={19}>BANCOOMEVA </Option> 
              <Option value={20}>EFECTIVO </Option> 
              <Option value={21}>OTRO </Option> 
            </Select>
          </Form.Item>
          <Form.Item  >
            <Select
              placeholder={"tipo de cuenta"}
              notFoundContent={null}
              style={{ width: "80%", marginTop: "1rem" }}
              onChange={onChangeBankType}
            >
              <Option key={"Ahorros"} value={3}>
                Ahorros
              </Option>
              <Option key={"Corriente"} value={4}>
                Corriente
              </Option>
              <Option key={"Convenio"} value={6}>
                Convenio
              </Option>
            </Select>
          </Form.Item>
          <Form.Item label="Numero de cuenta" name="number" initialValue={0}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </Form.Item>
        </Form>

      </div>
    </div>
  );
};

export default AddBankAccount;
