import { createSlice } from "@reduxjs/toolkit";
import { error } from "highcharts";

const initialState = {
  filesModal: false, 
  status: null,
  errormsg: null,
  loading: null,
  error: false,
  alertError: false,
  serviceResponse : []
};

const filesReaderSlice = createSlice({ 
  name: "filesReader",
  initialState: initialState,
  reducers: { 
    onChangeStatus(state, { payload }) {
      state.loading = false;
      state.status = payload;
    },
    onError(state, { payload }) {
      state.loading = false;
      state.status = "error";
      state.errormsg = payload;
    }, 
  readFileMassive(state, { payload }){},
  setServiceResponse(state, { payload }){
    state.serviceResponse = payload; 
  },
  
  }
});



const filesReaderActions = filesReaderSlice.actions;
const filesReaderReducer = filesReaderSlice.reducer;

export { filesReaderActions, filesReaderReducer };
