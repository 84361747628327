import { put, all, takeLatest } from "redux-saga/effects";
import ApiService from "../../common/api/Api";
import { propertyActions } from "./PropertySlice";
import { getPropertyType } from "../../common/constants/propertyType";
import { getStatus } from "../../common/constants/status";
import { getPriority } from "../../common/constants/priority";
import { getContractType } from "../../common/constants/contractType";

function* getProperties({ payload }) {
  yield put(propertyActions.onChangeStatus("loading"));
  try {
    const { page, limit, filter } = payload;
    const response = yield ApiService.post(`/admin/prop-filter?page=${page}`, {
      ...filter,
      limit,
    });

    let { data, total } = response;
    if (response.response) {
      const pagination = { page, limit, total };
      payload.callback && payload.callback()
      yield put(propertyActions.setProperties({ data: [], pagination, filter }));
    } else {
      data = data.map((property, index) => ({
        ...property,
        key: index,
        type: getPropertyType(property?.property_type?.id)?.label,
        location: property?.localization?.location?.fullname,
        status: getStatus(property?.status_id)?.label,
      }));
      const pagination = { page, limit, total };
      yield put(propertyActions.setProperties({ data, pagination, filter }));
    }

    yield put(propertyActions.onChangeStatus("success"));
  } catch (err) {
    yield put(propertyActions.onError(err.toString()));
  }
}

function* getStatistics({ payload }) {
  yield put(propertyActions.onChangeStatus("loading"));
  try {
    const response = yield ApiService.request(
      `/property/statistics-company/${payload.userId}`,
      "GET"
    );
    yield put(propertyActions.setStatistics(response.payload));
    yield put(propertyActions.onChangeStatus("success"));
  } catch (err) {
    console.log(err);
    yield put(propertyActions.onError(err.toString()));
  }
}

function* priority({ payload }) {
  yield put(propertyActions.onChangeStatus("loading"));
  try {
    const response = yield ApiService.put(`/admin/property/${payload.id}`, {
      priority: payload.priority,
    });
    console.log(response);
    /* yield put(propertyActions.setStatistics(response.payload)) */
    yield put(propertyActions.onChangeStatus("success"));
  } catch (err) {
    console.log(err);
    yield put(propertyActions.onError(err.toString()));
  }
}

function* getUserProperties({ payload }) {
  yield put(propertyActions.onChangeStatus("loading"));
  try {
    const response = yield ApiService.get(`/admin/prop-user/${payload}`);

    const data = response.map((property, index) => ({
      ...property,
      key: index,
      title: property?.title,
      type: getPropertyType(property?.property_type?.id).label,
      location: property?.localization?.location?.fullname,
      participation:
        property?.ownership_type_id === 1 ? "Propietario" : "Inquilino",
      status: getStatus(property?.status_id)?.label,
    }));
    yield put(propertyActions.setUserProperties(data));
    yield put(propertyActions.onChangeStatus("success"));
  } catch (err) {
    yield put(propertyActions.onError(err.toString()));
  }
}

function* getProperty({ payload }) {
  yield put(propertyActions.onChangeStatus("loading"));
  try {
    const response = yield ApiService.post(`/admin/prop-info`, {
      prop_id: payload,
    });
    const data = {
      ...response,
      title: response?.title,
      type: getPropertyType(response?.property_type?.id).label,
      location: response?.localization?.location?.fullname,
      status: getStatus(response?.status_id)?.label,
      tickets: response?.tickets?.map((ticket, index) => ({
        ...ticket,
        key: index,
        title: ticket?.content[0]?.title,
        status: getStatus(ticket?.status_id).label,
        created_at: ticket.created_at,
        priority: getPriority(ticket?.priority_id).label,
      })),
      contract_initiate: response?.contract_initiate.map((contract, index) => ({
        ...contract,
        key: index,
        status: getStatus(contract?.status_id)?.label,
        contract_type: getContractType(contract?.contract_type_id).label,
        renter: `${contract?.ownership[1]?.user?.profile?.name || ""} ${contract?.ownership[1]?.user?.profile?.lastname || ""
          }`,
      })),
      contract_active: response?.contract_active.map((contract, index) => ({
        ...contract,
        key: index,
        status: getStatus(contract?.status_id)?.label,
        contract_type: getContractType(contract?.contract_type_id).label,
        renter: `${contract?.ownership[1]?.user?.profile?.name || ""} ${contract?.ownership[1]?.user?.profile?.lastname || ""
          }`,
      })),
    };

    yield put(propertyActions.getUser(response?.ownership.find(owner => owner.ownership_type_id == 1)?.user_id));
    yield put(propertyActions.getUserAdmin(response?.ownership.find(owner => owner.ownership_type_id == 7)?.user_id));
    if (data?.contract_active[0]?.ownership[1]?.user_id) {
      yield put(
        propertyActions.getUserRenter(
          data?.contract_active[0]?.ownership?.find(user => user.ownership_type_id == 2)
        )
      );
    }
    yield put(propertyActions.setProperty(data));
    yield put(propertyActions.onChangeStatus("success"));
  } catch (err) {
    yield put(propertyActions.onError(err.toString()));
  }
}

function* getUser({ payload }) {
  yield put(propertyActions.onChangeStatus("loading"));
  try {
    const response = yield ApiService.post(`/admin/user-info/${payload}`);

    const data = {
      ...response,
      name: response?.profile?.name,
      lastname: response?.profile?.lastname,
      location: response?.localization?.location?.fullname,
      genre:
        response?.profile?.genre_id === 1
          ? "Masculino"
          : response?.profile?.genre_id === 2
            ? "Femenino"
            : "Indefinido",
      payments: response?.payments?.map((payment, index) => ({
        ...payment,
        status: getStatus(payment.status_id).label,
        key: index,
      })),
    };
    if (response?.profile)
      yield put(propertyActions.setUser(data));
    yield put(propertyActions.onChangeStatus("success"));
  } catch (err) {
    yield put(propertyActions.onError(err.toString()));
  }
}

function* getUserAdmin({ payload }) {
  yield put(propertyActions.onChangeStatus("loading"));
  try {
    const response = yield ApiService.post(`/admin/user-info/${payload}`);

    const data = {
      ...response,
      name: response?.profile?.name,
      lastname: response?.profile?.lastname,
      location: response?.localization?.location?.fullname,
      genre:
        response?.profile?.genre_id === 1
          ? "Masculino"
          : response?.profile?.genre_id === 2
            ? "Femenino"
            : "Indefinido",
      payments: response?.payments?.map((payment, index) => ({
        ...payment,
        status: getStatus(payment.status_id).label,
        key: index,
      })),
    };
    yield put(propertyActions.setUserAdmin(data));
    yield put(propertyActions.onChangeStatus("success"));
  } catch (err) {
    yield put(propertyActions.onError(err.toString()));
  }
}

function* getUserRenter({ payload }) {
  yield put(propertyActions.onChangeStatus("loading"));
  try {
    const response = yield ApiService.post(`/admin/user-info/${payload.user_id}`);
    const data = {
      ...response,
      name: response?.profile?.name,
      lastname: response?.profile?.lastname,
      location: response?.localization?.location?.fullname,
      genre:
        response?.profile?.genre_id === 1
          ? "Masculino"
          : response?.profile?.genre_id === 2
            ? "Femenino"
            : "Indefinido",
      payments: response?.payments?.map((payment, index) => ({
        ...payment,
        status: getStatus(payment.status_id).label,
        key: index,
      })),
    };
    yield put(propertyActions.setUserRenter(data));
    yield put(propertyActions.onChangeStatus("success"));
  } catch (err) {
    console.log("🚀 ~ file: PropertySaga.js:195 ~ function*getUserRenter ~ err:", err)
    yield put(propertyActions.onError(err.toString()));
  }
}

function* setCurrentCreateProperty({ payload }) {
  yield put(propertyActions.onError(null));

  try {
    const response = yield ApiService.post("/admin/create-prop", payload);
    yield put(propertyActions.propertyInfo(response));
    console.log("createProperty", response);
  } catch (error) {
    yield put(propertyActions.onError(error?.data));
    console.log('este es el error', error);
  }
}

function* setFeature({ payload }) {
  const { dataFeat, id, edit } = payload;
  let response = null
  try {
    if (edit) {
      for (let i = 0; i < dataFeat.length; i++) {
        response = yield ApiService.put(
          `/admin/feature/${dataFeat[i].id}`,
          {
            quantity: dataFeat[i].quantity,
            feature_type_id: parseInt(dataFeat[i].feature_type_id),
          }
        );
      }
      if (response) yield put(propertyActions.getProperty(payload.id));

    } else {
      for (let i = 0; i < dataFeat.length; i++) {
        yield ApiService.post("/admin/feature", {
          quantity: dataFeat[i].quantity,
          feature_type_id: dataFeat[i].feature_type_id,
          entity_name: "property",
          entity_id: id,
        });
      }
    }
  } catch (error) {
    console.log(error, "hola error");
    yield put(propertyActions.onError(error.toString()));
  }
}

function* setAmenitys({ payload }) {
  let response = null;
  try {
    if (payload.edit) {
      for (let i = 0; i < payload.Amenities.length; i++) {
        response = yield ApiService.put(
          `/admin/amenity/${payload.Amenities[i].type_id}`,
          payload.Amenities[i].data
        );
      }
      if (response) yield put(propertyActions.getProperty(payload.property_id));

    } else {
      const response = yield ApiService.post(
        `/admin/amenity/${payload.property_id}/property`,
        payload.Amenities
      );
    }
  } catch (error) {
    console.log(error, "hola error");
    yield put(propertyActions.onError(error.toString()));
  }
}

function* getFeature({ payload }) {
  try {
    const response = yield ApiService.get("/admin/feature", payload);
    console.log("Caracteristicas", response);
  } catch (error) {
    console.log(error, "hola error");
    yield put(propertyActions.onError(error.toString()));
  }
}

function* getAmenitys() {
  try {
    const response = yield ApiService.get("/admin/amenity");
    console.log("Amenitys", response);
  } catch (error) {
    console.log(error, "hola error");
    yield put(propertyActions.onError(error.toString()));
  }
}

function* setPay({ payload }) {
  let { Id, pay, pets } = payload;
  try {
    const response = yield ApiService.post(`/admin/cost/${Id}/property`, pay);
    const res = yield ApiService.post(`/admin/policy/${Id}/property`, pets);
    console.log("Caracteristicas", pay, "id", Id);
  } catch (error) {
    console.log(error, "hola error");
    yield put(propertyActions.onError(error.toString()));
  }
}

function* editProperty({ payload }) {
  console.log("🚀 ~ file: PropertySaga.js:338 ~ function*editProperty ~ payload:", payload)
  try {
    const response = yield ApiService.put(
      `/admin/property/${payload.id}`,
      payload.pay
    );
    const res = yield ApiService.post(
      `/cost/${payload.id}/property`,
      payload.cost
    );
    const responseLocalization = yield ApiService.put(`/localization/${payload.id}/property`, payload.localization);
    if (payload.isOwnerTypeOne && payload.existOwner && payload?.ownership?.email) {
      const responseOwnerShip = yield ApiService.post(`/admin/change-ownership`, payload.ownership);
    } else {
      if (payload?.ownership?.email) {
        const responseOwnerShipAdd = yield ApiService.post(`/admin/add-ownership`, payload.ownership);
      }
    }
    if (payload.isOwnerTypeSeven && payload.existAdmin && payload?.ownershipAdmin?.email) {
      const responseOwnerShip = yield ApiService.post(`/admin/change-ownership`, payload.ownershipAdmin);
    } else {
      if (payload?.ownershipAdmin?.email) {
        const responseOwnerShipAdd = yield ApiService.post(`/admin/add-ownership`, payload.ownershipAdmin);
      }
    }

    if (response) yield put(propertyActions.getProperty(payload.id));
  } catch (error) {
    console.log(error, "hola error");
    yield put(propertyActions.onError(error.toString()));
  }
}

function* uploadImage({ options, fmData, setProgress, id }) {
  console.log(options, fmData, setProgress, id);
  try {
    const response = yield ApiService.uploadImage('/media', options, fmData, setProgress)
    if (response) yield put(propertyActions.getProperty(id));

  } catch (error) {
    console.log(error, "hola error");
    yield put(propertyActions.onError(error.toString()));
  }
}

function* getMedia({ payload }) {
  try {
    const response = yield ApiService.post(`/media/${payload.entityId}/${payload.entityName}`)
    if (response) {
      yield put(propertyActions.setMedia(response))
    }
  } catch (err) {
    yield put(propertyActions.onError(error.toString()));
  }
}

function* deleteImage({ id_image, id }) {
  yield put(propertyActions.onChangeStatus("loading"));
  try {
    const response = yield ApiService.deleteImage(`/media/${id_image}`)
    if (response) yield put(propertyActions.getProperty(id));
    yield put(propertyActions.onChangeStatus("success"));

  } catch (error) {
    console.log(error, "hola error");
    yield put(propertyActions.onError(error.toString()));
  }
}

function* getPropertiesRecord() {
  yield put(propertyActions.onChangeStatus("loading"));
  try {
    const response = yield ApiService.request(
      `/property/property-record`,
      "GET"
    );
    yield put(propertyActions.setPropertiesRecord(response?.payload?.propertyRecord));
    yield put(propertyActions.onChangeStatus("success"));
  } catch (err) {
    console.log('err record:', err);
    yield put(propertyActions.onError(err.toString()));
  }
}

function* editTestProperty({ payload }) {
  try {
    const response = yield ApiService.put(
      `/admin/property/${payload.id}`,
      payload.pay
    );
    if (response) yield put(propertyActions.getProperty(payload.id));
    console.log("Caracteristicas", payload);
  } catch (error) {
    console.log(error, "hola error");
    yield put(propertyActions.onError(error.toString()));
  }
}


function* deleteProperty({ payload }) {
  try {
    const response = yield ApiService.delete(`/property/${payload.propertyId}`)
    payload.callback && payload.callback()
  } catch (err) {
    yield put(propertiesActions.onError('No se pudo registrar el costo'))
  }
}

function* ActionWatcher() {
  yield takeLatest(propertyActions.getProperties, getProperties);
  yield takeLatest(propertyActions.getStatistics, getStatistics);
  yield takeLatest(propertyActions.getProperty, getProperty);
  yield takeLatest(propertyActions.getUserProperties, getUserProperties);
  yield takeLatest(propertyActions.getUser, getUser);
  yield takeLatest(propertyActions.getUserRenter, getUserRenter);
  yield takeLatest(propertyActions.getUserAdmin, getUserAdmin);
  yield takeLatest(propertyActions.priority, priority);
  yield takeLatest(
    propertyActions.setCurrentCreateProperty,
    setCurrentCreateProperty
  );
  yield takeLatest(propertyActions.getFeature, getFeature);
  yield takeLatest(propertyActions.setFeature, setFeature);
  yield takeLatest(propertyActions.setPay, setPay);
  yield takeLatest(propertyActions.getAmenitys, getAmenitys);
  yield takeLatest(propertyActions.setAmenitys, setAmenitys);
  yield takeLatest(propertyActions.editProperty, editProperty);
  yield takeLatest(propertyActions.uploadImage, uploadImage);
  yield takeLatest(propertyActions.deleteImage, deleteImage);
  yield takeLatest(propertyActions.getPropertiesRecord, getPropertiesRecord);
  yield takeLatest(propertyActions.editTestProperty, editTestProperty);
  yield takeLatest(propertyActions.getMedia, getMedia)
  yield takeLatest(propertyActions.deleteProperty, deleteProperty)

}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
