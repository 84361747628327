
import React from 'react';
import {
  Document,
  Text,
  Page,
  StyleSheet,
  Image,
  View,
} from "@react-pdf/renderer";
import TableRow from './TableRow';
import ItemsTable from './ItemsTable';
import TableFooters from './TableFooters';
import { formmatterCurrency } from "common/utilities";
const styles = {
  page: {
    padding: 30,
    fontFamily: 'Helvetica',
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: 'justify'
  },
  sectionHeader: {
    marginBottom: 20
  },
  sectionInfoGen: {
    display: 'flex',
    flexDirection: 'column'
  },
  sectionInfo: {
    marginBottom: 20
  },
  senderInfo: {
    fontSize: 12,
    marginBottom: 10
  },
  date: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'right'
  },
  receiverInfo: {
    fontSize: 12,
    marginBottom: 10
  },
  greeting: {
    fontSize: 12,
    marginBottom: 10
  },
  bodyText: {
    fontSize: 12,
    marginBottom: 10
  },
  closing: {
    fontSize: 12,
    marginTop: 30
  },
  image: {
    width: '100px',   // Ajusta el tamaño según sea necesario
    height: '100px',  // Ajusta el tamaño según sea necesario
    marginBottom: '10px', // Espacio entre la imagen y el siguiente contenido
  },
};

const getisYimg = (data) => {
  let logo_url = data.company_image;
  let response = false;
  if (logo_url.includes("v4A4+17r8F+E+AAQBvR7GbWNuJLAAAAABJRU5ErkJggg==")) {
    response = true;
  } 
  return response;
}
const getimg = (data) => {
  let logo_url = data.company_image;
  if (logo_url.includes("v4A4+17r8F+E+AAQBvR7GbWNuJLAAAAABJRU5ErkJggg==")) {
    logo_url = data.company_name;
  } 
  return logo_url;
}

function PDF({ data }) { 
  return (<Document>
    <>
      <Page style={styles.page}>
        <View style={styles.sectionHeader}>
          <View style={styles.sectionInfoGen}>
            <View style={styles.sectionInfo}>
              <Text style={styles.senderInfo}>
                {getisYimg(data) ? (
                  // Si es una imagen base64, mostrar la imagen
                  <Text style={styles.imageName}>{getimg(data)}</Text> 
                ) : (
                  // Si no es base64, mostrar el nombre de la imagen
                  <Image style={styles.image} src={getimg(data)} />
                )} 
              </Text>
              <Text style={styles.date}>
                {"\n"}
                {data.approved_at}
              </Text>

              <Text style={styles.receiverInfo}>
                {"\n"}
                {"\n"}
                {data.renter.name + ' ' + data.renter.lastname}{"\n"}
                {data.prop_title}{"\n"}
                {data.localization.address}
              </Text>

              <Text style={styles.greeting}>
              </Text>

              <Text style={styles.bodyText}>
                {"\n"}Esperamos te encuentres muy bien y decirte que ha sido un verdadero placer tenerte como arrendatari@ durante el último año. Nos contactamos contigo en esta 
                oportunidad para comentarte que el contrato de arrendamiento de la propiedad {data.prop_title} ubicada en {data.localization.address}, 
                ha sido renovado por un nuevo período que inicia el {data.date_init}.{"\n"}


                {"\n"}Por lo anterior, te informamos que el nuevo valor de arrendamiento para esta nueva vigencia corresponde a la suma de 
                {formmatterCurrency(data.valueCanonNew + data.valueAdminNew)}, en donde  el valor del canon  es de {formmatterCurrency(data.valueCanonNew)},  
                y la administración, si aplica, es de {formmatterCurrency(data.valueAdminNew)}.{"\n"}

                {"\n"}Por favor, no dudes en comunicarte con nosotros si tienes alguna pregunta o inquietud con relación a esta notificación. Te recordamos que podemos 
                atenderte a través del correo servicioalcliente@yampi.com o wa.link/6pb1qe{"\n"}

                {"\n"}Agradecemos de antemano tu atención. ¡Muchas gracias por tu tiempo!{"\n"}
                
              </Text>

              <Text style={styles.closing}>
                {"\n"}Un cordial saludo,{"\n"}
                {data.company_name}{"\n"}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </>
  </Document >);
}

export default PDF;
