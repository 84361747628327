import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Spin, Dropdown, Row, Col, PageHeader, Modal, Menu, Button } from 'antd'
import { MoreOutlined } from '@ant-design/icons';
import ModalReport from './sections/modalReport';


import { shoppingActions } from 'services/Shopping/ShoppingSlice'
import { columns } from './constans'
import { Filter } from './sections/Filter'
import { ModalDispersion } from './sections/ModalDispersion'

export const Shopping = () => {

  const dispatch = useDispatch()
  const { shoppings, status, pagination, filterShoppings } = useSelector(state => state.shopping)
  const { company } = useSelector((state) => state.user);
  const { page, limit, total } = pagination
  const [cart, setCart] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const currentColumn = columns(isModalVisible, setIsModalVisible, cart, setCart);
  const [isModalVisibleReport, setIsModalVisibleReport] = useState(false); // Variable para controlar la visibilidad del modal


  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    dispatch(shoppingActions.setShoppingDataDis(undefined))
    setIsModalVisible(false);
  };

  useEffect(() => {
    return () => {
      dispatch(shoppingActions.setShoppings({
        data: [],
        pagination: {
          total: 1,
          page: 1,
          limit: 10
        }
      }))
    }
  }, [])

  const handlePagination = (page, limit) => {
    dispatch(shoppingActions.getShoppings({ page, limit, date_filter: { ...filterShoppings, inmo_id: filterShoppings.inmo_id } }))
  }

  const tablePagination = {
    onChange: handlePagination,
    hideOnSinglePage: true,
    current: page,
    pageSize: limit,
    total: total
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsModalVisibleReport(true)}>Descargar reporte de recaudos</Menu.Item>
    </Menu>
  );

  return (
    <div className="user">
      <PageHeader
        className="site-page-header"
        title=""
        style={{ color: '#888888', paddingLeft: 0, paddingRight: 0 }}
      >
        <Filter />
      </PageHeader>
      <Spin spinning={status === "loading"}>
        <Table
          title={() =>
            <>
              <Row justify="space-between">
                <Col flex={1} > <span style={{ color: '#0d0c0c', fontSize: 18, fontWeight: 500, margin: '25px 10px' }}>Lista de Recaudos</span></Col>
                <Dropdown
                  overlay={menu}
                  placement="bottomLeft"
                  arrow={{ pointAtCenter: true }}
                  trigger={['click']}
                >
                  <Button type='text'><MoreOutlined style={{ fontSize: '20px' }} /></Button>
                </Dropdown>
              </Row>
            </>
          }
          columns={currentColumn}
          dataSource={shoppings}
          scroll={{ x: 400 }}
          pagination={tablePagination}
        />
      </Spin>
      {isModalVisible &&
        <Modal
          title={"Informacion de dispersion"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1100}
          footer={null}
        >
          <ModalDispersion
            cart={cart}
          />
        </Modal>
      }
      <Modal
        title={"Descargar Reporte de Propiedades"}
        visible={isModalVisibleReport}
        onCancel={() => setIsModalVisibleReport(false)}
        footer={null}
      >
        <ModalReport handleCancel={() => setIsModalVisibleReport(false)} />
      </Modal>
    </div>
  )
}