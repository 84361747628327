import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuthenticated: false,
  status: null,
  errormsg: null,
  token: null,
  user: null,
  tokenExpo: null,
  currentUserData: null,
  confirmCode: null,
  role: null,
  loading: null,
  company: null,
  loadingGroup: {
    profile: false
  }
}

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login(state, { }) {
      state.loading = true
    },
    checkIsAuth() { },
    onError(state, { payload }) {
      state.status = 'error'
      state.loading = false
      state.errormsg = payload
    },
    setToken(state, { payload }) {
      state.token = payload
      state.loading = false
    },
    setUserData(state, { payload }) {
      state.user = payload
    },
    companyByUser(state, { payload }) {
      state.loading = true;
    },
    setCompanyByUser(state, { payload }) {
      state.company = payload;
      state.loading = false;
    },
    logout() {
      return initialState
    }
  }
})

const authActions = authSlice.actions
const authReducer = authSlice.reducer

export { authActions, authReducer }