import { apiUrl, apiUrlV2 } from "../config/Environments"
import { StorageService } from "../storage/storage-service"
import axios from "axios";

class Api {

  async post(endpoint, data) {
    const token = await StorageService.getItem('token')
    const response = await fetch(`${apiUrl}${endpoint}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'BearerToken': token
      }
    })
    const body = await response.json()

    if (response.status === 200) {
      return body
    } else {
      throw body
    }
  }

  async put(endpoint, data) {
    const token = await StorageService.getItem('token')
    const response = await fetch(`${apiUrl}${endpoint}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'BearerToken': token
      }
    })
    const body = await response.json()
    if (response.status === 200) {
      return body
    } else {
      throw Error(body.message)
    }
  }

  async patch(endpoint, data) {
    const token = await StorageService.getItem('token')
    endpoint = new URL(`${apiUrl}${endpoint}`)
    if (data)
      Object.keys(data).forEach(key => endpoint.searchParams.append(key, data[key]))
    return fetch(endpoint, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'BearerToken': token
      }
    })
      .then(response => {
        return response.json().then(body => {
          if (response.status === 200) {
            return body
          } else {
            throw Error(body.message)
          }
        })
      })
  }

  async get(endpoint, data) {
    const token = await StorageService.getItem('token')
    endpoint = new URL(`${apiUrl}${endpoint}`)
    if (data)
      Object.keys(data).forEach(key => endpoint.searchParams.append(key, data[key]))

    return axios.get(endpoint, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'BearerToken': token
      }
    }).then((response) => {
      if (response.status == 200) {
        return response.data
      } else {
        throw Error(body.message)
      }
    });

  }

  async getFile(endpoint, data, params, tokenSend) {
    const token = await StorageService.getItem('token');
    endpoint = new URL(`${apiUrl}${endpoint}`);
    if (params)
      Object.keys(params).forEach(key => endpoint.searchParams.append(key, params[key]))

    return fetch(endpoint, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + (tokenSend || token),
        'BearerToken': (tokenSend || token)
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error en la solicitud');
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${data?.name}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
  }

  async getBackNew(endpoint, data) {
    const token = await StorageService.getItem('token')
    endpoint = new URL(`${apiUrlV2}${endpoint}`)
    if (data)
      Object.keys(data).forEach(key => endpoint.searchParams.append(key, data[key]))
    return fetch(endpoint, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'BearerToken': token
      }
    })
      .then(response => {
        return response.json().then(body => {
          if (response.status === 200) {
            return body
          } else {
            throw Error(body.message)
          }
        })
      })
  }

  async request(endpoint, method, data) {
    const token = await StorageService.getItem('token');
    return fetch(`${apiUrlV2}${endpoint}`, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'BearerToken': token
      }
    }).then(response => {
      return response.json().then(body => {
        if (response.status === 200) {
          return body
        } else {
          throw Error(body.message)
        }
      })
    })
  }


  async requestPost(endpoint, data) {
    const token = await StorageService.getItem('token')
    const response = await fetch(`${apiUrlV2}${endpoint}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'BearerToken': token
      }
    })
    const body = await response.json()
    if (response.status === 200 || response.status === 201) {
      return body
    } else {
      console.log(body)
      throw Error(body.message)
    }
  }

  async requestPostFormData(endpoint, data) {
    const token = await StorageService.getItem('token');

    // Crear una instancia de FormData
    const formData = new FormData();

    // Agregar todos los campos a FormData
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    // Realizar la petición
    const response = await fetch(`${apiUrlV2}${endpoint}`, {
      method: 'POST',
      body: formData, // Usar FormData como cuerpo de la solicitud
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    });
    const body = await response.json();
    if (response.status === 200 || response.status === 201) {
      return body;
    } else {
      console.log(body);
      throw Error(body.message);
    }
  }

  async requestPut(endpoint, data) {
    const token = await StorageService.getItem('token')
    const response = await fetch(`${apiUrlV2}${endpoint}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'BearerToken': token
      }
    })
    const body = await response.json()
    if (response.status === 200 || response.status === 201) {
      return body
    } else {
      console.log(body)
      throw Error(body.message)
    }
  }

  async requestDelete(endpoint, data) {
    const token = await StorageService.getItem('token')
    const response = await fetch(`${apiUrlV2}${endpoint}`, {
      method: 'DELETE',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'BearerToken': token
      }
    })
    const body = await response.json()
    if (response.status === 200 || response.status === 201) {
      return body
    } else {
      console.log(body)
      throw Error(body.message)
    }
  }

  async uploadImage(options, fmData, setProgress) {
    const { onSuccess, onError, onProgress } = options;
    const token = await StorageService.getItem('token')

    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        "Authorization": "Bearer " + token,
        "BearerToken": token,
      },
      method: "POST",
      body: fmData,
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    try {
      const res = await fetch(`${apiUrl}/media`, config);
      onSuccess("Ok");
      console.log("server res: ", res);
      return res
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
    }
  }

  async deleteImage(id) {

    const token = await StorageService.getItem('token')
    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        "Authorization": "Bearer " + token,
        "BearerToken": token,
      },
      method: "DELETE",
    };

    try {
      const res = await fetch(`${apiUrl}/media/${id}`, config);
      return res;
      console.log("server res: ", res);
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
    }
  }

  async delete(endpoint, data) {
    const token = await StorageService.getItem('token')

    const response = await fetch(`${apiUrl}${endpoint}`, {
      method: 'DELETE',
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })

    const body = await response.json()
    if (response.status === 200 || response.status === 201) {
      return body
    } else if (response.status === 401) {
      StorageService.clear()
      store.dispatch(authActions.logout())
      throw Error(body.message)
    } else {
      throw Error(body.message)
    }
  }
}






export default new Api()