import { put, all, takeLatest } from "redux-saga/effects";
import ApiService from "../../common/api/Api";
import { PaymentSendActions } from "./PaymentSendSlice";


function* getPaymentSend({ payload }) {
  console.log("🚀 ~ function*getPaymentSend ~ payload:", payload)
  try {
    const response = yield ApiService.request(`/payment/company/${payload.companyId}?day=${payload.day}&limit=${payload.limit}&page=${payload.page}`)
    const pagination = { page: response.meta.currentPage, limit: payload.limit, total: response.meta.totalItems }
    yield put(PaymentSendActions.SetPaymentSend({ items: response.items, pagination }))
  } catch (err) {
    yield put(
      PaymentSendActions.onError("No se pudo actualizar el descuento")
    );
  }
}

function* sendMenssage({ payload }) {
  try {
    const response = yield ApiService.requestPost(`/payment/send_menssage`, payload)
    yield put(PaymentSendActions.setSendMenssage(response))
  } catch (err) {
    yield put(
      PaymentSendActions.onError("No se pudo actualizar el descuento")
    );
  }
}

function* getConciliation({ payload }) {
  try {
    const response = yield ApiService.requestPost(`/payment/conciliation`, payload)
    yield put(PaymentSendActions.setConciliation(response.payload))
  } catch (err) {
    yield put(
      PaymentSendActions.onError("No se pudo actualizar el descuento")
    );
  }
}


function* ActionWatcher() {
  yield takeLatest(PaymentSendActions.getPaymentSend, getPaymentSend)
  yield takeLatest(PaymentSendActions.sendMenssage, sendMenssage)
  yield takeLatest(PaymentSendActions.getConciliation, getConciliation)
}

export default function* () {
  yield all([ActionWatcher()]);
}

