import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import TableRow from "./TableRow";
import TableHeaders from "./TableHeaders";
import TableFooters from "./TableFooters";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
const ItemsTable = ({ data }) => (
  <View  > 
      <>
        <TableHeaders item={data}/>
        <TableRow item={data} /> 
      </> 
  </View>
);

export default ItemsTable;