import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Row, Statistic, Col, Card, Modal } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { Liquid } from "@ant-design/charts";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from "highcharts/modules/exporting";

import { permissionActions } from "../../../services/Permission/PermissionSlice";
import { propertyActions } from "../../../services/Property/PropertySlice";
import { ShowButton } from "../components/ShowButton/ShowButton";
import { TableProperties } from "./components/TableProperties";
import { formmatterCurrency } from "../../../common/utilities";
import moment from "moment";
import "moment/locale/es";

const HighchartsWrapper = (props) => {
  let options = {
    chart: {
      type: props.type ? props.type : "spline",
    },
    title: {
      text: props.title,
    },
    xAxis: {
      categories: props.categories,
    },
    yAxis: {
      title: {
        text: props.title,
      },
    },
    tooltip: {
      headerFormat: "{point.x:.1f} <br>",
      pointFormat: "{point.y},  {point.cost}",
      shared: false,
      formmatterCurrency: function () {
        return (
          this.point.series.name +
          " : " +
          this.point.y +
          "<br/>Valor: " +
          formmatterCurrency(this.point.cost) +
          "<br/>Fecha: " +
          this.point.category
        );
      },
    },
    plotOptions: {
      spline: {
        dataLabels: {
          enabled: true,
          formmatterCurrency: function () {
            return this.point.cost ? formmatterCurrency(this.point.cost) : "";
          },
        },
        /* enableMouseTracking: false */
      },
      column: {
        dataLabels: {
          enabled: true,
          formmatterCurrency: function () {
            return this.point.y ? this.point.y : "";
          },
        },
      },
    },
    series: props.chartData,
  };

  return (
    <div style={{ flex: 1 }}>
      {props.chartData.length > 1 ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          oneToOne={true}
        />
      ) : (
        <span></span>
      )}
    </div>
  );
};

export const Home = () => {
  HC_exporting(Highcharts);
  const dispatch = useDispatch();
  const { propertiesStatistics, propertiesRecord } = useSelector((state) => state.property);
  const { user } = useSelector((state) => state.auth);
  const { company } = useSelector((state) => state.user);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filter, setFilter] = useState(undefined);
  const [series, setSeries] = useState([]);
  const [seriesTwo, setSeriesTwo] = useState([
    { name: "arrendadas", data: [{ cost: 24, y: 71125095 }, { cost: 28, y: 79378095 }, { cost: 33, y: 83728095 }, { cost: 37, y: 104928095 }, { cost: 47, y: 117979995 }, { cost: 47, y: 117979995 }, { cost: 48, y: 118443226 }, { cost: 51, y: 126176226 }, { cost: 55, y: 142301841 }, { cost: 55, y: 143171841 }, { cost: 55, y: 142806708 }, { cost: 51, y: 149039429 }, { cost: 62, y: 150039429 }, { cost: 65, y: 168039429 }, { cost: 70, y: 185895160 }, { cost: 73, y: 194020995 }, { cost: 73, y: 201800457 }] },
    { name: "proceso de arriendo", data: [0, 0, 0, 0, 0, 3, 1, 5, 5, 4, 4, 7, 7, 7, 2, 2, 2] },
    { name: "publicadas", data: [{ cost: 35 + 14, y: 155139659 }, { cost: 52 + 14, y: 388389659 }, { cost: 76 + 14, y: 438167659 }, { cost: 77, y: 424718000 }, { cost: 79, y: 241068000 }, { cost: 85, y: 261658000 }, { cost: 86, y: 268208000 }, { cost: 72, y: 231508000 }, { cost: 72, y: 243181025 }, { cost: 70, y: 238431025 }, { cost: 72, y: 243116135 }, { cost: 61, y: 214020000 }, { cost: 61, y: 214020000 }, { cost: 61, y: 214020000 }, { cost: 65, y: 218825301 }, { cost: 65, y: 217575868 }, { cost: 64, y: 211685868 }] },
    { name: "sin publicar", data: [18, 23, 21, 23, 24, 23, 22, 21, 23, 26, 26, 26, 26, 25, 25, 25, 26] }]
  );
  const seriesThree = [
    { name: "arrendadas", data: [{ y: 24, cost: 71125095 }, { y: 28, cost: 79378095 }, { y: 33, cost: 83728095 }, { y: 37, cost: 104928095 }, { y: 47, cost: 117979995 }, { y: 47, cost: 117979995 }, { y: 48, cost: 118443226 }, { y: 51, cost: 126176226 }, { y: 55, cost: 142301841 }, { y: 55, cost: 143171841 }, { y: 55, cost: 142806708 }, { y: 51, cost: 149039429 }, { y: 62, cost: 150039429 }, { y: 65, cost: 168039429 }, { y: 70, cost: 185895160 }, { y: 73, cost: 194020995 }, { y: 73, cost: 201800457 }] },
    { name: "proceso de arriendo", data: [0, 0, 0, 0, 0, 3, 1, 5, 5, 4, 4, 7, 7, 7, 2, 2, 2] },
    { name: "publicadas", data: [{ y: 35 + 14, cost: 155139659 }, { y: 52 + 14, cost: 388389659 }, { y: 76 + 14, cost: 438167659 }, { y: 77, cost: 424718000 }, { y: 79, cost: 241068000 }, { y: 85, cost: 261658000 }, { y: 86, cost: 268208000 }, { y: 72, cost: 231508000 }, { y: 72, cost: 243181025 }, { y: 70, cost: 238431025 }, { y: 72, cost: 243116135 }, { y: 61, cost: 214020000 }, { y: 61, cost: 214020000 }, { y: 61, cost: 214020000 }, { y: 65, cost: 218825301 }, { y: 65, cost: 217575868 }, { y: 64, cost: 211685868 }] },
    { name: "sin publicar", data: [18, 23, 21, 23, 24, 23, 22, 21, 23, 26, 26, 26, 26, 25, 25, 25, 26] }]

  const [categories, setCategories] = useState(['jul. 20', 'ago. 20', 'sep. 20', 'oct. 20', 'nov. 20', 'dic. 20', 'ene. 21', 'feb. 21', 'mar. 21', 'abr. 21', 'may. 21', 'jun. 21', 'jul. 21', 'ago. 21', 'sep. 21', 'oct. 21', 'nov. 21', 'dic. 21', 'ene. 22', 'feb. 22', 'mar. 22', 'abr. 22', 'may. 22', 'jun. 22']);
  const [categoriesTwo, setCategoriesTow] = useState(['feb. 21', 'mar. 21', 'abr. 21', 'may. 21', 'jun. 21', 'jul. 21', 'ago. 21', 'sep. 21', 'oct. 21', 'nov. 21', 'dic. 21', 'ene. 22', 'feb. 22', 'mar. 22', 'abr. 22', 'may. 22', 'jun. 22']);

  const formmatterCurrency = (input) =>
    new Intl.NumberFormat("en", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
    })
      .format(input)
      .replace("COP", "$")
      .replace(/,b/gi, ".");

  const showModal = (id) => {
    setIsModalVisible(true);
    setFilter(id);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    dispatch(permissionActions.getModules());
    dispatch(propertyActions.getPropertiesRecord());
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(propertyActions.getStatistics({ userId: user.id }));
    }
  }, [user]);

  useEffect(() => {
    let saveProperties = { name: "sin publicar", data: [] };
    let accepted = { name: "arrendadas", data: [] };
    let publicProperty = { name: "publicadas", data: [] };
    let openProperty = { name: "proceso de arriendo", data: [] };
    dataX("months", "MMM YY", 10).map((categorie) => {
      publicProperty.data.push({ y: 0, cost: 0 });
      saveProperties.data.push({ y: 0, cost: 0 });
      accepted.data.push({ y: 0, cost: 0 });
      openProperty.data.push(0);
    });

    if (propertiesStatistics.save.properties.length) {
      propertiesStatistics.save.properties.map((property) => {
        let newDate = moment(property.created_at).format("MMM YY");
        let isCategorie = dataX("months", "MMM YY", 10).findIndex((categorie) => {
          if (categorie == newDate) {
            return categorie;
          }
        });

        if (isCategorie != -1)
          saveProperties.data[isCategorie] = {
            y: saveProperties.data[isCategorie].y + 1,
          };
      });
    }

    if (propertiesStatistics.publicProperty.properties.length) {
      propertiesStatistics.publicProperty.properties.map((property) => {
        let newDate = moment(property.published_at).format("MMM YY");

        let isCategorie = dataX("months", "MMM YY", 10).findIndex((categorie) => {
          if (categorie == newDate) {
            return categorie;
          }
        });
        let currentProperty = property.contract[0];
        if (isCategorie != -1)
          publicProperty.data[isCategorie] = {
            cost: currentProperty.cost + publicProperty.data[isCategorie].cost,
            y: publicProperty.data[isCategorie].y + 1,
          };
      });
    }

    if (propertiesStatistics.accepted.properties.length) {
      propertiesStatistics.accepted.properties.map((property, i) => {
        let newDate = moment(property.contract_updated_at).format("MMM YY");

        let isCategorie = dataX("months", "MMM YY", 10).findIndex((categorie) => {
          if (categorie == newDate) {
            return categorie;
          }
        });
        let currentProperty = property.contract.find((contract) => {
          return contract.status_id >= 12 && contract.status_id != 14;
        });
        if (isCategorie != -1)
          accepted.data[isCategorie] = {
            cost: currentProperty?.cost + accepted?.data[isCategorie]?.cost,
            y: accepted?.data[isCategorie]?.y + 1,
          };
      });
    }
    if (propertiesStatistics.open.properties.length) {
      propertiesStatistics.open.properties.map((property) => {
        let newDate = moment(property.contract_updated_at).format("MMM YY");

        let isCategorie = dataX("months", "MMM YY", 10).findIndex((categorie) => {
          if (categorie == newDate) {
            return categorie;
          }
        });
        if (isCategorie != -1) openProperty.data[isCategorie]++;
      });
    }

    setSeries([accepted, openProperty, publicProperty, saveProperties]);
  }, [propertiesStatistics]);

  const configOne = {
    percent: propertiesStatistics.accepted.acum / 500000000,
    statistic: {
      content: {
        style: {
          fontSize: 14,
          width: 200,
          fill: "black",
          lineHeight: 1,
        },
      },
    },
  };

  const config = {
    percent: propertiesStatistics.accepted.total / 300,
    statistic: {
      content: {
        style: {
          fontSize: 14,
          width: 200,
          fill: "black",
          lineHeight: 1,
        },
      },
    },
  };

  const dataSource = [
    {
      key: "1",
      Item: "Total propiedades",
      Valor: formmatterCurrency(propertiesStatistics.total.acum),
      Cantidad: propertiesStatistics.total.total,
      Promedio: formmatterCurrency(propertiesStatistics.total.prom),
    },
    {
      key: "2",
      Item: "Propiedades arrendadas",
      Valor: formmatterCurrency(propertiesStatistics.accepted.acum),
      Cantidad: propertiesStatistics.accepted.total,
      Promedio: formmatterCurrency(propertiesStatistics.accepted.prom),
    },
    {
      key: "3",
      Item: "Propiedades en proceso de arriendo",
      Valor: formmatterCurrency(propertiesStatistics.open.acum),
      Cantidad: propertiesStatistics.open.total,
      Promedio: formmatterCurrency(propertiesStatistics.open.prom),
    },
    {
      key: "4",
      Item: "Propiedades publicadas para encontrar inquilino",
      Valor: formmatterCurrency(propertiesStatistics.publicProperty.acum),
      Cantidad: propertiesStatistics.publicProperty.total,
      Promedio: formmatterCurrency(propertiesStatistics.publicProperty.prom),
    },
    {
      key: "5",
      Item: "Propiedades sin publicar",
      Valor: formmatterCurrency(propertiesStatistics.save.acum),
      Cantidad: propertiesStatistics.save.total,
      Promedio: formmatterCurrency(propertiesStatistics.save.prom),
    },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "Item",
      key: "Item",
    },
    {
      title: "Valor",
      dataIndex: "Valor",
      key: "Valor",
    },
    {
      title: "Cantidad",
      dataIndex: "Cantidad",
      key: "Cantidad",
    },
    {
      title: "Arriendo Promedio",
      dataIndex: "Promedio",
      key: "Promedio",
    },
    {
      title: "Acciones",
      key: "action",
      dataIndex: "",
      width: "10%",
      render: (id) => <ShowButton onClick={() => showModal(id)} size="small" />,
    },
  ];

  const dataX = (type, format, rango) => {
    const arrayDays = [];
    rango = rango ?? 20;
    for (let index = 0; index < rango; index++) {
      arrayDays.push(
        moment()
          .subtract(rango - 1 - index, type ?? "day")
          .format(format ?? "MMM DD")
      );
    }
    return arrayDays;
  };

  const dataYTree = () => {
    let publicProperty = { name: "publicadas", data: [] };
    let saveProperties = { name: "sin publicar", data: [] };
    let accepted = { name: "arrendadas", data: [] };
    let openProperty = { name: "proceso de arriendo", data: [] };

    const datasX = dataX();
    datasX.map((x) => {
      const valor = propertiesRecord.find((v) => {
        return moment(v.createdAt).format("MMM DD") == x;
      });

      if (valor) {
        publicProperty.data.push({ y: valor.publicProperty.acum });
        saveProperties.data.push({ y: valor.save.acum });
        accepted.data.push({ y: valor.accepted.acum });
        openProperty.data.push({ y: valor.open.acum });
      } else {
        publicProperty.data.push({ y: 0 });
        saveProperties.data.push({ y: 0 });
        accepted.data.push({ y: 0 });
        openProperty.data.push({ y: 0 });
      }
    });

    return [accepted, openProperty, publicProperty, saveProperties];
  };

  const dataYOne = (type, format, rango) => {
    let publicProperty = { name: "publicadas", data: [] };
    let saveProperties = { name: "sin publicar", data: [] };
    let accepted = { name: "arrendadas", data: [] };
    let openProperty = { name: "proceso de arriendo", data: [] };

    const datasX = dataX(type, format, rango);
    datasX.map((x) => {
      const valor = propertiesRecord.find((v) => {
        return moment(v.createdAt).format(format) == x;
      });

      if (valor) {
        publicProperty.data.push({ y: valor.publicProperty.total });
        saveProperties.data.push({ y: valor.save.total });
        accepted.data.push({ y: valor.accepted.total });
        openProperty.data.push({ y: valor.open.total });
      } else {
        publicProperty.data.push({ y: 0 });
        saveProperties.data.push({ y: 0 });
        accepted.data.push({ y: 0 });
        openProperty.data.push({ y: 0 });
      }
    });

    return [accepted, openProperty, publicProperty, saveProperties];
  };

  const dataYTwo = (type, format, rango) => {
    let publicProperty = { name: "", data: [] };
    let saveProperties = { name: "", data: [] };
    let accepted = { name: "Número de contratos administrados", data: [] };
    let openProperty = { name: "", data: [] };

    const datasX = dataX(type, format, rango);
    datasX.map((x) => {
      const valor = propertiesRecord.find((v) => {
        return moment(v.createdAt).format("MMM DD") == x;
      });

      if (valor) {
        accepted.data.push({ y: valor.accepted.total });
      } else {
        accepted.data.push({ y: 0 });
      }
    });

    return [accepted, openProperty, publicProperty, saveProperties];
  };

  return (
    <div className="user">
      <Row gutter={16}>
        <Col span={8}>
          <Card bordered={false}>
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="pricing"
            >
              <Statistic
                title="Total de contratos $"
                value={formmatterCurrency(propertiesStatistics.accepted.acum)}
                suffix="/ 500,000,000"
              />
              <Liquid width={80} height={80} {...configOne} />
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Statistic
                title="Contratos activos"
                value={propertiesStatistics.accepted.total}
                suffix="/ 300"
              />
              <Liquid width={80} height={80} {...config} />
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false} style={{ height: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Statistic
                prefix={<UserAddOutlined />}
                title="Usuarios registrados - Descargas"
                value={348}
                suffix="- 145"
              />
            </div>
          </Card>
        </Col>
      </Row>

{/*       <div>
        <HighchartsWrapper
          title="Número de propiedades (Evolución mensual)"
          chartData={dataYOne("months", "MMM YY", 10)}
          categories={dataX("months", "MMM YY", 10)}
        />
      </div> */}

      <div style={{ display: "flex" }}>
        {/* <HighchartsWrapper
          title="Contratos Administrados"
          chartData={dataYTwo(undefined, undefined, 20)}
          categories={dataX(undefined, undefined, 20)}
        />
        <HighchartsWrapper
          title="Valor de cartera"
          type={"column"}
          chartData={dataYTree(undefined, undefined, 20)}
          categories={dataX(undefined, undefined, 20)}
        /> */}
      </div>
      <div style={{ background: "#ffff" }}>
        <Table dataSource={dataSource} columns={columns} />;
      </div>
      <Modal
        title={`${filter?.Item}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <TableProperties data={propertiesStatistics} filter={filter?.key} />
      </Modal>
    </div>
  );
};
