import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InputNumber, Button, Input, Form, Spin, Result, Select } from 'antd'

import { DollarOutlined } from '@ant-design/icons'
import ReactLoading from 'react-loading';
import { shoppingActions } from 'services/Shopping/ShoppingSlice'

export const ModalDiscount = (props) => {
  const { pay } = props;
  const dispatch = useDispatch()

  const formatter = (input) => new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
  }).format(input)
    .replace('COP', '$')
    .replace(/,b/gi, '.')

  const onFinish = (values) => {
    console.log('Success:', values, pay);
    dispatch(shoppingActions.discount(
      {
        shopping_cart_id: pay.id,
        concept: values.concept,
        value: values.value,
        type: values.type
      }
    ))
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const { status, discount } = useSelector(
    (state) => state.shopping
  );

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ discount:", discount)
  }, [discount])

  return (
    <>
      <span>Pago #{pay.id}.</span>

      <Spin spinning={status == "loading"}>
        {!discount ?
          <Form
            name="basic"
            layout='vertical'
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Concepto"
              name="concept"
              rules={[
                {
                  required: true,
                  message: 'Ingrese un concepto',
                  min: 3
                },
              ]}
            >
              <Input size={"large"} style={{ marginBottom: 1 }} placeholder="Concepto" />
            </Form.Item>

            <Form.Item
              label="Valor"
              name="value"
              rules={[
                {
                  required: true,
                  message: 'Ingrese un valor!',
                },
              ]}
            >
              <InputNumber size={"large"} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Tipo de descuento"
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Ingrese un tipo!',
                },
              ]}
            >
              <Select
                style={{
                  width: 150,
                }}
                placeholder="Tipo "
                options={[
                  {
                    value: 0,
                    label: 'Descuento ',
                  },
                  {
                    value: 1,
                    label: 'Recargo',
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Generar descuento
              </Button>
            </Form.Item>
          </Form> :
          <Result
            status="success"
            title="Descuento Realizado"
            subTitle=""
          />
        }
      </Spin>
    </>
  )
}