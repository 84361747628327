import { Button, Menu } from 'antd'


export const menu = (
    <Menu>
        <Menu.Item>
            Editar
            {/*  <Button type="primary" onClick={() => setIsModalCompany(true)} style={{ width: '100%' }}>
                Editar
            </Button> */}
        </Menu.Item>
        <Menu.Item>
            Cambiar a propiedad de pruebas
        </Menu.Item>
        <Menu.Item>
            Eliminar Propiedad
        </Menu.Item>
    </Menu>
);