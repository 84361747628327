import React, { useEffect, useState } from 'react'
import { Filter } from './sections/Filter'
import { Modal, PageHeader, Spin, Table } from 'antd'
import { columnsDetail, tablePaginationBillDetail } from './constans';
import { useDispatch, useSelector } from 'react-redux'; 
import { billingActions } from 'services/Billing/BillingSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ModalBillItemDetail } from './sections/ModalBillItemDetail';

export const BillingDescription = () => {
  const dispatch = useDispatch()
  const { id } = useParams();
  const currentTablePagination = tablePaginationBillDetail();
  const { pagination,listDescription,status } = useSelector(state => state.billing);
  const { page,limit } = pagination
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [billItem, setBillItem] = useState(false);

  const currentColumn = columnsDetail(isModalVisible, setIsModalVisible, billItem, setBillItem);
  

  useEffect(() => {
    dispatch(billingActions.getListDescription({ page, limit, filter:{bill_id:id} })) 
    
  }, []);


  const handleOk = async () => {

  };

  const handleCancel = async () => {
    setIsModalVisible(false);
  };

  return (
    <div className="sysblueprint"> 
      <PageHeader
        className="site-page-header"
        title=""
        style={{ color: '#888888', paddingLeft: 0 }}
      >  
      </PageHeader>
      <Spin spinning={status === "loading"}>
        <Table
          title={() =>
            <> 
            </>
          }
          columns={currentColumn}
          dataSource={listDescription}
          pagination={currentTablePagination}
          scroll={{ x: 400 }}
        />
      </Spin>
      {//isModalVisible &&
        //<Modal
        //  title={"Detalle de item facturado"}
        //  visible={isModalVisible}
        //  onOk={handleOk}
        //  onCancel={handleCancel}
        //  width={1100}
        //  footer={null}
        //>
        //  <ModalBillItemDetail
        //    billItem={billItem}
        //  />
        //</Modal>
      }
    </div>
  )
}