import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Divider, Button, Form, Input, Select, Row, Col, InputNumber } from "antd";
import { companyActions } from "services/Company/CompanySlice";

export const ModalChangeDataRenew = (visible) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {filterIpc, renewUpdateSel,pagination } = useSelector((state) => state.company);
  const { page, limit, total } = pagination;

  useEffect(() => {

    if (renewUpdateSel) { 
      form.setFieldsValue({
        value_canon: renewUpdateSel.valueCanon,
        value_admin: renewUpdateSel.valueAdmin,
        value_admin_re: renewUpdateSel.valueAdminNew,
        value_ipc: renewUpdateSel.ipcValue,
        is_renew: renewUpdateSel.isRenew
      });
    }
  }, [renewUpdateSel]);

  const handleFinish = (values) => {
    values.register_id = parseInt(renewUpdateSel.id); 
    values.is_renew = (values.is_renew)?true:false; 
    if (renewUpdateSel) {
      dispatch(companyActions.setUpdateRenewRegister( {update :values ,page,limit,  filter: filterIpc}));
    }  
  };

  const hideModal = () => {
    dispatch(companyActions.setIsViewRenewUpdate(false));
    dispatch(companyActions.setRenewUpdateSel(false));

  };

  return (
    <>
      <Modal
        title="Detalle del descuento"
        visible={visible}
        onOk={() => {
          // Ejecutar alguna acción antes de cerrar el modal
          form.submit(); // Enviar el formulario
          hideModal(); // Cerrar el modal
        }}
        onCancel={hideModal}
        width="70%"
        className="ticket-modal"
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleFinish}
          style={{ maxWidth: 600, margin: "0 auto" }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="value_canon"
                label="Valor de canon Actual"
                rules={[{ required: true, message: "Ingrese el valor de canon" }]}
              >
                <InputNumber
                  style={{ width: 200 }}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="value_admin"
                label="Valor administracion Actual"
                rules={[{ required: true, message: "Ingrese el valor de la administracion" }]}
              >
                <InputNumber
                  style={{ width: 200 }}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="value_ipc"
                label="Valor IPC"
                rules={[{ required: true, message: "Ingrese el valor del IPC" }]}
              >
                <InputNumber
                  style={{ width: 200 }} 
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="value_admin_re"
                label="Valor administracion al renovar"
                rules={[{ required: true, message: "Ingrese el valor de la administracion al renovar" }]}
              >
                <InputNumber
                  style={{ width: 200 }}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>


            <Col span={12}>
              <Form.Item
                name="is_renew"
                label="" 
                hidden={true} 
              >
                <InputNumber
                  style={{ width: 200 }}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>
          </Row> 
        </Form>
      </Modal>
    </>
  );
};
