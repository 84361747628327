export const getLabelName = (word) => {
    switch (word) {
      case 'general':
        return 'General'
      case 'electric':
        return 'Eléctricos'
      case 'kitchen':
        return 'Cocina'
      case 'bedroom':
        return 'Habitación'
      case 'bathroom':
        return 'Baño'
      case 'playground':
        return 'Patio de juegos'
      case 'garage':
        return 'Garaje'
      case 'storage':
        return 'Almacén'
      case 'living_room':
        return 'Sala'
      case 'basement':
        return 'Sótano'
      case 'laundry':
        return 'Lavandería'
      case ('main_door'):
        return "Puerta principal"
      case ('main_door_lock'):
        return "Cerradura puerta principal"
      case ('other_doors'):
        return "Otras puertas"
      case ('other_doors_lock'):
        return "Cerradura otras puertas"
      case ('windows'):
        return "Ventanas"
      case ('special_glasses'):
        return "Vidrio especial"
      case ('other_glasses'):
        return "Otros vidrios"
      case ('fences'):
        return "Vallas"
      case ('floors'):
        return "Pisos"
      case ('walls'):
        return "Paredes"
      case ('carpets'):
        return "Alfombras"
      case ('roofs'):
        return "Tejados"
      case ('divisions'):
        return "Divisiones"
      case ('stairs'):
        return "Escaleras"
      case ('phones'):
        return "Teléfonos"
      case ('cytophones'):
        return "Citófonos"
      case ('switches'):
        return "Interruptores"
      case ('outlet'):
        return "Tomas de corriente"
      case ('rosettes'):
        return "Rosetas"
      case ('light_counter'):
        return "Contador de luz"
      case ('fuse_box'):
        return "Caja de fusibles"
      case ('lamps'):
        return "lámparas"
      case ('appliques'):
        return "Apliques"
      case ('door'):
        return "Puerta"
      case ('lock'):
        return "Cerradura"
      case ('glasses'):
        return "Vidrios"
      case ('bars'):
        return "Barras"
      case ('floors'):
        return "Pisos"
      case ('walls'):
        return "Paredes"
      case ('roofs'):
        return "Techos"
      case ('outlet'):
        return "Tomas de corriente"
      case ('switches'):
        return "Interruptores"
      case ('rosettes'):
        return "Rosetas"
      case ('appliques'):
        return "Apliques"
      case ('doors'):
        return "Puertas"
      case ('lock'):
        return "Cerradura"
      case ('glasses'):
        return "Vidrios"
      case ('bars'):
        return "Barras"
      case ('walls'):
        return "Paredes"
      case ('roofs'):
        return "Techos"
      case ('outlet'):
        return "Tomas de corriente"
      case ('switches'):
        return "Interruptores"
      case ('rosettes'):
        return "Rosetas"
      case ('door'):
        return "Puertas"
      case ('lock'):
        return "Cerradura"
      case ('glasses'):
        return "Vidrios"
      case ('handwash'):
        return "Lavamanos"
      case ('faucet'):
        return "Grifo"
      case ('toilet'):
        return "Baño"
      case ('towel_rail'):
        return "Toallero"
      case ('soap_holder'):
        return "Jabonera"
      case ('brush'):
        return "Cepillo"
      case ('shower'):
        return "Ducha"
      case ('mirrors'):
        return "Espejos"
      case ('cabinet'):
        return "Gabinete"
      case ('divisions'):
        return "Divisiones"
      case ('floors'):
        return "Pisos"
      case ('walls'):
        return "Paredes"
      case ('roofs'):
        return "Tejados"
      case ('outlet'):
        return "Tomas de corriente"
      case ('switches'):
        return "Interruptores"
      case ('appliques'):
        return "Apliques"
      case ('bidet'):
        return "Bidé"
      case ('tub'):
        return "Tina"
      case ('door'):
        return "Puertas"
      case ('lock'):
        return "Cerradura"
      case ('glasses'):
        return "Vidrios"
      case ('roofs'):
        return "Tejados"
      case ('floors'):
        return "Pisos"
      case ('walls'):
        return "Paredes"
      case ('fences'):
        return "Vallas"
      case ('outlet'):
        return "Tomas de corriente"
      case ('switches'):
        return "Interruptores"
      case ('rosettes'):
        return "Rosetas"
      case ('door'):
        return "Puertas"
      case ('lock'):
        return "Cerradura"
      case ('glasses'):
        return "Vidrios"
      case ('roofs'):
        return "Tejados"
      case ('floors'):
        return "Pisos"
      case ('walls'):
        return "Paredes"
      case ('outlet'):
        return "Tomas de corriente"
      case ('switches'):
        return "Interruptores"
      case ('rosettes'):
        return "Rosetas"
      case ('door'):
        return "Puertas"
      case ('lock'):
        return "Cerradura"
      case ('glasses'):
        return "Vidrios"
      case ('roofs'):
        return "Tejados"
      case ('floors'):
        return "Pisos"
      case ('walls'):
        return "Paredes"
      case ('outlet'):
        return "Tomas de corriente"
      case ('switches'):
        return "Interruptores"
      case ('rosettes'):
        return "Rosetas"
      case ('door'):
        return "Puertas"
      case ('lock'):
        return "Cerradura"
      case ('glasses'):
        return "Vidrios"
      case ('roofs'):
        return "Tejados"
      case ('floors'):
        return "Pisos"
      case ('walls'):
        return "Paredes"
      case ('outlet'):
        return "Tomas de corriente"
      case ('switches'):
        return "Interruptores"
      case ('rosettes'):
        return "Rosetas"
      case ('door'):
        return "Puertas"
      case ('lock'):
        return "Cerradura"
      case ('glasses'):
        return "Vidrios"
      case ('roofs'):
        return "Tejados"
      case ('floors'):
        return "Pisos"
      case ('walls'):
        return "Paredes"
      case ('stairs'):
        return "Escaleras"
      case ('outlet'):
        return "Tomas de corriente"
      case ('switches'):
        return "Interruptores"
      case ('rosettes'):
        return "Rosetas"
      case ('door'):
        return "Puertas"
      case ('lock'):
        return "Cerradura"
      case ('glasses'):
        return "Vidrios"
      case ('roofs'):
        return "Tejados"
      case ('floors'):
        return "Pisos"
      case ('walls'):
        return "Paredes"
      case ('outlet'):
        return "Tomas de corriente"
      case ('switches'):
        return "Interruptores"
      case ('rosettes'):
        return "Rosetas"
      case ('door'):
        return "Puertas"
      case ('lock'):
        return "Cerradura"
      case ('floors'):
        return "Pisos"
      case ('walls'):
        return "Paredes"
      case ('outlet'):
        return "Tomas de corriente"
      case ('switches'):
        return "Interruptores"
      case ('rosettes'):
        return "Rosetas"
      case ('washing_machine'):
        return "Lavadora"
      case ('washing_machine_installation'):
        return "Instalación de lavadoras"
      case ('outlet'):
        return "Tomas de corriente"
      case ('switches'):
        return "Interruptores"
      case ('rosettes'):
        return "Rosetas"
      case ('lamps'):
        return "Lámparas"
      case ('broom_guard'):
        return "Guarda escobas"
      case ('closets'):
        return "Armarios"
      case ('doors'):
        return "Puertas"
      case ('drawers'):
        return "Cajones"
      case ('outlet'):
        return "Tomas de corriente"
      case ('switches'):
        return "Interruptores"
      case ('rosettes'):
        return "Rosetas"
      default:
        return word
    }
  }
  