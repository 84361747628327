import React, { useState } from "react";
import { Input, Button, message } from "antd";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import { userActions } from "../../../../../services/User/UserSlice";

const ChangePassword = ({ handleCancel }) => {
  const [newPassword, setNewPassword] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();

  const submit = () => {
    const res = dispatch(
      userActions.changePassword({
        user_id: id,
        password: newPassword,
      })
    );
      
    if (res.payload.user_id) {
        success()
        handleCancel();
    }
    
  };

  const onChange = (e) => {
    setNewPassword(e.target.value);
  };

  const success = () => {
    message.success({
      content: 'Contraseña actualizada ',
      style: {
        marginTop: '20vh',
      },
    });
  };

  return (
    <>
      <div style={{ padding: "1em" }}>
        <Input.Password
          placeholder="input password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          onChange={onChange}
        />
      </div>
      <div className="modal-rol-button">
        <Button type="primary" onClick={submit}>
          Guardar Valor
        </Button>
      </div>
    </>
  );
};

export default ChangePassword;
