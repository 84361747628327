import React from 'react'
import { PageHeader } from 'antd'
import { Filter } from './Filter'

export const ConciliationPageHeader = () => {
    return (
        <PageHeader
            className="site-page-header"
            title=""
            style={{ color: '#888888', paddingLeft: 0 }}
        >
            <Filter key="filter" />
        </PageHeader>
    )
}
