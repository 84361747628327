import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Descriptions, Row, Spin, Typography, List, Timeline, Button, Modal, Table, Col } from "antd";


import { shoppingActions } from "services/Shopping/ShoppingSlice";
import PDF from "./pdf_gen/PDF";
import { pdf, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

export const ModalDetailData = (stateData) => {
  const dispatch = useDispatch()
  const { dispersionData } = useSelector(state => state.shopping)
  const { payload, otraPropiedad } = dispersionData || {};
  const [initData, setInitData] = useState(null);

  useEffect(() => {
    //se extrae la info para facilitar la construccion de la carta
    if (stateData) {
      console.log(stateData);
      let data_pdf = {};
      let data_base = stateData.stateData;
      let metadata = (data_base?.metadata);
      let localization =  metadata.localization
      let meta_res = metadata.response;
      let owners = meta_res.ownerships;
      let company_name = metadata.company.company.name;
      let company_image = metadata.company.company.metadata.img;
      let renter = null;
      owners.forEach(item => {
        switch (item.ownershipTypeId) {
          case 2:
            renter = item.user;
            break;
        } 
      }); 
      data_pdf.prop_title = data_base.propertyTitle;
      data_pdf.renter = renter.profiles[0];
      data_pdf.localization = localization;
      data_pdf.valueAdminNew = data_base.valueAdminNew;
      data_pdf.valueCanonNew = data_base.valueCanonNew;
      let d_en  =  data_base.dateEnd;
      let data_d_en  = d_en.split("T");
      data_pdf.date_init =  data_d_en[0]; 
      data_pdf.company_name = company_name; 
      data_pdf.company_image = company_image;
      let ap_at  =  data_base.approvedAt; 
      let data_ap_at  = ap_at.split("T");
      data_pdf.approved_at =  data_ap_at[0];
      setInitData(data_pdf);
    }

  }, [stateData]);

  const listaDeDispersion = payload?.dispersion || [];
  const historial = payload?.history || [];

  return (
    <div>
    {initData !== null && (
      <>
        <PDFDownloadLink document={<PDF data={initData} />} fileName="Estado_de_cuenta.pdf">
          {({ loading }) =>
            loading ? (
              <button>Cargando documento ...</button>
            ) : (
              <button>Descargar</button>
            )
          }
        </PDFDownloadLink>
        <PDFViewer width="100%" height="1000px">
          <PDF data={initData} />
        </PDFViewer>
      </>
    )}
  </div>
  );
};
