import React from "react";
import { useDispatch } from "react-redux";
import { specialistActions } from "../../../../../../services/Specialist/SpecialistSlice";
import { useHistory } from "react-router-dom";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Form, Button, Input, Col, Row } from "antd";

export const SignUpForm = ({ next, setRegister, register }) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const onFinish = (data) => {
    const userData = {
      email: data.email,
      password: data.password,
      role_id: 5,
      profile: {
        name: data.userName,
        lastname: data.lastName,
        country_id: 2,
      },
    };

    setRegister(userData);
    next();
    /* dispatch(authActions.signup(userData)) */
    /* history.push("/") */
  };

  return (
    <div className="signup-form">
      <Form
        name="signup-form"
        initialValues={{
          remember: true,
          userName: register?.profile?.name,
          lastName: register?.profile?.lastname,
          email: register?.email,
          password: register?.password,
        }}
        onFinish={onFinish}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="userName"
              rules={[
                { required: true, message: "Por favor ingrese el nombre!" },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Nombres"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="lastName"
              rules={[
                { required: true, message: "Por favor ingrese el apellido" },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Apellidos"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese un correo válido!",
                  type: "email",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Correo"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese una contraseña!",
                  min: 10,
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Contraseña"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
