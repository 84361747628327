import { put, all, takeLatest } from "redux-saga/effects";
import ApiService from "../../common/api/Api";
import { dispersionActions } from "./DispersionSlice";
 
 

function* getDispersionList({ payload: { page, limit, filterData } }) {
   try { 
    yield put(dispersionActions.onChangeStatus("loading"));
     const response = yield ApiService.requestPost(`/dispersion/dispersion_list?page=${page}&limit=${limit}`, filterData); 
     let {items, meta} = response.payload;   
     let meta_data = {...meta};
     let pagination_search = { page:meta_data.currentPage, limit:meta_data.itemsPerPage, total:meta_data.totalItems }  
     yield put(dispersionActions.setDispersions({ items, pagination:pagination_search, filterData})) 
     yield put(dispersionActions.setState({ filterData:filterData })) 
     yield put(dispersionActions.onChangeStatus("success"));
   } catch (err) {
     yield put(
      dispersionActions.onError("No se pudo obtener resultados")
     );
   }
}

function* updateDisRegister({ payload: { updateData } }) {
  try { 
    yield put(dispersionActions.onChangeStatus("loading"));
    const response = yield ApiService.requestPost(`/dispersion/update_dispersion`, updateData); 
    yield put(dispersionActions.onChangeStatus("success"));
  } catch (err) {
    yield put(
     dispersionActions.onError("No se pudo actualizar el registro")
    );
  }
}

function* regenDataDisRegister({ payload: { updateData } }) {
  try {  
    yield put(dispersionActions.onChangeStatus("loading"));
     yield ApiService.requestPost(`/dispersion/dispersion_gen_data_single`, updateData); 
     yield put(dispersionActions.onChangeReload({value:true}));
     yield put(dispersionActions.onChangeStatus("success"));
  } catch (err) {
    yield put(
     dispersionActions.onError("No se pudo actualizar el registro")
    );
  }
}

function* getDispersionListSingle({ payload: { page, limit, cart_id ,code_gen} }) {
  try { 
    const response = yield ApiService.request(`/dispersion/state_account_breakdown/${cart_id}/${code_gen}`,
    "GET"); 
    let items = response.payload; 
    yield put(dispersionActions.setDispersionSingle({items})) 
  } catch (err) {
    yield put(
     dispersionActions.onError("No se pudo obtener resultados")
    );
  }
}

function* getCartsWithoutDis({ payload: {contract_id} }) {
  try { 
    const response = yield ApiService.request(`/dispersion/getCartsWithoutDisp/${contract_id}`,
    "GET");  
    let items = response.payload; 
    yield put(dispersionActions.setCartsDispersion({items})) 
  } catch (err) {
    yield put(
     dispersionActions.onError("No se pudo obtener resultados")
    );
  }
} 

function* assignateCartDis({ payload: { assign_data } }) {
  try {  
    console.log(assign_data);
     yield ApiService.requestPost(`/dispersion/dispersion_assign_cart`, assign_data); 
     yield put(dispersionActions.onChangeReload({value:true}));
  } catch (err) {
    yield put(
     dispersionActions.onError("No se pudo actualizar el registro")
    );
  }
}

function* listLastApproved({ payload: {  filterData }}) {
  try { 
    yield put(dispersionActions.onChangeStatus("loading"));
    const response = yield ApiService.request(`/dispersion/dispersion_list_last`,
    "GET");  
    let {items, meta} = response.payload;   
     let meta_data = {...meta};
     let pagination_search = { page:meta_data.currentPage, limit:meta_data.itemsPerPage, total:meta_data.totalItems }  
    yield put(dispersionActions.setDispersions({ items, pagination:pagination_search, filterData})) 
    yield put(dispersionActions.setState({ filterData:filterData })) 
    yield put(dispersionActions.onChangeStatus("success"));
  } catch (err) {
    yield put(
     dispersionActions.onError("No se pudo obtener resultados")
    );
  }
} 

function* ActionWatcher() { 
  yield takeLatest(dispersionActions.getDispersionList, getDispersionList)  
  yield takeLatest(dispersionActions.updateDisRegister, updateDisRegister)
  yield takeLatest(dispersionActions.regenDataDisRegister, regenDataDisRegister)
  yield takeLatest(dispersionActions.getDispersionListSingle, getDispersionListSingle)
  yield takeLatest(dispersionActions.getCartsWithoutDis, getCartsWithoutDis)
  yield takeLatest(dispersionActions.assignateCartDis, assignateCartDis)
  yield takeLatest(dispersionActions.listLastApproved, listLastApproved)
}

export default function* () {
  yield all([ActionWatcher()]);
}

