import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button, Spin, Collapse, Row, Select, Divider } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import { permissionActions } from '../../../services/Permission/PermissionSlice'

const { Panel } = Collapse
const { Option } = Select

export const Permission = () => {

  const history = useHistory()

  const dispatch = useDispatch()
  const { users, modules, status } = useSelector(state => state.permission)

  useEffect(() => {
    dispatch(permissionActions.getModules())
  }, [])

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Apellido',
      dataIndex: 'lastname',
      key: 'lastname',
    },
    {
      title: 'Correo',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Eliminar',
      key: 'action',
      dataIndex: 'id',
      width: '10%',
      render: (id) => (
        <Button
          type="default"
          shape="circle"
          icon={<DeleteOutlined />}
        />
      )
    }
  ]

  const getUserOnChange = e => {
    if (e.length > 2)
      dispatch(permissionActions.getUsers(e))
  }

  return (
    <div className="user">
      <div className="user-header">
        PERMISOS
      </div>
      <Collapse bordered={false}>
        {modules.map((module, index) => (
          <Panel header={module.name.toUpperCase()} key={index}>
            <Spin spinning={status === "loading"}>
              <Row>
                <Select
                  showSearch
                  placeholder={"Usuario"}
                  showArrow={false}
                  filterOption={false}
                  style={{ width: 300 }}
                  onSearch={getUserOnChange}
                  notFoundContent={null}
                >
                  {users.map((user, index) => <Option key={index} value={user.id}>{user.fullname}</Option>)}
                </Select>
                <Divider type="vertical" />
                <Button
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={() => dispatch(permissionActions.setUserPermission())}
                />
              </Row>
              <Row>
                <Table
                  columns={columns}
                  dataSource={[]}
                  style={{ width: '100%' }}
                />
              </Row>
            </Spin>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}