import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer"; 
import TableHeadersInmo from "./TableHeadersInmo";
import TableRowInmo from "./TableRowInmo";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
const ItemsTableInmo = ({ data }) => (
  <View  > 
      <>
        <TableHeadersInmo item={data}/>
        <TableRowInmo item={data} /> 
      </> 
  </View>
);

export default ItemsTableInmo;