import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stateAccountModal: false,
  paymentSendUser: [],
  filterPaymentSendUser: {},
  stateAccountSelected: false,
  stateAccounts: [],
  status: null,
  errormsg: null,
  loading: null,
  error: false,
  alertError: false,
  conciliation: [],
  pagination: {
    total: 1,
    page: 1,
    limit: 10
  }
};

const PaymentSendSlice = createSlice({
  name: "paymentSend",
  initialState: initialState,
  reducers: {
    setState(state, { payload: { key, value } }) {
      state[key] = value
    },
    onChangeStatus(state, { payload }) {
      state.loading = false;
      state.status = payload;
    },
    onError(state, { payload }) {
      state.loading = false;
      state.status = "error";
      state.errormsg = payload;
    },
    onAlert(state, { payload }) {
      state.error = payload
    },
    setLoading(state, { payload: { key, value } }) {
      state.loading[key] = value
    },
    getStateAccount() { },
    getStateAccounts() { },
    setStateAccounts(state, { payload }) {
      state.stateAccounts = payload
    },
    getPaymentSend(state, { payload }) {
      state.status = "loading",
        state.filterPaymentSendUser = { companyId: payload.companyId, day: payload.day }
    },
    SetPaymentSend(state, { payload }) {
      state.paymentSendUser = payload.items;
      state.pagination = payload.pagination;
      state.status = null;
    },
    getConciliation(state, { payload }) {
      state.status = "loading"
    },
    setConciliation(state, { payload }) {
      state.conciliation = payload;
      state.status = null;
    },
    sendMenssage(state, { payload }) {
      state.status = "loading"
    },
    setSendMenssage(state, { payload }) {
      state.status = null
    }
  },
});

const PaymentSendActions = PaymentSendSlice.actions;
const PaymentSendReducer = PaymentSendSlice.reducer;

export { PaymentSendActions, PaymentSendReducer };
