import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: null,
  userTickets: [],
  ticket: undefined,
  ticket: undefined,
  user: undefined,
  errormsg: null,
  userTicketsPagination: {
    total: 1,
    page: 1,
    limit: 5
  }
}

const ticketSlice = createSlice({
  name: "ticket",
  initialState: initialState,
  reducers: {
    getTicket() { },
    setTicket(state, { payload }) {
      state.ticket = payload
    },
    getTickets() { },
    setTickets(state, { payload }) {
      state.tickets = payload.data
    },
    getUserTickets() { },
    setUserTickets(state, { payload }) {
      state.userTickets = payload?.data || []
      state.userTicketsPagination = payload?.pagination || { total: 1, page: 1, limit: 5 }
    },
    getUser() { },
    setUser(state, { payload }) {
      state.user = payload
    },
    onChangeStatus(state, { payload }) {
      state.status = payload
    },
    onError(state, { payload }) {
      state.status = 'error'
      state.errormsg = payload
    },
  }
})

const ticketActions = ticketSlice.actions
const ticketReducer = ticketSlice.reducer

export { ticketActions, ticketReducer }