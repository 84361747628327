
import { useState } from 'react';
import { Card, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { CalendarOutlined } from '@ant-design/icons'
import EditContract from "../../Components/Edit/EditContract";
import ContractCollapse from "../../Components/ContractCollapse/ContractCollapse";



export const Contract = () => {
    const [cartsProperty, setCartsProperty] = useState([]);
    const [isModalEditContract, setIsModalEditContract] = useState(false);
    const { property } = useSelector((state) => state.property);

    return <>
        <Card size="default" title={
            <>
                <CalendarOutlined style={{ background: "#ffdee2", borderRadius: '5px', padding: '5px', color: '#d82067' }} />
                <span style={{ marginLeft: '5px', fontWeight: 600, color: '#0d0c0c' }}>Contrato</span>
            </>
        } style={{ borderRadius: 12, marginBottom: '20px' }} bodyStyle={{ padding: 0 }}>
            {property?.all_contracts?.length > 0 && (
                <ContractCollapse
                    setContractId={(setContractId) => {
                        console.log("🚀 ~ file: PropertyDataCard.js:98 ~ PropertyDataCard ~ setContractId:", setContractId)
                    }
                    }
                    setIsModalEditContract={setIsModalEditContract}
                    cartsProperty={cartsProperty}
                />
            )}
        </Card>
        <Modal
            title={"EDITAR CONTRATO"}
            visible={isModalEditContract}
            onCancel={() => setIsModalEditContract(false)}
            footer={null}
            width={'50%'}
        >
            <EditContract setIsModalEditContract={setIsModalEditContract} />
        </Modal>
    </>
}