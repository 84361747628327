import React from 'react'
import { Row, Col, Button } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { formmatterCurrency } from "common/utilities"

const StatisticItem = ({ title, value }) => (
    <Col span={24}>
        <Row justify="space-between">
            <Col>
                <span className='title'>{title}</span>
            </Col>
            <Col>
                <span>{value ? formmatterCurrency(value) : "---"}</span>
            </Col>
        </Row>
    </Col>
)

export const StatisticsSection = ({ sectionTitle, statistics, showModal, type }) => {
    return (
        <Col span={12}>
            <div style={{ background: '#ffff', borderRadius: '12px', padding: 25, marginBottom: '20px', border: '1px solid #f0f0f0' }}>
                <Row justify="space-between" align="middle">
                    <Col>
                        <span style={{ color: '#0d0c0c', fontSize: 18, fontWeight: 500, }}>{sectionTitle}</span>
                    </Col>
                    <Col>
                        <Button
                            type="text"
                            icon={<EyeOutlined />}
                            onClick={() => showModal(type)}
                            size={'middle'}
                        >
                            ver detalle
                        </Button>
                    </Col>
                </Row>
                <Row gutter={24} className='statics'>
                    {statistics.map(stat => <StatisticItem key={stat.title} title={stat.title} value={stat.value} />)}
                </Row>
            </div>
        </Col>
    )
}
