import React from "react";
import { Result, Button } from "antd";
const StepNotification = ({handleCancel}) => {
  return (
    <>
      <Result
        status="success"
        title="Usuario creado con éxito!!!"
        subTitle=""
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" htmlType="submit" onClick={()=>handleCancel()}>
          Cerrar
        </Button>
      </div>
    </>
  );
};

export default StepNotification;
