import { all } from 'redux-saga/effects'

function* ActionWatcher() {

}

export default function* () {
  yield all([
    ActionWatcher(),
  ])
}
