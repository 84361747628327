import React from "react";
import { Col, Row } from "antd";

export const getItemDescription = (label, value, span, onClick, key) => {
  return <Col
    className="gutter-row"
    span={span}
    key={key}
    xs={18}
    sm={12}
    md={10}
    lg={10}
    xl={8}
  >
    <div onClick={onClick} style={{ cursor: `${onClick ? 'pointer' : ''}`, display: "flex", flexDirection: 'column' }}>
      <span style={{ color: '#423d3e', marginBottom: '8px', fontSize: '16px', fontWeight: 600 }}>{label}:</span>
      <span style={{ color: '#423d3e', marginBottom: '12px', fontSize: '14px', fontWeight: 500 }}>{value}</span>
    </div>
  </Col>

}
