import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: null,
  errormsg: null,
  loading: false,
  genres: [],
}

const genreSlice = createSlice({
  name: "genre",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error'
      state.errormsg = payload
    },
    getGenres() { },
    setGenres(state, { payload }) {
      state.genres = payload
    }
  }
})

const genreActions = genreSlice.actions
const genreReducer = genreSlice.reducer

export { genreActions, genreReducer }