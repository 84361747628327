import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Button } from "antd";

import { userActions } from "../../../../../services/User/UserSlice";
import { companyActions } from "../../../../../services/Company/CompanySlice";
const AddCompanyRol = ({ user, handleCancel }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [rol, setRol] = useState();
  const [Company, setCompany] = useState();
  const { companys } = useSelector((state) => state.company);

  const onChangeRol = (value) => {
    setRol(value);
  };

  const onChanCompany = (value) => {
    setCompany(value);
  };

  useEffect(() => {
    dispatch(companyActions.getCompanys());
  }, []);

  const submit = () => {
    console.log("🚀 ~ file: AddCompanyRol.js:34 ~ submit ~ payload.user:", user)

    let payload = {
      company_id: Company ? Company : user.company[0].id,
      metadata: "{'data':1}",
      is_active: true,
      position: rol ? rol : user?.roles[0]?.id,
    };
    dispatch(
      userActions.addCompanyUser({
        company_id: Company ? Company : user.company[0]?.id,
        user_id: user.id,
        payload,
      })
    );

    handleCancel();
  };

  return (
    <div className="modal-rol">
      <div className="modal-rol-company">
        <span style={{ fontWeight: "bold" }}>Seleccione el Rol</span>
        <Select
          placeholder={"Roles"}
          notFoundContent={null}
          style={{ width: "80%", marginTop: "1rem" }}
          onChange={onChangeRol}
        >
          <Option key={3} value={3}>
            Editor
          </Option>
          <Option key={4} value={4}>
            Usuario
          </Option>
          <Option key={5} value={5}>
            Especialista
          </Option>
          <Option key={6} value={6}>
            Administrador de inmobiliaria
          </Option>
          <Option key={7} value={7}>
            Usuario de inmobiliaria
          </Option>
        </Select>
      </div>

      <div className="modal-rol-company">
        <span style={{ fontWeight: "bold" }}>Seleccione la Compañía</span>
        <Select
          placeholder={"Compañía"}
          notFoundContent={null}
          style={{ width: "80%", marginTop: "1rem" }}
          onChange={onChanCompany}
        >
          {companys?.data?.map((company) => (
            <Option key={company.id} value={company.id}>
              {company.name}
            </Option>
          ))}
        </Select>
      </div>

      <div className="modal-rol-button">
        <Button type="primary" onClick={submit}>
          Guardar Valor
        </Button>
      </div>
    </div>
  );
};

export default AddCompanyRol;
