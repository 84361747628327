import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Spin, Radio, Row, Col, PageHeader, Modal, Button } from 'antd'

import ApiService from "../../../../common/api/Api"
import { shoppingActions } from "services/Shopping/ShoppingSlice";
import { columns, columnsDispersion, columnsDispersionData, columnsDispersionHistory, columnsSend } from "../constans";
import PDF from "../pdf_gen/PDF";
import { pdf, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { ModalStateDetailData } from "./ModalStateDetailData";
import { emailActions } from "services/Email/EmailSlice";

export const ModalSendStateAccount = (stateData) => {
  const dispatch = useDispatch()
  const { stateAccounts, status, pagination } = useSelector(state => state.stateAccount)
  const { user, } = useSelector(state => state.auth)
  const { page, limit, total } = pagination
  const [selectionType, setSelectionType] = useState('11');
  const [current, setCurrent] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [isModalVisible, setIsModalVisible] =
    useState(false);

  const [stateAc, setStateAc] =
    useState(false);

  const [userinfo, setUserInfo] = useState({
    regIds: [],
    response: [],
  });

  const currentColumn = columnsSend(isModalVisible, setIsModalVisible, stateAc, setStateAc, userinfo, setUserInfo, selectedRows, setSelectedRows);
  const handlePagination = (page, limit) => {
  }
  const tablePagination = {
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    hideOnSinglePage: true,
    current: page,
    pageSize: limit,
    total
  }

  const handleOk = () => {
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setStateAc(false);
  };

  const test2 = async () => {
    console.log(userinfo);
  }
  const sendState = async () => {
    try {
      //se valida que del array general  exista el id en el array de seleccionados
      console.log(selectedRows);
      selectedRows.forEach(
        async function (element) {
          let data = {
            stateData: [element]
          };

          console.log("------------------------------");
          console.log(data);
          console.log("------------------------------");
          const pdfDocument = await <PDF data={data} />;
          console.log(pdfDocument);
          const blob = await pdf(pdfDocument).toBlob();

          const base64String = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });
          //const blob = await pdf(pdfDocument).toBuffer();
          //const base64String = blob.toString('base64');
          console.log("------------------------------");
          console.log("texto : ", base64String);
          console.log("------------------------------");
          console.log(blob);
          //se genera el payload a enviar
          let prop_name_label = element.names_reg.prop_name;
          let label = "Estado de cuenta " + prop_name_label;
          let email_owner = element.names_reg.owner_email;
          let payload = {
            to: email_owner, 
            emailKey: "message_general",
            substitutions: {
              message: label,
              subject: { "en": label, "es": label },
              button: "",
              redirect: ""

            },
            file: {
              name: "Estado_de_cuenta.pdf",
              data_base_64: base64String
            }
          };
          // servico de envio de correo
          //dispatch(emailActions.sendEmail(payload));
        }
      );
    } catch (error) {
      console.error('Error:', error);
    }
  }
  useEffect(() => {
    //console.log(selectedRows);
  }, [selectedRows])

  // Función para seleccionar todas las filas
  const handleSelectAll = () => {

    if (stateAccounts.length >= 1) {
      if (selectAll) {
        setSelectedRows([]);
      } else {
        let valid_reg = [];
        stateAccounts.forEach(element => {
          let dis_reg = element.dis_reg;
          dis_reg.forEach(function (element_in, index) {
            valid_reg.push(element_in)
          });
        });
        setSelectedRows(valid_reg); // Asigna todas las filas como seleccionadas
      }
      setSelectAll(!selectAll); // Cambia el estado de selección de todos los checkboxes
    }
  };


  return (
    <div className="user">
      <PageHeader
        className="site-page-header"
        title=""
        style={{ color: '#888888' }}
      />
      <Spin spinning={status === "loading"}>
        <Table
          title={() =>
            <>
              <Row justify="space-between">
                <Col>
                  <Button style={{
                    borderRadius: '5px',
                    borderColor: '#d82067',
                    padding: '5px 31px',
                    height: 'auto',
                    fontSize: 'medium',
                    marginRight: '15px'
                  }}
                    type="primary"
                    htmlType="submit"
                    onClick={handleSelectAll}>
                    {selectAll ? 'Desseleccionar todo' : 'Seleccionar todo'}
                  </Button>
                  <Button style={{
                    borderRadius: '5px',
                    borderColor: '#d82067',
                    padding: '5px 31px',
                    height: 'auto',
                    fontSize: 'medium',
                  }}
                    type="primary"
                    htmlType="submit"
                    onClick={() => sendState()}  >
                    Enviar al correo
                  </Button>
                </Col>
              </Row>
            </>
          }
          columns={currentColumn}
          dataSource={stateAccounts}
          scroll={{ x: 400 }}
          pagination={tablePagination}
        />
      </Spin>
      {isModalVisible &&
        <Modal
          title={"Informacion de dispersion"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1100}
          footer={null}
        >
          <ModalStateDetailData
            stateData={stateAc}
          />
        </Modal>
      }
    </div>
  )
};
