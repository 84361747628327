import { Tag } from 'antd';
import ApiService from "../../../../../../../common/api/Api"
import { useDispatch, useSelector } from "react-redux";
import { useNotificationDispatchContext } from 'common/utilities/notification/NotificationContext';
import { useHistory } from "react-router";
import { formmatterCurrency } from "common/utilities";


import moment from 'moment'
import 'moment/locale/es'
import { useEffect, useState } from 'react';
import { Button } from 'antd';

export const columns = ({ detailsInventory }) => {
    const { media } = useSelector((state) => state.property);
    const [invetoriMedia, setInvetoriMedia] = useState(null);
    const [inventoryEditable, setInventoryEditable] = useState(false);

    const { contract } = useSelector(
        (state) => state.contract
    );
    const INVENTORY_STATUS = { 1: "abierto", 2: "en progreso", 3: "detenido", 4: "cancelado", 5: "re abierto", 6: "cerrado", 7: "rechazado", 8: "aceptado", 9: "reagendado", 10: "firmado", 11: "en pago", 12: "pagado", 13: "entregado", 14: "retornado", 15: "En entrega", 16: "en retorno", 17: "entregado sin inventario", 18: "esperando respuesta de pasarela", 20: "propiedad base" }

    useEffect(() => {
        if (media) {
            const resultado = media.filter(item => item.media_group.name === "contract_act_deliver");
            setInvetoriMedia(resultado[0] ? resultado[0] : null)
        }
    }, [media])


    return [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Estado',
            dataIndex: 'status_id',
            key: 'status_id',
            render: (status_id) => <Tag color={`${status_id === 15 ? 'success' : 'default'}`} >{INVENTORY_STATUS[status_id]}</Tag>
        },
        {
            title: 'Fecha de registro',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => (
                moment(created_at).format('LL')
            )
        },
        {
            title: 'Grupos',
            dataIndex: 'sets',
            key: 'sets',
            render: (sets) => (
                sets?.length
            )
        },
        {
            title: 'Acciones',
            key: 'action',
            dataIndex: '',
            width: '10%',
            render: (obj) => (
                <>
                    {obj?.have_act == 1 ?
                        <Button
                            type='primary'
                            onClick={() =>
                                ApiService.getFile(`/media/private/${invetoriMedia.id}${invetoriMedia.filename.slice(0, 1) === '/' ? '' : '/'}${invetoriMedia.filename}`,
                                    {
                                        name: `Iventario ${contract?.contractable?.title}.pdf`
                                    })
                            } style={{ padding: "2px" }} className='aLink'>
                            Descargar
                        </Button> :
                        <Button
                            type='primary'
                            onClick={() => detailsInventory(obj)} className='aLink'>
                            Ver
                        </Button>

                    }

                </>
            )
        }
    ]
}

export const paymentsColumns = () => {
    const { property } = useSelector((state) => state.property)
    const dispatch = useDispatch();
    const dispatchNotificationEvent = useNotificationDispatchContext();


    const history = useHistory();
    const confirm = (obj) => {
        dispatch(shoppingActions.deleteShopping({
            shopping_id: obj.id, propertyId: property?.id, callback: (type, dialog, message, description) => {
                dispatchNotificationEvent({
                    type,
                    dialog,
                    message,
                    description
                })
            }
        }));
    }

    const genDispersionOverPayment = async (obj) => {
        let updateData = {
            "shopping_cart": parseInt(obj)
        }
        await dispatch(dispersionActions.regenDataDisRegister({ updateData }))
    }

    const menu = (obj) => {
        return (
            <Menu>
                <Menu.Item onClick={() => history.push(`/shopping/${obj.id}`)}>
                    <EyeOutlined />
                    <span style={{ fontSize: 12, fontWeight: "600" }}>
                        Ver detalle
                    </span>
                </Menu.Item>
            </Menu>
        )

    }


    return [
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "Fecha creación",
            dataIndex: "created_at",
            key: "created_at_pay",
            render: (created_at) => moment(created_at).format("ll"),
        },
        {
            title: "Fecha de recaudo",
            dataIndex: "transaction_date",
            key: "transaction_date",
            render: (transaction_date) =>
                transaction_date
                    ? moment(transaction_date).format("ll")
                    : "sin fecha de pago",
        },
        {
            title: "Valor",
            dataIndex: "total_amount",
            key: "total_amount_pay",
            render: (total_amount) => formmatterCurrency(total_amount),
        },
        {
            title: "Estado",
            dataIndex: "",
            key: "status_pay",
            render: (data) => {
                let paymentStore;

                switch (data.payment_method) {
                    case 1:
                        paymentStore = "payu";
                        break;
                    case 3:
                        paymentStore = "zona pago";
                        break;
                    case 4:
                        paymentStore = "terceros";
                        break;
                    case 5:
                        paymentStore = "paymentez";
                        break;
                }
                return (
                    <span>
                        {data.payment_method
                            ? `${data.status} por ${paymentStore} `
                            : data.status}
                    </span>
                );
            },
        },
        {
            title: "Acciones",
            key: "action_pay",
            dataIndex: "",
            render: (obj) => (
                <>
                    <Dropdown
                        overlay={menu(obj)}
                        placement="bottomLeft"
                        arrow={{ pointAtCenter: true }}
                        trigger={['click']}
                    >
                        <Button type='text'><MoreOutlined style={{ fontSize: '22px', transform: 'scale(1.4)', color: 'rgb(112 112 112)' }} /></Button>
                    </Dropdown>
                </>
            ),
        },
        {
            title: "Mensaje de Recaudo",
            key: "link",
            dataIndex: "",
            render: (obj) => (
                <>
                    {
                        {
                            11: (
                                <>
                                    <Button
                                        style={{ backgroundColor: "#6f1183", borderColor: "#6f1183" }}
                                        type="primary"
                                        icon={<DollarOutlined style={{}} />}
                                        size={"large"}
                                        onClick={() => showModal("yampiPasarela", obj)}
                                    >
                                        <span
                                            style={{ fontSize: 12, fontWeight: "600", marginLeft: "5px" }}
                                        >
                                            Generar
                                        </span>
                                    </Button>
                                </>
                            ),
                            18: (
                                <ShowButton
                                    onClick={() => {
                                        dispatch(
                                            shoppingActions.rePayment({ shopping_id: obj.id })
                                        );
                                    }}
                                    size="small"
                                    icon={<RedoOutlined />}
                                    style={{ backgroundColor: "#ed1c27", borderColor: "#ed1c27" }}
                                />
                            ),
                        }[obj.status_id]
                    }
                </>
            ),
        },
    ];
}

export const status_id = [
    "open",
    "in_progress",
    "stopped",
    "canceled",
    "re_opened",
    "closed",
    "Rechazado",
    "accepted",
    "rescheduled",
    "signed",
    "Pendiente de pago",
    "Pagado",
    "delivered",
    "returned",
    "in_deliver",
    "in_return",
    "delivered_not_inventory",
    "Espera de aprobación",
];

export const COST_TYPE = [
    {
        id: 1,
        name: "canon"
    },
    {
        id: 2,
        name: "administración"
    },
    {
        id: 3,
        name: "deposito"
    },
    {
        id: 4,
        name: "Firma"
    },
    {
        id: 5,
        name: "Firma"
    },
    {
        id: 6,
        name: "Dispersion"
    },
    {
        id: 7,
        name: "ticket inquilino"
    },
    {
        id: 8,
        name: "canon y administracion"
    },
    {
        id: 9,
        name: "Pago late"
    },
    {
        id: 10,
        name: "sobre costo"
    },
    {
        id: 11,
        name: "ticket propietario"
    },
    {
        id: 12,
        name: "Valor del contrato"
    },
    {
        id: 13,
        name: "Planes"
    },
]

export const columnsPayments = () => {
    return [
        {
            title: 'id recaudo',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Valor',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Fecha creación',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => (
                <span>{moment(date).format('LL')}</span>
            )
        },
        {
            title: 'Fecha Recaudado',
            dataIndex: 'transactionDate',
            key: 'transactionDate',
            render: (data) => (
                <>
                    <span>{data ? `${moment(data).format('LL')}` : "Sin recaudar"}</span>
                </>
            )
        },
        {
            title: 'Estado',
            dataIndex: '',
            key: 'status',
            render: (data) => {
                let paymentStore
                switch (data.payment_method) {
                    case 1:
                        paymentStore = "payu"
                        break;
                    case 3:
                        paymentStore = "zona pago"
                        break;
                    case 4:
                        paymentStore = "terceros"
                        break;
                    case 5:
                        paymentStore = "Paymentez"
                        break;
                }
                return <span>{data.payment_method ? `${data.status} por ${paymentStore}` : data.status}</span>
            }
        },

    ]
}