import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: null,
  list: [],
  filter: {},
  pagination: {
    total: 1,
    page: 1,
    limit: 50,
  },
  loading: false,
  errormsg: null,
};

const sysBlueprintSlice = createSlice({
  name: "sysblueprint",
  initialState: initialState,
  reducers: {
    getList() { },
    setLists(state, { payload }) {
      state.list = payload.items
      state.pagination = payload.pagination
      state.filter = payload.filter
    },
    onChangeStatus(state, { payload }) {
      state.status = payload
    },
  },
});

const sysBlueprintActions = sysBlueprintSlice.actions;
const sysBlueprintReducer = sysBlueprintSlice.reducer;

export { sysBlueprintActions, sysBlueprintReducer };
