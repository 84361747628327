import React, { useState, useEffect } from "react";
import { Table, Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PlusOutlined } from "@ant-design/icons";

import { specialistActions } from "../../../services/Specialist/SpecialistSlice";
import { getColumnSearchProps } from "../../../common/utilities/getColumnSearchProps";
import { CreateModal } from "./CreateModal/CreateModal";
import { ShowButton } from "../components/ShowButton/ShowButton";

export const Specialist = () => {
  const history = useHistory();

  const [createModalVisible, setCreateModalVisible] = useState(false);

  const dispatch = useDispatch();

  const { specialists, status, pagination, filter } = useSelector(
    (state) => state.specialist
  );
  const { page, limit, total } = pagination;

  useEffect(() => {
    specialists.length === 0 &&
      dispatch(specialistActions.getSpecialists({ page, limit, filter }));
  }, []);

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ...getColumnSearchProps("name", useState(""), useState(""), (query) =>
        dispatch(
          specialistActions.getSpecialists({
            page: 1,
            limit,
            filter: { ...filter, name: query },
          })
        )
      ),
    },
    {
      title: "Apellido",
      dataIndex: "lastname",
      key: "lastname",
      width: "20%",
    },
    {
      title: "Correo",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Ubicación",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Acciones",
      key: "action",
      dataIndex: "id",
      width: "10%",
      render: (id, specialist) => (
        <ShowButton onClick={() => history.push(`/user/${id}`, specialist)} />
      ),
    },
  ];

  const handlePagination = (page, limit) => {
    dispatch(specialistActions.getSpecialists({ page, limit, filter }));
  };

  const tablePagination = {
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    hideOnSinglePage: true,
    current: page,
    pageSize: limit,
    total,
  };

  return (
    <div className="user">
      <div className="user-header">ESPECIALISTAS</div>
      <Button
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
        onClick={() => setCreateModalVisible(true)}
        style={{ marginBottom: "1.5rem" }}
      />
      <Spin spinning={status === "loading"}>
        <Table
          columns={columns}
          dataSource={specialists}
          pagination={tablePagination}
          scroll={{ x: 400 }}
        />
      </Spin>
      <CreateModal
        visible={createModalVisible}
        setVisible={setCreateModalVisible}
      />
    </div>
  );
};
