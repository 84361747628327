import React, { useState, useEffect } from "react";
import { Typography, Form, InputNumber, Button, Row, Col } from "antd";
import { useParams, useHistory } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { propertyActions } from "../../../../../services/Property/PropertySlice";
import { Features } from '../../../../../common/constants/propertyFeatures'

export const StepFeatureProperty = ({ next }) => {
  const { Title } = Typography;
  const { form } = Form.useForm;
  const dispatch = useDispatch();
  const [id, setId] = useState();
  const { propertyInfo } = useSelector((state) => state.property);

  
  useEffect(() => {
    /* dispatch(propertyActions.getFeature()) */
    if (propertyInfo?.id) {
      setId(propertyInfo?.id);
    }
  }, [id]);
 
  const layoutstyled = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 24,
      },
      lg: {
        span: 24,
      },
      xl: {
        span: 24,
      },
    },
    wrapperCol: {
      xs: {
        span: 4,
      },
      sm: {
        span: 4,
      },
      md: {
        span: 4,
      },
      lg: {
        span: 14,
      },
      xl: {
        span: 8,
      },
    },
  };

  const onFinish = (data) => {
    let dataFeat = [];
    let i = 0;
    for (const pro in data) {
      if (pro === Features[i]?.name && data[pro] !== undefined) {
        dataFeat.push({
          quantity: data[pro]?.toString(),
          feature_type_id: Features[i]?.id,
        });
      }
      i++;
    }
    dispatch(propertyActions.setFeature({ dataFeat, id: propertyInfo.id, edit:false }));
    next();
  };

  return (
    <div style={{marginTop: '2em'}}>
      
      <Form form={form} onFinish={onFinish} {...layoutstyled}>
        <Row gutter={[8, 8]}>
          {Features.map((item) => (
            <Col span={6} key={item.id}>
              <Form.Item
                name={item.name}
                label={item.label}
                id={item.name}
                rules={[{required: false, message: "Ingrese un Numero" }]}
              >
                <InputNumber min={0} max={10} id="1" style={{ width: 100 }}/>
              </Form.Item>
            </Col>
          ))}
        </Row>
        <div style={{display: 'flex', justifyContent: 'flex-end',width: '100%'}}>
          <Form.Item >
            <Button type="primary" htmlType="submit">
              Siguiente
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
