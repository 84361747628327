import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Steps} from "antd";
import { locationActions } from "../../../../services/Location/LocationSlice";
import { StepInforProperty } from "./StepInfoProperty/StepInforProperty";
import { StepFeatureProperty } from "./StepFeatureProperty/StepFeatureProperty";
import { StepPayProperty } from "./StepPayProperty/StepPayProperty";
import { StepDoneProperty } from "./StepDoneProperty/StepDoneProperty";

import StepNotification from "./StepNotification/StepNotification";

export const ModalCreateProperty = ({current, next}) => {
  const dispatch = useDispatch();
  const [selectCity, setSelectCity] = useState();
  const { locations } = useSelector((state) => state.location);
  const { Step } = Steps;

  useEffect(() => {
  }, [selectCity]);

  
  const getLocationOnSearch = (e) => {
    if (e.length > 4) dispatch(locationActions.getLocations(e));
  };

  const getLocationOnChange = (e) => {
    const city = locations.filter((location) => location.id == e);
    setSelectCity(city);
  };

  return (
    <>
      <Steps current={current} >
        <Step title="Datos Generales"  />
        <Step title="Características"  />
        <Step title="Políticas"  />
        <Step title="Comodidades" />
        <Step title="Final"  />
      </Steps>
      <div>
        {
          {
            0: (
              <StepInforProperty
                getLocationOnChange={getLocationOnChange}
                getLocationOnSearch={getLocationOnSearch}
                selectCity={selectCity}
                next={next}
              />
            ),
            1: <StepFeatureProperty next={next} />,
            2: <StepPayProperty next={next} />,
            3: (
              <StepDoneProperty
                current={current}
                next={next}
              />
            ),
            4: <StepNotification />
          }[current]
        }
      </div>
    </>
  );
};
