import { createSlice } from "@reduxjs/toolkit";
import { error } from "highcharts";

const initialState = {
  feesModal: false, 
  fee: null, 
  contextData:undefined,
  status: null,
  errormsg: null,
  loading: null,
  error: false,
  alertError: false
};

const feesSlice = createSlice({ 
  name: "fees",
  initialState: initialState,
  reducers: { 
    onChangeStatus(state, { payload }) {
      state.loading = false;
      state.status = payload;
    },
    onError(state, { payload }) {
      state.loading = false;
      state.status = "error";
      state.errormsg = payload;
    },
    setContextData(state, { payload }) {
      state.contextData = payload;
    },
  updateDispersionContext(state, { payload,data_id }){},
  createDispersionContext(state, { payload }){
    state.contextData = payload;
  }
  }
});



const feesActions = feesSlice.actions;
const feesReducer = feesSlice.reducer;

export { feesActions, feesReducer };
