import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    status: null,
    errormsg: null,
    contracts: [],
    mediaFiles: [],
    contract: undefined,
    lastContract: null,
    finale: null
}

const mediaSlice = createSlice({
    name: "media",
    initialState: initialState,
    reducers: {
        upload() { },
        getMedia() { },
        onChangeStatus(state, { payload }) {
            state.status = payload
        },
        onError(state, { payload }) {
            state.status = 'error'
            state.errormsg = payload
        },
        getMediaFiles() { },
        setMediaFiles(state, { payload }) {
            state.mediaFiles = payload
        },
    }
})

const mediaActions = mediaSlice.actions
const mediaReducer = mediaSlice.reducer

export { mediaActions, mediaReducer }