import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Descriptions, Row, Spin, Typography, List, Timeline, Button, Modal, Table, Col } from "antd";

import ApiService from "../../../../common/api/Api"
import { shoppingActions } from "services/Shopping/ShoppingSlice";
import { columnsDispersion, columnsDispersionData, columnsDispersionHistory } from "../constans";
import PDF from "../pdf_gen/PDF";
import {pdf, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

export const ModalStateDetailData = (stateData) => {
  const dispatch = useDispatch()
  const { dispersionData } = useSelector(state => state.shopping)
  const currentColumnHistory = columnsDispersionHistory();
  const currentcolumnsDisData = columnsDispersionData();
  const { payload, otraPropiedad } = dispersionData || {};
  const [initData, setInitData] = useState(false);

   

  const listaDeDispersion = payload?.dispersion || [];
  const historial = payload?.history || [];

  return (
    <div>
    <PDFDownloadLink  document={<PDF data={stateData} />} fileName= "Estado_de_cuenta.pdf">
      {({ loading, url, error, blob }) =>
        loading ? (
          <button>Cargando documento ...</button>
        ) : (
          <button>Descargar</button>
        )
      }
    </PDFDownloadLink> 
    <PDFViewer  width="100%" height="1000px">
      <PDF 
      data={stateData}
      />
    </PDFViewer>
  </div> 
  );
};
