
import React from 'react';
import {
  Document,
  Text,
  Page,
  StyleSheet,
  Image,
  View,
} from "@react-pdf/renderer";
import TableRow from './TableRow';
import ItemsTable from './ItemsTable';
import TableFooters from './TableFooters';
import logoBig from '../../../../assets/logo-purple.png'
import logosmall from '../../../../assets/logo-R5.png'
import ItemsTableInmo from '../pdf_gen_inmo/ItemsTableInmo';
import TableFootersInmo from '../pdf_gen_inmo/TableFootersInmo';

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
    padding: 30,
    size: {
      orientation: 'landscape',
    },
  },
  title: {
    fontSize: 14,
    textAlign: "center",
    fontWeight: "bold",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    margin: 10,
    padding: 10,
  },
  parragraph: {
    fontSize: 12,
    textAlign: "justify",
    lineHeight: 1.5,
    margin: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  image: {
    width: '140px',
    height: 'auto',
    right: 10,
  },
  imageL: {
    width: '80px',
    height: 'auto',
    right: 10,
  },
  imageR: {
    width: '40px',
    height: 'auto',
    right: 10,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    bottom: 30,
    left: 15,
    right: 15,
    paddingLeft: 15,
    paddingRight: 15,
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    top: 15,
    left: 15,
    right: 15,
    paddingBottom: 0,
    paddingLeft: 15,
    paddingRight: 15,
  },
  sectionHead: {
    display: "flex",
    flexDirection: "row",
    margin: 30,
    padding: 30,
  },
  sectionSeparator: {
    display: "flex",
    flexDirection: "row",
    margin: 15,
    padding: 15,
  },
  sectionInfoGen: {
    flexDirection: 'column',
  },
  sectionInfo: {
    marginBottom: 2,
  },
  text: {
    textAlign: 'center',
    fontSize: 10,
  },
});

const getLogo = (data) => {
  let logo_url = "";
  data.stateData.map((item, index) => (
    logo_url = item.dis_reg.metadata[0].inmo_admin_logo
  ));
  if (logo_url.includes("v4A4+17r8F+E+AAQBvR7GbWNuJLAAAAABJRU5ErkJggg==")) {
    logo_url = '';
  }
  console.log(logo_url);
  return logo_url;
} 
function PDF({ data }) { 
  let adminData = data.stateData[0].dis_reg.metadata[0].type_dis; 
  if (adminData == 0) {//"Pagado a Propietario"
    return (
      <Document>
        {data.stateData.map((item_data, index) => (
          <>
            <Page style={styles.page}>
              <View style={styles.sectionHeader}>
                {(getLogo(data) == '') ? <Text style={styles.text}>
                  {(item_data.dis_reg.context == undefined) ? " " : item_data.dis_reg.context}
                </Text> : <Image style={styles.image} src={getLogo(data)} />}
                <View style={styles.sectionInfoGen}>
                  <View style={styles.sectionInfo}>
                    <Text style={styles.text}>
                      {(item_data.dis_reg.context == undefined) ? " " : item_data.dis_reg.context}
                    </Text>
                  </View>
                  <View style={styles.sectionInfo}>
                    <Text style={styles.text}>
                      {(item_data.names_reg.company_nit == undefined) ? " " : item_data.names_reg.company_nit}
                    </Text>
                  </View>
                  <View style={styles.sectionInfo}>
                    <Text style={styles.text}>
                      {(item_data.names_reg.company_contact == undefined) ? " " : item_data.names_reg.company_contact}
                    </Text>
                  </View>
                  <View style={styles.sectionInfo}>
                    <Text style={styles.text}>
                      INFORME ESTADO DE CUENTA PROPIETARIO
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.section}></View>
              <View style={styles.section}>
                <View style={styles.cell}>
                  <Text style={styles.text}>Propietario : {item_data.names_reg.owner_name}</Text>
                </View>
              </View>
              <View >
                <ItemsTable data={item_data} />
                {/*<TableHeader />*/}
                {/*<ItemsTable data={data} />*/}
                {/*<TableFooter items={data.items} />*/}
              </View>
              <View>
                <TableFooters item={item_data} />
              </View>
              <View style={styles.sectionSeparator}>
              </View>
              <View style={styles.footer}>
                <Image style={styles.imageL} src={logoBig} />
                <Image style={styles.imageR} src={logosmall} />
              </View>
              <View style={styles.pageNumber}>
                <Text render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`} />
              </View>
            </Page>
          </>
        ))}

      </Document >
    );
  } else if (adminData == 1) {//"Pagado a inmobiliaria"
    return (
      <Document>
        {data.stateData.map((item_data, index) => (
          <>
            <Page style={styles.page}>
              <View style={styles.sectionHeader}>
                {(getLogo(data) == '') ? <Text style={styles.text}>
                  {(item_data.dis_reg.context == undefined) ? " " : item_data.dis_reg.context}
                </Text> : <Image style={styles.image} src={getLogo(data)} />}
                <View style={styles.sectionInfoGen}>
                  <View style={styles.sectionInfo}>
                    <Text style={styles.text}>
                      {(item_data.names_reg.company_nit == undefined) ? " " : item_data.names_reg.company_nit}
                    </Text>
                  </View>
                  <View style={styles.sectionInfo}>
                    <Text style={styles.text}>
                      {(item_data.names_reg.company_contact == undefined) ? " " : item_data.names_reg.company_contact}
                    </Text>
                  </View>
                  <View style={styles.sectionInfo}>
                    <Text style={styles.text}>
                      INFORME ESTADO DE CUENTA INMOBILIARIA
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.section}></View>
              <View style={styles.section}>
                <View style={styles.cell}>
                  <Text style={styles.text}>Imobiliaira : {item_data.dis_reg.context}</Text>
                </View>
              </View>
              <View >
                <ItemsTableInmo data={item_data} />
                {/*<TableHeader />*/}
                {/*<ItemsTable data={data} />*/}
                {/*<TableFooter items={data.items} />*/}
              </View>
              <View>
                <TableFootersInmo item={item_data} />
              </View>
              <View style={styles.sectionSeparator}>
              </View>
              <View style={styles.footer}>
                <Image style={styles.imageL} src={logoBig} />
                <Image style={styles.imageR} src={logosmall} />
              </View>
              <View style={styles.pageNumber}>
                <Text render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`} />
              </View>
            </Page>
          </>
        ))}

      </Document >
    );
  }


}

export default PDF;
