import { put, all, takeLatest } from "redux-saga/effects";
import ApiService from "../../common/api/Api";
import { stateAccountActions } from "./StateAccountSlice";
 
 

function* getStateAccount({ payload }) {
  try {
    const { page, limit, filterData } = payload;
    yield put(stateAccountActions.onChangeStatus("loading"));
    const response = yield ApiService.requestPost(`/dispersion/state_account?page=${page}`, filterData);  
    yield put(stateAccountActions.setStateAccounts(response?.payload)) 
    yield put(stateAccountActions.onChangeStatus("success"));
    yield put(stateAccountActions.setFilterData(filterData));
  } catch (err) {
    yield put(
      stateAccountActions.onError("No se pudo actualizar el descuento")
    );
  }
}
  
function* ActionWatcher() { 
  yield takeLatest(stateAccountActions.getStateAccount, getStateAccount) 
}

export default function* () {
  yield all([ActionWatcher()]);
}

