import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Spin, Row, Col, PageHeader, Modal } from 'antd'

import { PaymentSendActions } from "services/PaymentSend/PaymentSendSlice";

import { columns } from './constans'
import { Filter } from './sections/Filter'
import { ModalStateDetailData } from './sections/ModalStateDetailData'


export const PaymentSend = () => {

	const dispatch = useDispatch()
	const { paymentSendUser, status, pagination, filterPaymentSendUser } = useSelector(state => state.paymentSend)
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [stateAc, setStateAc] = useState(false);

	const { page, limit, total } = pagination
	const currentColumn = columns(isModalVisible, setIsModalVisible, stateAc, setStateAc);

	const handlePagination = (page, limit) => {
		dispatch(PaymentSendActions.getPaymentSend({ page, limit, ...filterPaymentSendUser }))
	}

	const tablePagination = {
		onChange: handlePagination,
		hideOnSinglePage: true,
		current: page,
		pageSize: limit,
		total
	}

	return (
		<div className="user">
			<PageHeader
				className="site-page-header"
				title=""
				style={{ color: '#888888', paddingLeft: 0 }}
			>
				<Filter key="filter" />
			</PageHeader>
			<Spin spinning={status === "loading"}>
				<Table
					title={() =>
						<>
							<Row justify="space-between">
								<Col flex={1} > <span style={{ color: '#0d0c0c', fontSize: 18, fontWeight: 500, margin: '25px 10px' }}>Días de recaudo</span></Col>
							</Row>
						</>
					}
					columns={currentColumn}
					dataSource={paymentSendUser}
					scroll={{ x: 400 }}
					pagination={tablePagination}
				/>
			</Spin>
			{isModalVisible &&
				<Modal
					title={"Informacion de dispersion"}
					visible={isModalVisible}
					onOk={handleOk}
					onCancel={handleCancel}
					width={1100}
					footer={null}
				>
					<ModalStateDetailData
						cart={cart}
					/>
				</Modal>
			}
		</div>
	)
}