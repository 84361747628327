import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { formmatterCurrency } from "common/utilities";
import { Button } from 'antd'
import { ShowButton } from 'scenes/Private/components/ShowButton/ShowButton'
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { FiDelete, FiEdit } from "react-icons/fi";
import { companyActions } from "services/Company/CompanySlice";
import { FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons'

export const columns = (regenerateData, setRegenerateData, setCloseContractData,selectedRows, setSelectedRows,selectedRowsM, setSelectedRowsM,setIsModalVisible, setStateData) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const setIsModalUpdateVisible = (values) => {
        dispatch(companyActions.setIsViewRenewUpdate(values));
    }

    const setRenewUpdateSel = (values) => {
        dispatch(companyActions.setRenewUpdateSel(values));
    }

    // Función para manejar la selección de filas
    const handleRowSelect = (row) => {
        const index = selectedRows.findIndex(selectedRow => selectedRow.id === row.id);

        // Si la fila ya está seleccionada, la quitamos del estado
        if (index !== -1) {
            const newSelectedRows = [...selectedRows];
            newSelectedRows.splice(index, 1);
            setSelectedRows(newSelectedRows);
        } else { // Si la fila no está seleccionada, la agregamos al estado
            setSelectedRows([...selectedRows, row]);
        }
    };

    const handleRowSelectM = (row) => {
        const index = selectedRowsM.findIndex(selectedRowM => selectedRowM.id === row.id);

        // Si la fila ya está seleccionada, la quitamos del estado
        if (index !== -1) {
            const newSelectedRowsM = [...selectedRowsM];
            newSelectedRowsM.splice(index, 1);
            setSelectedRowsM(newSelectedRowsM);
        } else { // Si la fila no está seleccionada, la agregamos al estado
            setSelectedRowsM([...selectedRowsM, row]);
        }
    };

    return [
        {
            title: '',
            dataIndex: '',
            key: '',
            render: (data) => { 
                if (data.isProcessed === 0) {
                    return <div>
                        <input
                            type="checkbox"
                            checked={selectedRows.some(selectedRow => selectedRow.id === data.id)}
                            onChange={() => handleRowSelect(data)}
                        />
                    </div> 
                }else  if (data.isProcessed === 1) {
                    return <div>
                        <input
                            type="checkbox"
                            checked={selectedRowsM.some(selectedRowM => selectedRowM.id === data.id)}
                            onChange={() => handleRowSelectM(data)}
                        />
                    </div> 
                }
            }
        }, 
        {
            title: 'Título',
            dataIndex: '',
            render: (data) => {
                return <span onClick={() => history.push(`/property/${data.propertyId}`)} style={{ color: '#423d3e', marginBottom: '12px', fontSize: '14px', fontWeight: 500, cursor: 'pointer' }}> {data.propertyTitle}   </span>

            }
        },
        {
            title: 'valores Actual',
            dataIndex: '',
            render: (data) => {
                let label = "";
                label = <>
                    <>
                        canon : {formmatterCurrency(data.valueCanon)}
                    </>
                    <br />
                    <>
                        administracion :{formmatterCurrency(data.valueAdmin)}
                    </>
                </>
                return label;
            }
        },
        {
            title: 'Porcentaje incremento',
            dataIndex: '',
            render: (data) => {
                return <>{(data.ipcValue)} %</>;
            }
        },
        {
            title: 'Incremento',
            dataIndex: '',
            render: (data) => {
                return <>{formmatterCurrency(data.valueIncrement)}</>;
            }
        },
        {
            title: 'Valores con ajuste IPC',
            dataIndex: '',
            render: (data) => {
                let label = "";
                label = <>
                    <>
                        canon : {formmatterCurrency(data.valueCanonNew)}
                    </>
                    <br />
                    <>
                        administracion :{formmatterCurrency(data.valueAdminNew)}
                    </>
                </>
                return label;
            }
        },
        {
            title: 'Prorrata (si aplica)',
            dataIndex: 'firstCanonProrrat',
            key: 'firstCanonProrrat',
            render: (data) => {
                let label = <>{formmatterCurrency(data)}</>
                return label;
            }
        },
        {
            title: 'Acciones',
            key: 'action',
            dataIndex: '',
            render: (data) => {
                let label = "";
                let data_id = data.id;
                if (data.isProcessed === 0) {
                    label = <>
                        <ShowButton icon={<EditOutlined />} onClick={() => { setIsModalUpdateVisible(true); setRenewUpdateSel(data) }} />

                        <ShowButton icon={<CheckOutlined />} onClick={() => {
                            setRegenerateData(data_id);
                        }
                        } />
                        <ShowButton icon={<FiDelete />} onClick={() => {
                            setCloseContractData(data_id);
                        }
                        } />
                    </>
                } else if (data.isProcessed === 1) {
                    label = <> En aplicacion
                    <Button
                                style={{
                                    borderRadius: '50%', // Para que el botón sea circular 
                                    padding: '10px', // Ajusta el padding según el tamaño que desees
                                    height: 'auto',
                                    width: 'auto', // Permite que el tamaño del botón se adapte al contenido
                                    display: 'flex', // Centra el ícono
                                    alignItems: 'center', // Alinea verticalmente el ícono
                                    justifyContent: 'center', // Alinea horizontalmente el ícono
                                    fontSize: '24px', // Aumenta el tamaño del ícono si es necesario
                                    marginBottom: 20
                                }}
                                icon={<FilePdfOutlined style={{ fontSize: '24px' }} />}
                                onClick={() => { setIsModalVisible(true); setStateData(data) }}
                            ></Button>
                    </>
                } else {
                    label = <> Aplicado
                    </>
                }
                return label;
            }
        }
    ]
} 