import { put, all, takeLatest, select } from 'redux-saga/effects'
import decode from 'jwt-decode'
import { authActions } from './AuthSlice'
import { userActions } from '../User/UserSlice'
import ApiService from '../../common/api/Api'
import { StorageService } from '../../common/storage/storage-service'

function* companyByUser({ payload }) {
  try {
    const response = yield ApiService.requestPost(`/company/user`, payload)
    yield put(authActions.setCompanyByUser(response))
    /* yield put(authActions.setToken(response.token))
    yield put(userActions.setCompany(response.company)) */
  } catch (err) {
    console.log("ERROR", err)
    yield put(authActions.onError(err.toString()))
  }
}

function* login({ payload }) {
  try {
    const response = yield ApiService.post('/login/email', payload)
    StorageService.setItem('token', response.token)
    StorageService.setItem('company', JSON.stringify(response.company))
    yield put(authActions.setToken(response.token))
    yield put(userActions.setCompany(response.company))
  } catch (err) {
    console.log("ERROR", err)
    yield put(authActions.onError(err.toString()))
  }
}

function* checkIsAuth() {
  try {
    const token = yield StorageService.getItem('token')
    const company = yield StorageService.getItem('company')
    /* const role = yield StorageService.getItem('role') */
    try {
      let { exp } = decode(token);
      if (Date.now() <= exp * 1000) {
        yield put(authActions.setToken(token))
        yield put(userActions.setCompany(JSON.parse(company)))
      } else {
        return TypeError('El token ha expirado');
      }
      /* yield put(authActions.fetchUser()) */

      /* yield put(authActions.setRole({ role: role })) */
    }
    catch (e) {
      console.log("ERROR", e)
      return e
    }
  }
  catch (err) {
    console.log("ERROR", err)
    yield put(authActions.onError(err.toString()))
  }
}

function* ActionWatcher() {
  yield takeLatest(authActions.login, login)
  yield takeLatest(authActions.companyByUser, companyByUser)
  yield takeLatest(authActions.checkIsAuth, checkIsAuth)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ])
}
