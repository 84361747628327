export const DESCRIPTION_DISEPERSION = (id) => {
    switch (id) {
        case 0:
            return 'dispersion realizada correctamente'
        case 1:
            return 'Registro de disperción no se ha realizada'
        case 2:
            return 'La dispersion no se realizo el propietario no tiene datos completos, cuenta de banco, etc'
        case 3:
            return 'Registro de dispersion se ha realizado prestando el dinero'
        case 4:
            return 'Registro de dispersion se registrado en estado de cuenta y ya no se vuelve a consultar para reportes automaticos'
        default:
            return ''
    }
}