import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Form, Select, Typography, Button } from "antd";

import { locationActions } from "../../../../../services/Location/LocationSlice";

const InfoSecond = ({ next, setSelectCity, user }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const { Title } = Typography;
  const [form] = Form.useForm();
  const { locations } = useSelector((state) => state.location);

  const layoutstyled = {
    labelCol: {
      xs: {
        span: 1,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 8,
      },
      lg: {
        span: 10,
      },
      xl: {
        span: 10,
      },
    },
    wrapperCol: {
      xs: {
        span: 10,
      },
      sm: {
        span: 20,
      },
      md: {
        span: 14,
      },
      lg: {
        span: 14,
      },
      xl: {
        span: 8,
      },
    },
  };

  useEffect(() => {

    if (user) {
      form.setFieldsValue({
        issue_location: user?.location ? '' : user?.location,
        direction: (user?.localization && user?.localization?.address) ? '' : user?.localization?.address,
        contac: (user?.phone && user?.phone?.number && user?.phone?.number.length === 0) ? '' : user?.phone?.number,
      })
      if (user?.location) {
        var city_var = user?.location;
        var string = city_var.split(",");
        dispatch(locationActions.getLocations(string[0]))
        const city = locations.filter((location) => location.fullname == user?.profile?.birth_location);
        setSelectCity(city);
      }
    }

  }, [user])

  const onFinish = (values) => {
    return next(values);
  };

  const getLocationOnSearch = (e) => {
    if (e.length > 4) dispatch(locationActions.getLocations(e));
  };

  const getLocationOnChange = (e) => {
    const city = locations.filter((location) => location.id == e);
    setSelectCity(city);
  };

  return (
    <>
      <Title style={{ marginTop: 20, marginBottom: 20 }} level={5}>
        Información Básica
      </Title>
      <Form
        {...layoutstyled}
        form={form}
        name="register"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="issue_location"
          label="Ciudad, Departamento"
          rules={[{ required: true, message: "Por favor ingrese el nombre!" }]}
        >
          <Select
            showSearch
            placeholder={"Ciudad, Departamento"}
            showArrow={false}
            filterOption={false}
            onChange={getLocationOnChange}
            onSearch={getLocationOnSearch}
            notFoundContent={null}
          >
            {locations.map((location) => (
              <Option key={location.id} data={location}>
                {location.fullname}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="direction"
          label="Dirección "
          placeholder={"Cra # cll"}
          rules={[
            {
              required: true,
              message: "Ingresa una dirección",
              whitespace: true,
            },
          ]}
        >
          <Input placeholder={"Cra cll #"} />
        </Form.Item>

        <Form.Item
          name="contac"
          label="No. Contacto "
          placeholder={"Cel"}
          rules={[
            {
              required: true,
              message: "Ingresa un numero telefónico",
              whitespace: true,
            },
          ]}
        >
          <Input placeholder={"Telefono"} />
        </Form.Item>

        <div
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default InfoSecond;
