import { createSlice } from "@reduxjs/toolkit";
import { error } from "highcharts";

const initialState = {
  stateAccountModal: false,
  stateAccountSelected: false,
  stateAccounts: [],
  status: null,
  errormsg: null,
  loading: null,
  error: false,
  alertError: false,
  filterData: [],
  pagination: {
    total: 1,
    page: 1,
    limit: 20
  }
};

const stateAccountSlice = createSlice({
  name: "stateAccount",
  initialState: initialState,
  reducers: {
    setState(state, { payload: { key, value } }) {
      state[key] = value
    },
    onChangeStatus(state, { payload }) {
      state.loading = false;
      state.status = payload;
    },
    onError(state, { payload }) {
      state.loading = false;
      state.status = "error";
      state.errormsg = payload;
    },
    onAlert(state, { payload }) {
      state.error = payload
    },
    setLoading(state, { payload: { key, value } }) {
      state.loading[key] = value
    },
    getStateAccount() { },
    getStateAccounts() { },
    setStateAccounts(state, { payload }) {
      state.stateAccounts = payload.data
      state.pagination = {
        total: payload.totalItems,
        page: payload.currentPage, 
        limit:20
      }
    },
    setFilterData(state, { payload }) {
      state.filterData = payload
    }
  }
});

const stateAccountActions = stateAccountSlice.actions;
const stateAccountReducer = stateAccountSlice.reducer;

export { stateAccountActions, stateAccountReducer };
