import { put, all, takeLatest } from 'redux-saga/effects'
import ApiService from '../../common/api/Api'
import { ticketActions } from './TicketSlice'
import { getStatus } from '../../common/constants/status'
import { getPriority } from '../../common/constants/priority'

function* getUserTickets({ payload: { user_id, page, limit } }) {
  yield put(ticketActions.onChangeStatus('ticketsLoading'))
  try {
    const body = { user_id, limit }
    const response = yield ApiService.post(`/admin/specialist-tickets?page=${page}`, body)
    let { data, total } = response
    data = response?.data?.map((ticket, index) => ({
      ...ticket,
      key: index,
      title: ticket?.content[0]?.title,
      status: getStatus(ticket?.status_id).label,
      created_at: ticket.created_at,
      priority: getPriority(ticket?.priority_id).label
    }))
    const pagination = { page, limit, total }
    yield put(ticketActions.setUserTickets({ data, pagination }))
    yield put(ticketActions.onChangeStatus('success'))
  } catch (err) {
    yield put(ticketActions.onError(err.toString()))
  }
}

function* getUser({ payload }) {
  yield put(ticketActions.onChangeStatus('loading'))
  try {
    const response = yield ApiService.post(`/admin/user-info/${payload}`)
    const data = {
      ...response,
      name: response?.profile?.name,
      lastname: response?.profile?.lastname,
      location: response?.localization?.location?.fullname,
      genre: response?.profile?.genre_id === 1 ? 'Masculino' : response?.profile?.genre_id === 2 ? 'Femenino' : 'Indefinido',
      payments: response?.payments.map((payment, index) => ({
        ...payment,
        status: getStatus(payment.status_id).label,
        key: index
      }))
    }
    yield put(ticketActions.setUser(data))
    yield put(ticketActions.onChangeStatus('success'))
  } catch (err) {
    yield put(ticketActions.onError(err.toString()))
  }
}

function* getTicket({ payload }) {
  yield put(ticketActions.onChangeStatus('loading'))
  try {
    const response = yield ApiService.get(`/admin/ticket/${payload}`)
    yield put(ticketActions.setTicket(response))
    yield put(ticketActions.onChangeStatus('success'))
  } catch (err) {
    yield put(ticketActions.onError(err.toString()))
  }
}

function* getTickets({ payload }) {
  yield put(ticketActions.onChangeStatus('loading'))
  const { page, limit, filter } = payload
  try {
    const response = yield ApiService.get(`/admin/ticket?page=${page}`)
    yield put(ticketActions.setTickets(response))
    yield put(ticketActions.onChangeStatus('success'))
  } catch (err) {
    yield put(ticketActions.onError(err.toString()))
  }
}



function* ActionWatcher() {
  yield takeLatest(ticketActions.getUserTickets, getUserTickets)
  yield takeLatest(ticketActions.getUser, getUser)
  yield takeLatest(ticketActions.getTicket, getTicket)
  yield takeLatest(ticketActions.getTickets, getTickets)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ])
}
