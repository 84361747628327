import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Spin, PageHeader, Button, Modal, Row, Col } from "antd";

import { userActions } from "../../../services/User/UserSlice";
import CreateUser from "./CreateUser/CreateUser";
import { columns } from './constans'
export const User = () => {

  const currentColumn = columns();
  const dispatch = useDispatch();

  const { users, status, pagination, filter } = useSelector(
    (state) => state.user
  );
  const { page, limit, total } = pagination;
  const [isModal, setIsModalCreateUser] = useState(false);

  useEffect(() => {
    users.length === 0 &&
      dispatch(userActions.getUsers({ page, limit, filter, op: "get" }));
  }, []);


  const handlePagination = (page, limit) => {
    dispatch(userActions.getUsers({ page, limit, filter }));
  };

  const tablePagination = {
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    hideOnSinglePage: true,
    current: page,
    pageSize: limit,
    total,
  };

  const handleOkDiscount = () => {
    setIsModalCreateUser(false);
  };

  const handleCancel = () => {
    setIsModalCreateUser(false);
  };

  return (
    <div className="user">
      <PageHeader
        className="site-page-header"
        title=""
        extra={[]}
      />
      <Spin spinning={status === "loading"}>
        <Table
          title={() =>
            <>
              <Row justify="space-between">
                <Col flex={1} > <span style={{ color: '#0d0c0c', fontSize: 18, fontWeight: 500, margin: '25px 10px' }}>Lista de Usuarios</span></Col>
                <Col >
                  <Button
                    key="1"
                    type="primary"
                    onClick={() => setIsModalCreateUser(true)}
                  >
                    Crear Usuario
                  </Button>
                </Col>
              </Row>
            </>
          }
          columns={currentColumn}
          dataSource={users}
          pagination={tablePagination}
          scroll={{ x: 400 }}
        />
      </Spin>
      <Modal
        title={"Crear Usuario"}
        visible={isModal}
        onOk={handleOkDiscount}
        onCancel={handleCancel}
        footer={null}
        width={"50%"}
      >
        <CreateUser handleCancel={handleCancel} />
      </Modal>
    </div>
  );
};
