import React, { useState, useEffect, Fragment } from "react";
import { Modal, Col, Row, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { ticketActions } from "../../../../../../services/Ticket/TicketSlice";
import { getItemDescription } from "../../../../components/ItemDescription/ItemDescription";

export const TicketModal = ({ ticket, setTicket }) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.ticket);

  useEffect(() => {
    const index = ticket?.ownership?.findIndex(
      (ownership) => ownership.ownership_type_id === 5
    );

    if (index >= 0)
      dispatch(ticketActions.getUser(ticket?.ownership[index].user_id));

    return () => {
      dispatch(ticketActions.setUser());
    };
  }, [ticket]);

  const hideModal = () => {
    setTicket();
  };

  return (
    <Modal
      title={`DETALLE MANTENIMIENTO`}
      visible={ticket}
      onOk={hideModal}
      onCancel={hideModal}
      width={"70%"}
      className="ticket-modal"
    >
      <Divider orientation="left" style={{ marginTop: 0 }}>
        CONTENIDO
      </Divider>
      {ticket?.content?.length > 0 && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {ticket?.content?.map((content, index) => (
            <Fragment key={index}>
              {getItemDescription("TÍTULO", content.title, 12)}
              {getItemDescription("FECHA", content.created_at, 12)}
              {getItemDescription("CUERPO", content.body, 12)}
              {getItemDescription("RESUMEN", content.summary, 12)}
              {ticket?.content.length > 0 && index < ticket?.content.length && (
                <Divider />
              )}
            </Fragment>
          ))}
        </Row>
      )}
      <Divider orientation="left">DESCRIPCIÓN GENERAL</Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {getItemDescription("PRIORIDAD", ticket?.priority, 7)}
        {getItemDescription("ESTADO", ticket?.status, 7)}
        {user && (
          <Col className="gutter-row" span={10}>
            <a onClick={() => history.push(`/user/${user?.id}`)}>
              <p>
                <span>ESPECIALISTA: </span>
                {`${user?.profile?.name} ${user?.profile?.lastname}`}
              </p>
            </a>
          </Col>
        )}
      </Row>
    </Modal>
  );
};
