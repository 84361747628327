import React, { useState, useEffect, Fragment } from 'react'
import { Modal, Steps, Button, message, Col, Row, Divider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { propertyActions } from '../../../../../services/Property/PropertySlice'
import { getItemDescription } from '../../../components/ItemDescription/ItemDescription'

export const TicketModal = ({ ticket, setTicket }) => {

  const history = useHistory()

  const dispatch = useDispatch()
  const { property } = useSelector(state => state.property)

  useEffect(() => {
    ticket && dispatch(propertyActions.getProperty(ticket?.property_id))

    return () => { dispatch(propertyActions.setProperty()) }
  }, [ticket])

  const hideModal = () => {
    setTicket()
  }

  return (
    <Modal
      title={`DETALLE MANTENIMIENTO`}
      visible={ticket}
      onOk={hideModal}
      onCancel={hideModal}
      width={'70%'}
      className="ticket-modal"
    >
      {ticket?.content?.length > 0 && (
        <Fragment>
          <Divider orientation="left" style={{ marginTop: 0 }}>CONTENIDO</Divider>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {ticket?.content?.map((content, index) => (
              <Fragment key={index}>
                {getItemDescription("TÍTULO", content.title, 12)}
                {getItemDescription("FECHA", content.created_at, 12)}
                {getItemDescription("CUERPO", content.body, 12)}
                {getItemDescription("RESUMEN", content.summary, 12)}
                {ticket?.content.length > 0 && index < ticket?.content.length - 1 && <Divider />}
              </Fragment>
            ))}
          </Row>
        </Fragment>
      )}
      <Divider orientation="left">DESCRIPCIÓN GENERAL</Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {getItemDescription("PRIORIDAD", ticket?.priority, 7)}
        {getItemDescription("ESTADO", ticket?.status, 7)}
        {property && <Col className="gutter-row" span={10}>
          <a onClick={() => history.push(`/property/${property?.id}`)}>
            <p><span>PROPIEDAD: </span>{property?.title}</p>
          </a>
        </Col>}
      </Row>
    </Modal>
  )
}