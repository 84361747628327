import React, { useEffect, useState, Fragment, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router'
import { Col, Row, Button, Spin, Collapse, Table, Divider, Popconfirm } from 'antd'
import { ArrowLeftOutlined, EyeOutlined } from '@ant-design/icons'

import { shoppingActions } from '../../../../services/Shopping/ShoppingSlice'
import { contractActions } from '../../../../services/Contract/ContractSlice'
import { propertyActions } from "../../../../services/Property/PropertySlice";
import { ticketActions } from '../../../../services/Ticket/TicketSlice'
import { getItemDescription } from '../../components/ItemDescription/ItemDescription'
import { useNotificationDispatchContext } from '../../../../common/utilities/notification/NotificationContext';
import { ContractCard } from '../../Contract/ContractCard'
import DetailCard from './DetailCard'

import moment from 'moment'
import 'moment/locale/es'

const { Panel } = Collapse

export const ShoppingDetail = () => {

  const dispatchNotificationEvent = useNotificationDispatchContext();

  const [model, setModel] = useState({ name: undefined, id: undefined })

  const history = useHistory()
  const { id } = useParams()

  const dispatch = useDispatch()

  const { shopping, status } = useSelector(state => state.shopping)


  useEffect(() => {
    dispatch(shoppingActions.getShopping(id))

    return () => { dispatch(shoppingActions.setShopping()) }
  }, [])

  useEffect(() => {
    if (!shopping) return
    const data = JSON.parse(shopping.metadata)

    if (shopping.metadata.includes('Contract')) {
      setModel({ name: 'Contract', id: data['App\\Models\\Contract'][0] })
      dispatch(contractActions.getContract(data['App\\Models\\Contract'][0]))
    }

    if (shopping.metadata.includes('Ticket')) {
      setModel({ name: 'Ticket', id: data['App\\Models\\Ticket'][0] })
      dispatch(ticketActions.getTicket(data['App\\Models\\Ticket'][0]))
    }
    if (shopping)
      dispatch(propertyActions.getMedia({ entityId: shopping?.id, entityName: 'ShoppingCart' }))
    return () => { dispatch(contractActions.setContract()) }
  }, [shopping])


  return (
    <div className="property-detail shopping-detail">
      <Spin spinning={status === "loading"}>
        <Row gutter={32}>
          <Col span={8} >
            <ContractCard />
          </Col>
          <Col span={16} >
            <div className="property-detail-header">
              <span>Detalle de pago</span>
            </div>
            <DetailCard />
          </Col>
        </Row>
      </Spin>
    </div>
  )
}