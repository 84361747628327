import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Descriptions, Row, Spin, Typography, List, Timeline, Button, Modal, Table, Col } from "antd";
import { columnsBill, columnsBillDetail} from "../constans";
import { formmatterCurrency } from "common/utilities";
import { billingActions } from 'services/Billing/BillingSlice';

export const ModalBillDetail = (bill) => {
 
  const dispatch = useDispatch()  
  const [items, setItems] = useState([]); 
  const columns = columnsBill();
  const currentColumn = columnsBillDetail();

  const [user, setUser] = useState({}); 
  const [profile, setProfile] = useState({}); 
  const [docId, setDocId] = useState({});
  const [phone, setPhone] = useState({}); 
  const [localization, setLocalization] = useState({}); 
  const [location, setLocation] = useState({}); 
  const [billData, setBillData] = useState({}); 

  const { pagination, listDescription, filter } = useSelector(state => state.billing)
  const { page, total } = pagination
  const { limit } = pagination
 

  useEffect(() => {  
    if (bill) {
      if(listDescription.length == 0){
        dispatch(billingActions.getListDescription({ page, limit, filter:{bill_id:bill.bill.id} }))
      } 
      let data_bill = bill.bill.metadataBillingItems;
      const claves = Object.keys(data_bill).sort((a, b) => a - b);
      const arregloDeArreglos = claves.map(clave => data_bill[clave]);
      setItems(arregloDeArreglos);  
      let bill_client = bill.bill.metadataBillingClient;
      let user_piv = bill_client.user; 
      let prof_piv = user_piv.profiles;
      let doc_piv = user_piv.docid;
      let phone_piv = user_piv.phone;
      phone_piv =  phone_piv[phone_piv.length - 1];
      let localization_piv = bill_client.localization; 
      let location_piv = localization_piv.location; 
      setBillData(bill.bill); 
      setUser(user_piv);  
      setProfile(prof_piv[0]);
      setDocId(doc_piv[0]); 
      setPhone(phone_piv); 
      setLocalization(localization_piv); 
      setLocation(location_piv); 
      console.log(listDescription);
    }
  }, [dispatch, bill]);

   
  return (
    <Row>
      <Row>
        <Col span={24}>
          <Descriptions title="Detalles del Cliente" bordered>
            <Descriptions.Item label="Nombre">{profile.name+" "+profile.lastname}</Descriptions.Item><Descriptions.Item label=""></Descriptions.Item><Descriptions.Item label=""></Descriptions.Item>
            <Descriptions.Item label="C.C/NIT">{docId.code}</Descriptions.Item><Descriptions.Item label=""></Descriptions.Item><Descriptions.Item label=""></Descriptions.Item>
            <Descriptions.Item label="Teléfono">{phone.number}</Descriptions.Item><Descriptions.Item label=""></Descriptions.Item><Descriptions.Item label=""></Descriptions.Item>
            <Descriptions.Item label="Ciudad">{location.name}</Descriptions.Item><Descriptions.Item label=""></Descriptions.Item><Descriptions.Item label=""></Descriptions.Item>
            <Descriptions.Item label="Dirección">{localization.addressData+" "+localization.address+" "+localization.addressExtra}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Descriptions title="Detalles de la Factura" bordered>
            <Descriptions.Item label="Factura Electrónica de Venta">{billData.codeBilling}</Descriptions.Item>
            <Descriptions.Item label=""></Descriptions.Item><Descriptions.Item label=""></Descriptions.Item>
            <Descriptions.Item label="Fecha de generacion">{billData.createdAt}</Descriptions.Item>
            <Descriptions.Item label=""></Descriptions.Item><Descriptions.Item label=""></Descriptions.Item>
            <Descriptions.Item label="Fecha de expedicion">{billData.dateBilling}</Descriptions.Item>
            <Descriptions.Item label=""></Descriptions.Item><Descriptions.Item label=""></Descriptions.Item>
            <Descriptions.Item label="Fecha de vencimiento"> </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table dataSource={listDescription} columns={currentColumn} pagination={false} />
        </Col>
        <Col span={24} style={{ textAlign: 'right', marginTop: '20px' }}>
          <Typography.Title level={4}>Total: {formmatterCurrency(bill.bill.valueBilling)}</Typography.Title>
        </Col>
      </Row>
    </Row>
  );
};