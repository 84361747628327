import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Descriptions, Row, Spin, Typography, List, Timeline, Button, Modal, Table, Col } from "antd";


import { shoppingActions } from "services/Shopping/ShoppingSlice";
import PDF from "./pdf_gen/PDF";
import { pdf, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

export const ModalDetailData = (stateData) => {
  const dispatch = useDispatch()
  const { dispersionData } = useSelector(state => state.shopping)
  const { payload, otraPropiedad } = dispersionData || {};
  const [initData, setInitData] = useState(null);

  useEffect(() => {
    //se extrae la info para facilitar la construccion de la carta
    if (stateData) {
      console.log(stateData);
      console.log(stateData.valueBill);
      let data_pdf = {};
       let data_base = stateData.stateData;
       let full_data = stateData.fullData;
      //let metadata = (data_base?.metadata);
       let localization =  data_base.localization;
       let company =  data_base.company;
       let response =  data_base.response;
       let user_bill =  data_base.user_bill;
       console.log(localization);
       console.log(company);
       console.log(response);
       console.log(user_bill);
      //let meta_res = metadata.response;
      //let owners = meta_res.ownerships;
      //let company_name = metadata.company.company.name;
      //let company_image = metadata.company.company.metadata.img;
      //let renter = null;
      //owners.forEach(item => {
      //  switch (item.ownershipTypeId) {
      //    case 2:
      //      renter = item.user;
      //      break;
      //  } 
      //}); 
      data_pdf.value_bill = stateData.valueBill;
      data_pdf.prop_title = full_data.prop_title;
      let us_bill_profile  = user_bill.profiles[0];
      let full_name = us_bill_profile.name +" "+us_bill_profile.lastname;
      data_pdf.full_name = full_name;
      let us_bill_docid  = user_bill.docid[0];
      data_pdf.id_code = us_bill_docid.code;
      let us_bill_localization  = user_bill.localization[0];
      data_pdf.address = us_bill_localization.address;
      let us_bill_location  = us_bill_localization.location;
      data_pdf.city = us_bill_location.fullname;
      let us_bill_phone  = user_bill.phone[0];
      data_pdf.phone = us_bill_phone.number;

     console.log(data_pdf);
      setInitData(data_pdf);
    }

  }, [stateData]);

  const listaDeDispersion = payload?.dispersion || [];
  const historial = payload?.history || [];

  return (
    <div>
    {initData !== null && (
      <>
        <PDFDownloadLink document={<PDF data={initData} />} fileName="Estado_de_cuenta.pdf">
          {({ loading }) =>
            loading ? (
              <button>Cargando documento ...</button>
            ) : (
              <button>Descargar</button>
            )
          }
        </PDFDownloadLink>
        <PDFViewer width="100%" height="1000px">
          <PDF data={initData} />
        </PDFViewer>
      </>
    )}
  </div>
  );
};
