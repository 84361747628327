import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './InventoryActions';

const initialState = {
  status: null,
  errormsg: null,
  loading: true,
  inventoryFull: false,
  inventory: [],
  inventoryItems: [],
  inventoryTypes: [],
  setsAvailable: [],
  setsTypeAvailable: [],
}

const inventorySlice = createSlice({
  name: "inventory",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setInventory(state, { payload }) {
      state.inventory = payload;
    },
    setInventoryItem(state, { payload }) {
      state.inventoryItems = payload;
    },
    setFullInventory(state, { payload }) {
      state.inventoryFull = payload;
    },
    setInventoryTypes(state, { payload }) {
      state.inventoryTypes = payload;
    },
    setSetsAvailable(state, { payload }) {
      state.setsAvailable = payload;
    },
    setSetsTypeAvailable(state, { payload }) {
      state.setsTypeAvailable = payload;
    }
  }
});

const inventoryActions = { ...inventorySlice.actions, ...asyncActions }
const inventoryReducer = inventorySlice.reducer

export { inventoryActions, inventoryReducer };