import { put, all, takeLatest } from "redux-saga/effects";
import ApiService from "../../common/api/Api";
import { feesActions } from "./FeesSlice";
 


function* createDispersionContext({ payload }) { 
  console.log("🚀 ~ file: FeesSaga.js:30 ~ function*createDispersionContext ~ data:",payload)
  try {
    const response = yield ApiService.post(`/admin/dispersion-context`, payload.payload);
    yield put(feesActions.setContextData(response));
    yield put(feesActions.onChangeStatus("success")); 
  } catch (err) {
    yield put( 
      feesActions.onError("No se pudo crear el contexto el descuento")
    );
  }
}

function* updateDispersionContext({ payload}) {
  console.log("🚀 ~ file: DiscountsSaga.js:30 ~ function*updateDispersionContext ~ dis_ID:", payload)
  try {
    const response = yield ApiService.post(`/admin/dispersion-context/${payload.data_id}`, payload.payload); 
    yield put(feesActions.setContextData(response));
    yield put(feesActions.onChangeStatus("success")); 
  } catch (err) {
    yield put( 
      feesActions.onError("No se pudo crear el contexto el descuento")
    );
  }
}

function* ActionWatcher() {
  yield takeLatest(feesActions.createDispersionContext, createDispersionContext)
  yield takeLatest(feesActions.updateDispersionContext, updateDispersionContext)
}
 
export default function* () {
  yield all([ActionWatcher()]);
}

