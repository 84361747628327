import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { DollarOutlined, RedoOutlined, BankOutlined, UploadOutlined, StarOutlined } from "@ant-design/icons";
import { ShowButton } from '../../components/ShowButton/ShowButton'
import { formmatterCurrency } from 'common/utilities';
import moment from 'moment';
import { billingActions } from 'services/Billing/BillingSlice';



export const columns = (isModalVisible, setIsModalVisible, stateData, setStateData, valueBill, setValueBill, regenVal, setRegenVal,fullData, setFullData) => {

    const formatter = (input) =>
        new Intl.NumberFormat("en", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
        })
            .format(input)
            .replace("COP", "$")
            .replace(/,b/gi, ".");

    const dispatch = useDispatch()
    const history = useHistory()
    const { pagination, filter } = useSelector(state => state.billing)
    const { page, limit } = pagination

    const getBillDetail = (data) => {
        console.log("entre service" + data);
        dispatch(billingActions.getListDescription({ page, limit, filter: { bill_id: data } }))
        console.log("sali service");
    }

    const regenBill = (data) => {
        console.log("entre service" + data);

    }


    return [
        {
            title: 'Propiedad',
            dataIndex: '',
            key: '',
            render: (data) => {
                return <span onClick={
                    () => history.push(`/property/${data.property_id}`)}
                    style={{ color: '#423d3e', marginBottom: '12px', fontSize: '14px', fontWeight: 500, cursor: 'pointer' }}>
                    {data.prop_title}
                </span>

            },
        },
        //{
        //    title: 'Contrato',
        //    dataIndex: 'contract_id',
        //    key: 'contract_id'
        //},
        {
            title: 'Periodo Facturacion',
            dataIndex: 'month',
            key: 'month',
        },
        {
            title: 'Propietario',
            key: 'action',
            dataIndex: '',
            render: (_, data) => (
                <div>
                    {data.value_bill.map((item, index) => (
                        <div key={index}>
                            {item.prefix === 0 ? (<>
                                <span>Valor facturado : {formatter(item.data)}</span>
                                <div>
                                    {data.other_metadata.map((itemm, index) => (
                                        <>
                                            {itemm.prefix === 0 ? (<>
                                                <ShowButton onClick={() => { setIsModalVisible(true); setStateData(itemm.data); setValueBill(item.data);setFullData(data) }} />
                                            </>
                                            ) : (<></>)}
                                        </>
                                    ))}
                                    {data.ids.map((itemi, index) => (
                                        <>
                                            {itemi.prefix === 0 ? (<>
                                                <ShowButton icon={<RedoOutlined />} onClick={() => { setRegenVal(itemi.data) }} />
                                            </>
                                            ) : (<></>)}
                                        </>
                                    ))}
                                </div>
                            </>
                            ) : (<></>)}

                        </div>
                    ))}
                </div>
            ),

        },

        {
            title: 'Inquilino',
            key: 'action',
            dataIndex: '',
            render: (_, data) => (
                <div>
                    {data.value_bill.map((item, index) => (
                        <div key={index}>
                            {item.prefix === 1 ? (<>
                                <span>Valor facturado : {formatter(item.data)}</span>
                                <div>
                                    {data.other_metadata.map((itemm, index) => (
                                        <>
                                            {itemm.prefix === 1 ? (<>
                                                <ShowButton onClick={() => { setIsModalVisible(true); setStateData(itemm.data); setValueBill(item.data);setFullData(data) }} />
                                            </>
                                            ) : (<></>)}
                                        </>
                                    ))}
                                    {data.ids.map((itemi, index) => (
                                        <>
                                            {itemi.prefix === 1 ? (<>
                                                <ShowButton icon={<RedoOutlined />} onClick={() => { setRegenVal(itemi.data) }} />
                                            </>
                                            ) : (<></>)}
                                        </>
                                    ))}
                                </div>
                            </>
                            ) : (<></>)}

                        </div>
                    ))}
                </div>
            ),

        }

    ]
}


export const tablePaginationBill = () => {
    const dispatch = useDispatch()
    const { pagination, filter } = useSelector(state => state.billing)
    const { page, total } = pagination
    const { limit } = pagination

    const handlePagination = (page, limit) => {
        dispatch(billingActions.getList({ page, limit, filter }))
    }

    return {
        onChange: handlePagination,
        hideOnSinglePage: true,
        current: page,
        pageSize: limit,
        total
    }
}



