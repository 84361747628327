import React from "react";
import { Result } from "antd";
const StepNotification = () => {
  return (
    <>
      <Result
        status="success"
        title="Propiedad creada con éxito!"
        subTitle=""
      />
    </>
  );
};

export default StepNotification;
