import React, { useState, useEffect } from 'react';

import { FooterCopyright } from '../components/FooterCopyright/FooterCopyright'
import { LoginForm } from './components/LoginForm/LoginForm';



export const Login = () => {
  const [showRecoverPasswordModal, toggleRecoverPasswordModal] = useState(false)

  return (
    <div className="login">
      <div className="login-content">
        <div className="login-row">
          <LoginForm />
        </div>
      </div>
      <FooterCopyright />
    </div>
  )
}

