import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Radio,
  DatePicker,
  InputNumber,
  Alert,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { contractActions } from "services/Contract/ContractSlice";
import { shoppingActions } from "services/Shopping/ShoppingSlice";
import { CONTRACT_TYPE } from "common/constants/contractType";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export const StepInfoContract = ({
  props,
  next
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { locations } = useSelector((state) => state.location);
  const { company } = useSelector((state) => state.user);
  const [errorTuition, setErrorTuition] = useState(null);
  const { propertyInfo, errormsg } = useSelector((state) => state.property);
  const { contract } = useSelector((state) => state.contract);
  const { id } = useParams();

  useEffect(() => {
    contract?.id && next();
  }, [contract]);

  useEffect(() => {
    if (errormsg) {
      setErrorTuition(Object.keys(errormsg));
    }
     
  }, [errormsg]);

  const layoutstyled = {
    labelCol: {
      xs: {
        span: 1,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 8,
      },
      lg: {
        span: 10,
      },
      xl: {
        span: 10,
      },
    },
    wrapperCol: {
      xs: {
        span: 10,
      },
      sm: {
        span: 20,
      },
      md: {
        span: 14,
      },
      lg: {
        span: 14,
      },
      xl: {
        span: 8,
      },
    },
  };

   
  const onFinish = async (values) => {
    setErrorTuition(null);
  };
  function handleChange(value) {
    setContractTypeId(value);
  }

  const formSuccess = (data) => {
    let d = data.finalDate;
    const pay = {
      payload: {
        contract_email: data.email,
        contract_type_id: data.select,
        property_id: props,
        date_ini: data.startDate.format("YYYY-MM-DD"),
        date_end: data.startDate.add(d, "months").format("YYYY-MM-DD"),
      },
      id: id
    };
    //next();
    const res = dispatch(contractActions.renterDefineNewContract(pay));  
  };
  const formFailed = (error) => {

  };
  return (
    <Form
      onFinish={formSuccess}
      onFinishFailed={formFailed}
      form={form}
      {...layoutstyled}
    >
      <Form.Item
        name="email"
        label="E-mail Arrendatario"
        rules={[
          {
            type: "email",
            required: true,
            message: "Ingresa un correo",
            whitespace: true,
          },
        ]}
      >
        <Input type="email" placeholder="Ingrese un E-mail" />
      </Form.Item>

      <Form.Item
        name="select"
        label="Tipo de Contrato"
        rules={[
          { required: true, message: "Selecciona el tipo de Contrato" },
        ]}
      >
        <Select placeholder="Seleccione el tipo de contrato">
          {CONTRACT_TYPE.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="startDate"
        label="Fecha de Inicio"
        rules={[{ required: true, message: "Selecciona una Fecha" }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        name="finalDate"
        label="Meses del Contrato"
        rules={[{ required: true, message: "Selecciona una Fecha" }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Siguiente
            </Button>
          </Form.Item>
        </div>

    </Form>
  );
};
