import React, { useState, useEffect } from "react";
import {
  Modal,
  Col,
  Row,
  Divider,
  Input,
  Button,
  DatePicker,
  Select,
  Form,
  InputNumber,
  message,
  Alert,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Steps} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { contractActions } from "../../../../../../services/Contract/ContractSlice";
import {
  getContractType,
  CONTRACT_TYPE,
} from "../../../../../../common/constants/contractType";
import moment from "moment";
import { StepInfoContract } from "./stepsCreateContract/StepInfoContract";
import { StepFees } from "./stepsCreateContract/StepFees";
import { StepFinal } from "./stepsCreateContract/StepFinal";

export const RenterAddModal = ({propertyId,current, next}) => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { errormsg, loading, error, status } = useSelector(
    (state) => state.contract
  );
  const [dateIni, setDateIni] = useState();
  const [form] = Form.useForm();
  const { Step } = Steps;
  useEffect(() => {

  }, [])

  const hideModal = () => { 
    dispatch(contractActions.onAlert(false));
    dispatch(contractActions.onChangeStatus(null));
  };

  function handleChange(value) {
    setContractTypeId(value);
  }

 

  const layoutstyled = {
    labelCol: {
      xs: {
        span: 1,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 8,
      },
      lg: {
        span: 10,
      },
      xl: {
        span: 10,
      },
    },
    wrapperCol: {
      xs: {
        span: 10,
      },
      sm: {
        span: 20,
      },
      md: {
        span: 14,
      },
      lg: {
        span: 14,
      },
      xl: {
        span: 8,
      },
    },
  };

  const success = () => {
    message.success("This is a success message");
  };
  return (
    <>
      <Steps current={current} >
        <Step title="Datos Generales"  />
        <Step title="Tarifas"  />
        <Step title="Final"  /> 
      </Steps>
      <div>
        {
          {
            0: (
              <StepInfoContract 
                next={next}
                props={propertyId}
              />
            ),
            1: <StepFees next={next}  props={propertyId} />, 
            2: <StepFinal />,
          }[current]
        }
      </div>
    </>
  );
//step
/*  return (
    <Modal
      title={`Agregar Arrendatario`}
      visible={props.visible}
      onCancel={hideModal}
      width={"60%"}
      className="renter-add-modal"
      destroyOnClose={true}
      forceRender={true}
      footer={false}
    >
      <Form
        onFinish={formSuccess}
        onFinishFailed={formFailed}
        form={form}
        {...layoutstyled}
      >
        <Form.Item
          name="email"
          label="E-mail Arrendatario"
          rules={[
            {
              type: "email",
              required: true,
              message: "Ingresa un correo",
              whitespace: true,
            },
          ]}
        >
          <Input type="email" placeholder="Ingrese un E-mail" />
        </Form.Item>

        <Form.Item
          name="select"
          label="Tipo de Contrato"
          rules={[
            { required: true, message: "Selecciona el tipo de Contrato" },
          ]}
        >
          <Select placeholder="Seleccione el tipo de contrato">
            {CONTRACT_TYPE.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="startDate"
          label="Fecha de Inicio"
          rules={[{ required: true, message: "Selecciona una Fecha" }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name="finalDate"
          label="Meses del Contrato"
          rules={[{ required: true, message: "Selecciona una Fecha" }]}
        >
          <InputNumber min={1} />
        </Form.Item>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "30px",
              marginLeft: "20px",
            }}
          >
            <LoadingOutlined style={{ fontSize: 30 }} spin />
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button style={{ marginRight: "10px" }} type="default" htmlType="submit">
              Asignar
            </Button>
            <Button type="primary" onClick={hideModal}>
              Cerrar
            </Button>
          </div>
        )}

        {error && (
          <Alert
            message={errormsg}
            type="error"
            style={{ marginTop: "10px" }}
            showIcon
            closable
          />
        )}
        {status && status != "error" ? (
          <Alert
            message="Arrendatario Agregado Exitosamente"
            type="success"
            style={{ marginTop: "10px" }}
            showIcon
            closable
          />
        ) : null}
      </Form>
    </Modal>
  );*/
};
