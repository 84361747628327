import * as React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
 
import { Login } from './Login/Login'

export const Public = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
      </Switch>
    </Router>
  )
}