import { put, all, takeLatest } from "redux-saga/effects";
import ApiService from "../../common/api/Api";
import { filesReaderActions } from "./FilesReaderSlice";
 


function* readFileMassive(payload) { 
  console.log("🚀 ~ file: FilesReaderSaga.js:30 ~ function*readFileMassive ~ data:",payload)
  try {
    yield put(filesReaderActions.onChangeStatus("loading")); 
    const response = yield ApiService.requestPostFormData(`/reader/upload`, payload.payload);
    yield put(filesReaderActions.setServiceResponse(response));
    yield put(filesReaderActions.onChangeStatus("success")); 
  } catch (err) {
    yield put( 
      filesReaderActions.onError("No se pudo cargar el documento")
    );
  }
}
 

function* ActionWatcher() {
  yield takeLatest(filesReaderActions.readFileMassive, readFileMassive) 
}
 
export default function* () {
  yield all([ActionWatcher()]);
}

