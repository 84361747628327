import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  Snackbar: {
    type: '',
    dialog: '',
    message: '',
    description: '',
    isOpen: false
  }
}

const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    openSnackbar(state, {payload}) {
      state.Snackbar = {
        type: 'OPEN_SNACKBAR',
        dialog: payload.type,
        message: payload.message,
        description: payload.description,
        isOpen: true
      }
    },
    closeSnackbar(state, { }) {
      state.Snackbar = {
        isOpen: false,
        dialog: "",
        type: "CLOSE_SNACKBAR",
        message: ""
      }
    },
    init() {
      return initialState
    }
  }
})

const appActions = appSlice.actions
const appReducer = appSlice.reducer

export { appActions, appReducer }