import React, { useEffect } from "react";
import { Modal, Col, Row, Divider, Button, Form, Input, Select, InputNumber } from "antd";
import { useDispatch } from "react-redux";
import { discountsActions } from "services/Discounts/DiscountsSlice";

export const DiscountModal = ({ visible, discount, contractId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (discount) {
      form.setFieldsValue({
        ...discount,
        recurrent: discount.recurrent === 1,
        type: discount.type === 1,
        is_active: discount.is_active === 1,
      });
    }
  }, [discount]);

  const hideModal = () => {
    dispatch(discountsActions.setState({ key: "discountModal", value: false }));
    dispatch(discountsActions.setState({ key: "discountSelected", value: null }));
  };

  const handleFinish = (values) => {
    const payload = {
      contract_id: contractId,
      is_active: values.is_active,
      recurrent: values.recurrent,
      type: values.type,
      concept: values.concept,
      value: values.value,
    };

    if (discount) {
      dispatch(discountsActions.updateDiscountContract({ con_adj_pay_id: parseInt(discount.id), ...payload }));
    } else {
      dispatch(discountsActions.createDiscountContract(payload));
    }
  };

  const inputStyle = { width: "100%", height: "40px" }; // Altura uniforme para inputs y selects

  return (
    <Modal
      title="Detalle del descuento"
      visible={visible}
      onOk={hideModal}
      onCancel={hideModal}
      width="50%"
      className="ticket-modal"
    >
      <Divider orientation="left" style={{ marginTop: 0 }}>
        Información del Descuento
      </Divider>
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="concept"
              label="Descripción del descuento"
              rules={[{ required: true, message: "Ingrese el concepto del descuento" }]}
            >
              <Input size="large" style={inputStyle} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="value"
              label="Valor"
              rules={[{ required: true, message: "Ingrese el valor del descuento" }]}
            >
              <InputNumber
                style={inputStyle}
                size="large"
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="recurrent"
              label="Frecuencia"
              rules={[{ required: true, message: "Seleccione la frecuencia" }]}
            >
              <Select
                size="large"
                style={inputStyle}
                options={[{ value: true, label: "Periódico" }, { value: false, label: "Única vez" }]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="type"
              label="Tipo"
              rules={[{ required: true, message: "Seleccione el tipo" }]}
            >
              <Select
                size="large"
                style={inputStyle}
                options={[{ value: false, label: "Descuento" }, { value: true, label: "Adición" }]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="is_active"
              label="Estado"
              rules={[{ required: true, message: "Seleccione el estado" }]}
            >
              <Select
                size="large"
                style={inputStyle}
                options={[{ value: false, label: "Inactivo" }, { value: true, label: "Activo" }]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit">
            {discount ? "Editar" : "Agregar"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
