import React, { useEffect, useState } from "react";
import { dispersionActions } from "services/Dispersion/DispersionSlice";
import { useDispatch, useSelector } from "react-redux";
import {  Row, Spin, Typography, List, Timeline, Button, Modal, Table, Col, DatePicker } from "antd";
  
export const ModalDownloadData = (registers) => {

  const dispatch = useDispatch()  
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(formattedDate);
  const { pagination, filterData } = useSelector(state => state.dispersion)
  const { page, limit, total } = pagination
  const [reload, setReload] = useState(false);


  useEffect(async () => {  
    if (reload ) {
      await dispatch(dispersionActions.getDispersionList({ page, limit, filterData }))
      setReload(false)
    } 
  }, [reload]) 

  const handleDownload = async () => {
    // Puedes usar la fecha ingresada en el campo de texto aquí
    let answer = window.confirm("Desea confirmar que este(os) pago(s) ya fue(ron) aprobado(s) por el(los) banco");
    if (answer) {
      let today_piv = new Date(startDate);
      let date_up = today_piv.toISOString().split('T')[0];
      let reg_piv = registers.carts; 
       // Utilizamos map() para crear un array de promesas
    const promises = reg_piv.map(async element => {
      if(element.status_id !== 8 ){
        let errors = element.metadata[0].error; 
        if(errors.length === 0){
          let updateData = {
            "status_id": 8,
            "dispersion_register_id": parseInt(element.id),
            "dispersed_at": date_up
          }; 
          await dispatch(dispersionActions.updateDisRegister({ updateData })); 
        } 
      } 
    });

    // Esperamos a que todas las promesas se resuelvan
    await Promise.all(promises); 
    await setReload(true);
    }
  };


  return (
    <Row>
      <Col>
        < >
          <label>Confirme que este(os) pago(s) ya fue(ron) aprobado(s) por el(los) banco</label>
        </ >
        <br />
        <Col>
          <div>
            <label htmlFor="fechaInput">Ingrese la fecha:</label>
            <DatePicker  style={{ 
                  marginRight:"15px"
                }} onChange={setStartDate} placeholder={startDate} />
            <button onClick={handleDownload} >Descargar Pago</button>
          </div>
        </Col>

      </Col>
    </Row>
  );
};
