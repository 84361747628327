import React, { useEffect, useState } from "react";
import { Steps, Input, Form, Select, Radio, Button, InputNumber } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";

import InfoBasic from "./components/InfoBasic";
import InfoSecond from "./components/InfoSecond";
import { userActions } from "../../../../services/User/UserSlice";
import { locationActions } from "../../../../services/Location/LocationSlice";
import StepNotification from "./components/StepNotification";
import OrganizationsRules from "./components/OrganizationsRules";

const EditUser = ({ id, handleCancel }) => {
  const { Step } = Steps;
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [current, setCurrent] = useState(0);
  const [selectCity, setSelectCity] = useState();
  const [selectCityBirth, setSelectCityBirth] = useState();
  const { locations } = useSelector((state) => state.location);
  const { id: user_id } = useParams();
  const { user } = useSelector((state) => state.user);

  const next = (values) => {
    if (current === 1) {
      let User = {
        user_id: data.id,
        name: data.name,
        lastname: data.lastname,
        birthday: data.birthday.format("YYYY-MM-DD"),
        birth_location: selectCityBirth[0].fullname,
        genre_id: data.genere_id,
        per_trib_type_id: data.per_trib_type_id,
        is_iva_responsable: false,
      };

      let localization = {
        location_id: selectCity[0].id,
        latitude: selectCity[0].latitude,
        longitude: selectCity[0].longitude,
        address: values.direction,
        address_data: values.direction,
        address_extra: values.direction,
      };

      let phone = {
        number: values.contac,
        extension: "57",
        country_id: 2,
        phone_type_id: 1,
      };



      let doc = {
        docid_type_id: data.docid_type_id,
        code: data.code.toString(),
        issue_date: data.issue_date.format("YYYY-MM-DD"),
        issue_location: selectCity[0].fullname,
      };
      let phone_val = null;
      if (user.phone != null) {
        phone_val = user?.phone?.id
      }
      else {
        phone = {
          entity_type: "user",
          entity_id: user_id,
          number: values?.contac,
          extension: "57",
          country_id: 2,
          phone_type_id: 1,
        }
      }
      let payload = {
        doc,
        User,
        localization,
        phone,
        id_phone: phone_val,//user?.phone.id,
        id_docid: user?.docid?.id,
        id_profile: user_id,
        id_localization: user?.localization?.id,
      };
      dispatch(userActions.editUser(payload));
      setCurrent(current + 1);
    } else {
      setCurrent(current + 1);
      setData({ ...data, ...values });
    }
  };

  const getLocationOnSearch = (e) => {
    if (e.length > 4) dispatch(locationActions.getLocations(e));
  };

  const getLocationOnChange = (e) => {
    const city = locations.filter((location) => location.id == e);

    setSelectCity(city);
  };

  return (
    <>
      <Steps current={current} size="">
        <Step title="Datos Generales" />
        <Step title="Datos Secundarios" />
        <Step title="Confirmación" />
      </Steps>

      <div>
        {
          {
            0: (
              <InfoBasic
                next={next}
                getLocationOnSearch={getLocationOnSearch}
                getLocationOnChange={getLocationOnChange}
                setSelectCityBirth={setSelectCityBirth}
                user={user}
              />
            ),
            1: (
              <InfoSecond
                next={next}
                setSelectCity={setSelectCity}
                getLocationOnSearch={getLocationOnSearch}
                getLocationOnChange={getLocationOnChange}
                user={user}
              />
            ),
            2: <StepNotification handleCancel={handleCancel} />,
          }[current]
        }
      </div>
    </>
  );
};

export default EditUser;
