import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  specialists: [],
  specialist: undefined,
  specialistTickets: [],
  status: null,
  errormsg: null,
  specialistInfo: null,
  loading: false,
  filter: {},
  pagination: {
    total: 10,
    page: 1,
    limit: 10
  }
}

const specialistSlice = createSlice({
  name: "specialist",
  initialState: initialState,
  reducers: {
    getSpecialists() { },
    setSpecialists(state, { payload }) {
      state.specialists = payload.data
      state.pagination = payload.pagination
      state.filter = payload.filter
    },
    getSpecialist() { },
    setSpecialist(state, { payload }) {
      state.specialist = payload
    },
    getSpecialistTickets() { },
    setSpecialistTickets(state, { payload }) {
      state.specialistTickets = payload
    },
    createSpecialist() { },
    onChangeStatus(state, { payload }) {
      state.status = payload
    },
    onError(state, { payload }) {
      state.status = 'error'
      state.errormsg = payload
    },
  }
})

const specialistActions = specialistSlice.actions
const specialistReducer = specialistSlice.reducer

export { specialistActions, specialistReducer }