import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: null,
  errormsg: null,
  loading: false,
  specialities: [],
  subSpecialities: []
}

const specialitySlice = createSlice({
  name: "speciality",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload
    },
    onError(state, { payload }) {
      state.status = 'error'
      state.errormsg = payload
    },
    getSpecialities() { },
    setSpecialities(state, { payload }) {
      state.specialities = payload
    },
    getSubSpecialities() { },
    setSubSpecialities(state, { payload }) {
      state.subSpecialities = payload;
    }
  }
})

const specialityActions = specialitySlice.actions
const specialityReducer = specialitySlice.reducer

export { specialityActions, specialityReducer }