import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Descriptions, Row, Spin, Typography, List, Timeline, Button, Modal, Table, Col } from "antd";

import ApiService from "../../../../common/api/Api"
import { dispersionActions } from "services/Dispersion/DispersionSlice";
import { columns, columnsBreackdown, columnsDispersion, columnsDispersionData, columnsDispersionHistory } from "../constans";
import PDF from "../pdf_gen/PDF";
import { pdf, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { ModalStateDetailData } from "./ModalStateDetailData";

export const ModalStateData = (stateData) => {

  const dispatch = useDispatch()
  const { dispersionSingle, status, pagination } = useSelector(state => state.dispersion)
  const { page, limit, total } = pagination
  const [isModalVisible, setIsModalVisible] =
  useState(false);
  const [stateAc, setStateAc] =
  useState(false);
  const [userinfo, setUserInfo] = useState({
    regIds: [],
    response: [],
  }); 
  const [id, setId] = useState();
  const [fileList, setFileList] = useState([]);


  const handlePagination = (page, limit) => {
  }

  const tablePagination = {
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    hideOnSinglePage: true,
    current: page,
    pageSize: limit,
    total
  }
  useEffect(() => {  
     if (!dispersionSingle ) { 
       let cart = stateData.stateData[0].dis_reg.shoppingCartId ? stateData.stateData[0].dis_reg.shoppingCartId : 0;
       let codeGen = stateData.stateData[0].dis_reg.consecutiveDisGen;
       dispatch(dispersionActions.getDispersionListSingle({ cart_id: cart ,code_gen : codeGen}))
       //se agrega a la lista  
     }   
  }, [dispersionSingle, dispatch, stateData])
  
  useEffect(() => {  
      dispatch(dispersionActions.setDispersionSingle([])) 
  }, [])
  const currentColumn = columnsBreackdown(isModalVisible, setIsModalVisible, stateAc, setStateAc, userinfo, setUserInfo,id,setId,fileList, setFileList);
  
  
  const handleOk = () => {
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setStateAc(false);
  };

  return (
    <div>
      
      <Spin spinning={status === "loading"}>
        <Table
          title={() =>
            <> 
            </>
          }
          columns={currentColumn}
          dataSource={dispersionSingle}
          scroll={{ x: 400 }}
          pagination={tablePagination}
        />
      </Spin>
      {isModalVisible &&
        <Modal
          title={"Informacion de dispersion"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1100}
          footer={null}
        >
          <ModalStateDetailData //vista del documento pdf
            stateData={stateAc}
          />
        </Modal>
      }
    </div>
  );
};
