import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { getColumnSearchProps } from '../../../common/utilities/getColumnSearchProps'
import { propertyActions } from '../../../services/Property/PropertySlice'
import { ShowButton } from '../components/ShowButton/ShowButton'


export const columns = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { pagination, filter } = useSelector(state => state.property)
    const { limit } = pagination

    return [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Título',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Ubicación',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Acciones',
            key: 'action',
            dataIndex: 'id',
            width: '10%',
            render: (id) => (
                <ShowButton onClick={() => history.push(`/property/${id}`)} />
            )
        }
    ]
}

export const tablePagination = () => {
    const dispatch = useDispatch()
    const { pagination, filter } = useSelector(state => state.property)
    const { page, total } = pagination
    const { limit } = pagination

    const handlePagination = (page, limit) => {
        dispatch(propertyActions.getProperties({ page, limit, filter }))
    }

    return {
        onChange: handlePagination,
        hideOnSinglePage: true,
        current: page,
        pageSize: limit,
        total
    }
}