import React, { useState, useEffect } from "react";
import { Typography, Form, InputNumber, Button, Checkbox, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { propertyActions } from "../../../../../services/Property/PropertySlice";

export const StepPayProperty = ({ next }) => {
  const dispatch = useDispatch();
  const { Title } = Typography;
  const { form } = Form.useForm;
  const [id, setId] = useState();
  const [Admin, setAdmin] = useState(true);

  const { propertyInfo } = useSelector((state) => state.property);

  useEffect(() => {
    if (propertyInfo?.id) {
      setId(propertyInfo?.id);
    }
    console.log(id);
  }, [id]);

  const onChange = (e) => {
    if (e.target.value === 1) {
      setAdmin(true);
    }
    if (e.target.value === 2) {
      setAdmin(false);
    }
  };

  const onFinish = (values) => {
    let pets = {
      is_checked: true,
      policy_type_id: values.pets,
    };
    if (Admin) {
      dispatch(
        propertyActions.setPay({
          pay: [
            {
              cost_type: { id: values.ValorAdmin },
              currency: { id: 2 },
              value: values.ValorAlquiler,
            },
          ],
          Id: id,
          pets,
        })
      );
    } else {
      dispatch(
        propertyActions.setPay({
          pay: [
            {
              cost_type: { id: 1 },
              currency: { id: 2 },
              value: values.ValorAlquiler,
            },
            {
              cost_type: { id: 2 },
              currency: { id: 2 },
              value: values.ValorAdministracion,
            },
          ],
          Id: propertyInfo.id,
          pets,
        })
      );
    }
    next();
  };

  return (
    <div>
      <Title style={{ marginTop: 20, marginBottom: 20 }} level={5}>
        Política de mascotas
      </Title>

      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="pets"
          label="¿Permite Mascotas?"
          rules={[{ required: true, message: "Selecciona una opción" }]}
        >
          <Radio.Group>
            <Radio value={2}>Si</Radio>
            <Radio value={1}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Title style={{ marginTop: 20, marginBottom: 20 }} level={5}>
          Costos
        </Title>

        <Form.Item
          name="ValorAdmin"
          label="¿El valor de alquiler incluye administración?"
        >
          <Radio.Group>
            <Radio onChange={(e) => onChange(e)} value={1}>
              Si
            </Radio>
            <Radio onChange={(e) => onChange(e)} value={2}>
              No
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="ValorAlquiler"
          label="Valor del Alquiler"
          rules={[{ required: true, message: "Ingrese un Numero" }]}
        >
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: 150 }}
          />
        </Form.Item>

        {Admin ? null : (
          <Form.Item
            name="ValorAdministracion"
            label="Valor de Administración"
            rules={[{ required: true, message: "Ingrese un Numero" }]}
          >
            <InputNumber
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              style={{ width: 150 }}
            />
          </Form.Item>
        )}

        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Siguiente
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
