import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";  
import { ConfigModal } from "./modals/ConfigModal";


const ContractConfigurations = () => {
  const dispatch = useDispatch();
  const { contract } = useSelector((state) => state.contract);   
  useEffect(() => { 
    console.log(contract);
  }, [contract])

  return (
    <div className=" "> 
      {<ConfigModal contract={contract} />}
    </div>
  );
}; 
export default ContractConfigurations;
