
import { useState } from 'react';
import { Card, Modal,Descriptions } from 'antd';
import { useSelector } from 'react-redux';
import { CalendarOutlined } from '@ant-design/icons'   


export const Configuration = () => { 
    const { companyAd } = useSelector((state) => state.company);

    // Parsear la metadata si está disponible y es un JSON válido
    let metadata = {};
    try {
        metadata = companyAd?.metadata ? JSON.parse(companyAd.metadata) : {};
    } catch (error) {
        console.error('Error parsing metadata', error);
    }

    // Validar si la imagen es Base64 o URL
    const getImageSource = () => {
        const logo = metadata?.img || metadata?.img; 
        if (logo) {
            // Verifica si es Base64 o una URL regular
            if (logo.startsWith('data:image/')) {
                return logo; // Es Base64
            } else {
                return logo; // Es una URL normal
            }
        }
        return null;
    };

    const imageSource = getImageSource();

    return (
        
        <Card title="" bordered={false}>
            <Descriptions bordered layout="vertical" column={1}>
                 <Descriptions.Item label="Company Logo"> 
                    {console.log(imageSource)}
                    {imageSource ? (
                        <img src={imageSource} alt="" style={{ width: '150px', height: 'auto' }} />
                    ) : (
                        'No logo available'
                    )}
                </Descriptions.Item>

                <Descriptions.Item label="Nombre">
                    {companyAd?.name || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Configuracion de pagos">
                    {companyAd?.payment_settings || 'N/A'}
                </Descriptions.Item> 
                <Descriptions.Item label="Correo de contacto (adicional)">
                    {companyAd?.email_contact || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Telefono de contacto (adicional)">
                    {companyAd?.phone_contact || 'N/A'}
                </Descriptions.Item>
            </Descriptions>
        </Card>
    );
}