export const STATUS = [
  { id: 1, name: "open", label: "Abierto" },
  { id: 2, name: "in_progress", label: "En progreso" },
  { id: 3, name: "stopped", label: "Detenido" },
  { id: 4, name: "canceled", label: "Cancelado" },
  { id: 5, name: "re_opened", label: "Reabierto" },
  { id: 6, name: "closed", label: "Cerrado" },
  { id: 7, name: "rejected", label: "Rechazado" },
  { id: 8, name: "accepted", label: "Aceptado" },
  { id: 9, name: "rescheduled", label: "Reprogramado" },
  { id: 10, name: "signed", label: "Firmado" },
  { id: 11, name: "in_payment", label: "En pago" },
  { id: 12, name: "payment", label: "Pago" },
  { id: 13, name: "delivered", label: "Entregado" },
  { id: 14, name: "returned", label: "Regresado" },
  { id: 15, name: "in_deliver", label: "En entrega" },
  { id: 16, name: "in_return", label: "En regreso" },
  { id: 17, name: "delivered_not_inventory", label: "Entregado sin inventario" },
  { id: 18, name: "dwaiting_pay_gateway", label: "Espera de respuesta de la pasarela" },
]

export const getStatus = (id) => STATUS.find(s => s.id === id)