
import React from 'react';
import {
  Document,
  Text,
  Page,
  StyleSheet,
  Image,
  View,
} from "@react-pdf/renderer";
import TableRow from './TableRow';
import ItemsTable from './ItemsTable';
import TableFooters from './TableFooters';
import { formmatterCurrency } from "common/utilities";

const formatter = (input) =>
  new Intl.NumberFormat("en", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
  })
      .format(input)
      .replace("COP", "$")
      .replace(/,b/gi, ".");

// Estilos para el documento PDF
const styles = StyleSheet.create({
  page: { padding: 30 },
  header: { fontSize: 16, textAlign: 'center', marginBottom: 10 },
  subHeader: { fontSize: 10, textAlign: 'center', marginBottom: 5 },
  section: { marginBottom: 10, paddingTop:20 },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: '#000',
    marginBottom: 20,
  },
  tableRow: { flexDirection: "row" },
  tableCol: { width: "20%", borderStyle: "solid", borderWidth: 1, borderColor: '#000', padding: 5 },
  tableCell: { fontSize: 8, textAlign: 'center' },
  totalText: { fontSize: 10, textAlign: 'right', marginTop: 5 }
});

const getisYimg = (data) => {
  let logo_url = data.company_image;
  let response = false;
  if (logo_url.includes("v4A4+17r8F+E+AAQBvR7GbWNuJLAAAAABJRU5ErkJggg==")) {
    response = true;
  }
  return response;
}
const getimg = (data) => {
  let logo_url = data.company_image;
  if (logo_url.includes("v4A4+17r8F+E+AAQBvR7GbWNuJLAAAAABJRU5ErkJggg==")) {
    logo_url = data.company_name;
  }
  return logo_url;
}

function PDF({ data }) {
  return (<Document>
    <Page style={styles.page}>
      {/* Header */}
      <Text style={styles.header}>YAMPI INMOBILIARIA S.A.S</Text>
      <Text style={styles.subHeader}>NIT 901.365.856-2</Text>
      <Text style={styles.subHeader}>Tel: 7359071 - Ext. 107</Text>
      <Text style={styles.subHeader}>Armenia - Colombia</Text>

      {/* Cliente y Factura Info */}
      <View style={styles.section}>
        <Text>Señores :  {data.full_name}</Text>
        <Text>Nit / C.C : {data.id_code}</Text>
        <Text>Direccion : {data.address}</Text>
        <Text>Ciudad : {data.city}</Text>
        <Text>Telefono : {data.phone}</Text>
      </View>

      {/* Tabla de Items */}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}><Text style={styles.tableCell}>Ítem</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>Descripción</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>Cantidad</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>Vr. Unitario</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>Vr. Total</Text></View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}><Text style={styles.tableCell}>1</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{data.prop_title}</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>1.00</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{formatter(data.value_bill)}</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{formatter(data.value_bill)}</Text></View>
        </View> 
      </View>

      {/* Totales */}
      <Text style={styles.totalText}>Total Bruto: {formatter(data.value_bill)}</Text> 
      <Text style={styles.totalText}>Total a Pagar: {formatter(data.value_bill)}</Text>
      <View style={styles.section}>
      </View >
      {/* Condiciones y Observaciones */}
      <View style={styles.section}>
        <Text>Condiciones de Pago:</Text>
        <Text></Text>
        <Text></Text>
        <Text>Observaciones:</Text>
        <Text></Text>
      </View>
    </Page>
  </Document>);
}

export default PDF;
