import React, { useEffect, useState } from 'react'
import { Filter } from './sections/Filter'
import { Button, Modal, PageHeader, Spin, Table } from 'antd'
import { columns, tablePaginationBill } from './sections/constans';
import { useSelector } from 'react-redux'; 
import { ModalDetailData } from './sections/pdf_bill_prop/ModalDetailData';

export const BillingProp = () => {
 
  const currentTablePagination = tablePaginationBill();
  const { paginationProp,listProp, status } = useSelector(state => state.billing);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bill, setBill] = useState(false);
  const { page,limit } = paginationProp

  const [stateData, setStateData] =
  useState(false);
  const [valueBill, setValueBill] =
  useState(false);
  const [regenVal, setRegenVal] =
  useState(false); 
  const [fullData, setFullData] =
  useState(false); 

  const currentColumn = columns(isModalVisible, setIsModalVisible, stateData, setStateData,valueBill, setValueBill,regenVal, setRegenVal,fullData, setFullData);
  const handleOk = async () => { 
  };

  const handleCancel = async () => {
    setIsModalVisible(false);
  };


  useEffect(async () => {
    if (regenVal != false) {
      console.log(regenVal);
      let answer = window.confirm("Desea Regenerar la informacion de facturacion de este elemento?");
      if (answer) {

       //let updateData = {
       //  "shopping_cart": regenerateData.shoppingCartId ? parseInt(regenerateData.shoppingCartId) : 0,
       //  "contract_id": parseInt(regenerateData.contractId),
       //  "code_gen": regenerateData.consecutiveDisGen ? parseInt(regenerateData.consecutiveDisGen) : 0
       //}
       //await dispatch(dispersionActions.regenDataDisRegister({ updateData }))
       //if (startDate) {
       //  let filterData = {
       //    period: startDate,
       //  };
       //  if (inmo != "Todos") {
       //    filterData.context = inmo;
       //  }
       //  if (userEmail !== "" && userEmail !== undefined && userEmail !== null) {
       //    filterData.user_email = userEmail;
       //  }
       //  //console.log('Filter Data:', filterData); 
       //  //await dispatch(dispersionActions.getDispersionList({ page, limit, filterData: filterData }))
       //  setError('');
       //}
      }
      //setDisDownload(null);
    }
  }, [regenVal])

  return (
    <div className="sysblueprint">
      <PageHeader
        className="site-page-header"
        title=""
        style={{ color: '#888888', paddingLeft: 0 }}
      >
        <Filter />
      </PageHeader>
      <Spin spinning={status === "loading"}>
        <Table
          title={() =>
            <>
            </>
          }
          columns={currentColumn}
          dataSource={listProp}
          pagination={currentTablePagination}
          scroll={{ x: 400 }}
        />
      </Spin>
      {isModalVisible &&
        <Modal
          title={"Informacion de factura"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1100}
          footer={null}
        >
          <ModalDetailData //vista del documento pdf
            stateData={stateData}
            valueBill={valueBill}
            fullData={fullData}
          />
        </Modal>
      }
    </div>
  )
}