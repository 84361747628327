import { put, all, takeLatest } from 'redux-saga/effects'
import ApiService from '../../common/api/Api'
import { permissionActions } from './PermissionSlice'
import { message } from 'antd'

function* getUsers({ payload }) {
  yield put(permissionActions.onChangeStatus('loading'))
  try {
    const response = yield ApiService.post('/admin/user-filter', { query: payload, role_id: 4 })
    const data = response.data.map((user, index) => ({
      ...user,
      key: index,
      fullname: `${user?.profile?.name} ${user?.profile?.lastname}, ${user?.email}`,
      name: user?.profile?.name,
      lastname: user?.profile?.lastname,
      location: user?.localization?.location?.fullname,
      genre: user?.profile?.genre_id === 1 ? 'Masculino' : user?.profile?.genre_id === 2 ? 'Femenino' : 'Indefinido',
    }))

    yield put(permissionActions.setUsers(data))
    yield put(permissionActions.onChangeStatus('success'))
  } catch (err) {
    yield put(permissionActions.onError(err.toString()))
  }
}

function* getModules({ payload }) {
  yield put(permissionActions.onChangeStatus('loading'))
  try {
    const response = yield ApiService.get('/admin/module')
    yield put(permissionActions.setModules(response.filter((item, i) => (i < 5))))
    yield put(permissionActions.onChangeStatus('success'))
  } catch (err) {
    yield put(permissionActions.onError(err.toString()))
  }
}

function* setUserPermission({ payload }) {
  yield put(permissionActions.onChangeStatus('loading'))
  try {
    yield ApiService.get('/admin/module')
    yield ApiService.get('/admin/module')
    yield ApiService.get('/admin/module')
    yield ApiService.get('/admin/module')
    /* yield put(permissionActions.setModules(response.filter((item, i) => (i < 5)))) */
    message.success('Permiso agregado correctamente.')
    yield put(permissionActions.onChangeStatus('success'))
  } catch (err) {
    yield put(permissionActions.onError(err.toString()))
  }
}

function* ActionWatcher() {
  yield takeLatest(permissionActions.getUsers, getUsers)
  yield takeLatest(permissionActions.getModules, getModules)
  yield takeLatest(permissionActions.setUserPermission, setUserPermission)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ])
}
