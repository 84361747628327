import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Button, Table, Modal } from "antd";
import { useHistory, useParams } from "react-router";
import { contractActions } from "../../../../../../services/Contract/ContractSlice";
import { propertyActions } from "../../../../../../services/Property/PropertySlice";
import { getIconDescription } from "../../../../components/ItemDescription/IconDescripsion";
import { ShowButton } from "scenes/Private/components/ShowButton/ShowButton";
import { FiDelete, FiEdit } from "react-icons/fi";
import { formmatterCurrency } from "../../../../../../common/utilities";
import moment from "moment";
import EditContract from "../Edit/EditContract";

const RecordContractCollapse = ({ setIsModal, formater }) => {
  const history = useHistory();
  const { property } = useSelector((state) => state.property);
  const { contract,conDelete } = useSelector((state) => state.contract);
  const dispatch = useDispatch();
  const { id: ID } = useParams();
  const [isModalEditContract, setIsModalEditContract] = useState(false);

  useEffect(() => {
    console.log(conDelete);
    if(conDelete){
      const response1 = dispatch(propertyActions.getProperty(property.id));
      const response = dispatch(contractActions.setConDelete(false)); 
    }
  }, [conDelete]);

  const closeContract = (contract_id) => {
    let answer = window.confirm("Desea dar de baja el contrato, esto eliminara pagos no realizados y pondra la porpiedad habilitada para nuevo contrato?");
    if (answer) {
      let property_id = property.contract_initiate[0].id;
      //se da de baja el contrato
      const response = dispatch(contractActions.downContract({ id: property_id }));
    }
  };
  const contratcsColumns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Inquilino",
      dataIndex: "ownership",
      key: "ownership",
      render: (ownership) => ownership?.find(user => user.ownership_type_id == 2)?.user?.profile?.name + ownership?.find(user => user.ownership_type_id == 2)?.user?.profile?.lastname,
    },
    /*     {
          title: "Tipo de contrato",
          dataIndex: "contract_type_id",
          key: "contract_type_id",
          render: (contract_type_id) => getContractType(contract_type_id).label,
        }, */
    {
      title: "Inicio",
      dataIndex: "started_at",
      key: "started_at",
      render: (started_at) => (started_at == "1980-01-01 00:00:00") ? "Sin definir" : moment(started_at).format("LL"),
    },
    {
      title: "Final",
      dataIndex: "finished_at",
      key: "finished_at",
      render: (finished_at) => (finished_at == "1980-01-01 00:00:00") ? "Sin definir" : moment(finished_at).format("LL"),
    },
    {
      title: "Valor Contrato",
      dataIndex: "costs",
      key: "costs",
      render: (costs) => {
        var cost_total = 0;
        var text_cost = "";
        costs?.forEach(
          function (element) {
            cost_total = cost_total + element.value;
            switch (element.cost_type_id) {
              case 1:
                text_cost += " - Canon :" + formmatterCurrency(element.value) + "";
                break;
              case 2:
                text_cost += " - Administracion :" + formmatterCurrency(element.value) + "";
                break;
              case 12:
                text_cost += " - Contrato :" + formmatterCurrency(element.value) + "";
                break;
              case 8:
                text_cost += " - Canon+Administracion :" + formmatterCurrency(element.value) + "";
                break;
            }
          }
        );
        return formmatterCurrency(cost_total) + " [" + text_cost + "]";
      }

    },
    {
      title: "Estado",
      dataIndex: "status_id",
      key: "status_id",
      render: (record) => {
        switch (record) {
          case 6:
            return "Cancelado";
            break;
          // Puedes agregar más casos según sea necesario
          default:
            return "Activo";
            break;
        }
      }
    },
    {
      title: "Acciones",
      key: "action",
      dataIndex: "id,status_id",
      width: "10%",
      render: (id, record) => {
        let deleteButton = null;
        let updateButton = null;
        if (record.status_id >= 12) {
          deleteButton = (
            <ShowButton
              onClick={() => closeContract(record.id)}
              size="small"
              icon={<FiDelete />}
            />
          );
          updateButton = (
            <ShowButton
              onClick={() => { setIsModalEditContract(true) }}
              size="small"
              icon={<FiEdit />}
            />
          )
        }
        return (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <ShowButton
              onClick={() =>
                history.push(
                  `/property/${ID}/contract/${record.id}`
                )
              }
              size="small"
            />
            {updateButton}
            {deleteButton}
          </div>
        );
      }
    },
  ];

  return (
    <>
      <Table
        title={() => "Historial de contratos"}
        columns={contratcsColumns}
        dataSource={property?.all_contracts}
        pagination={{ pageSize: 5 }}
        size="large"
        bordered={true}
        aling="center"
        scroll={{ x: 400 }}
      />
      <Modal
            title={"EDITAR CONTRATO"}
            visible={isModalEditContract}
            onCancel={() => setIsModalEditContract(false)}
            footer={null}
            width={'50%'}
        >
            <EditContract setIsModalEditContract={setIsModalEditContract} />
        </Modal>
    </>
  );
};

export default RecordContractCollapse;
