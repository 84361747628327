import React, { useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { useTheme, ThemeContext, ThemeProvider } from 'styled-components';
import { env_production } from "common/config/Environments"


import { Public } from './Public/Public'
import { Private } from './Private/Private'
import { authActions } from '../services/Auth/AuthSlice'
import { NotificationProvider } from '../common/utilities/notification';

import cuiTheme from '../ui/type.d'

function App() {

  const { token } = useSelector(state => state.auth)
  const { company } = useSelector(state => state.user)
  const themeContext = useContext(ThemeContext)

  const theme = useTheme()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(authActions.checkIsAuth())
  }, [])

  useEffect(() => {

    if (company && company[0]) {
      try {
        const parsedData = JSON.parse(company[0]);
        const color = parsedData?.company_data?.metadata?.color[0];
        theme.colors.primary.main = color ? color : 'linear-gradient(180deg, #57157d 0%, #7923aa 99.99%, rgba(121, 35, 170, 0) 100%)';
      } catch (error) {
        console.error('Error parsing JSON:', error);
        theme.colors.primary.main = 'linear-gradient(180deg, #57157d 0%, #7923aa 99.99%, rgba(121, 35, 170, 0) 100%)';
      }
    } else {
      theme.colors.primary.main = 'linear-gradient(180deg, #57157d 0%, #7923aa 99.99%, rgba(121, 35, 170, 0) 100%)';
    }
  }, []);

  return (
    <NotificationProvider>
      {!env_production && <div style={{
        width: '100%', height: 'auto', background: '#0086ff', textAlign: 'center', color: 'white', fontsize: '18px', fontWeight: 'bold', padding: '8px'
      }}>Modo Desarrollo</div>}
      {!token ? <Public /> : <Private />}
    </NotificationProvider >
  )
}

export default App
