
const NotificationReducer = (
  state,
  event
) => {
  switch (event.type) {
    case 'OPEN_SNACKBAR':
      return { ...state, currentSnackbar: { isOpen: true, type: event.dialog, message: event.message, description: event.description } };
    case 'CLOSE_SNACKBAR':
      return {
        ...state,
        currentSnackbar: { ...state.currentSnackbar, isOpen: false },
      };
    default:
      return state
  }
};

export default NotificationReducer;
