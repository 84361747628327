import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons'
/* import { authActions } from 'services/Auth/AuthSlice'; */
/* mport { InputForm } from 'components/form/input/InputForm'; */
import { Modal, Button, Form, Input } from 'antd';


export const RecoverPassword = ({ closeModal }) => {
  const dispatch = useDispatch()
  const { company, loading } = useSelector(state => state.auth)

  const onSubmit = data => {
/*     dispatch(authActions.recoveryPassword(data))
 */    closeModal(false);
  }

  return (
    <div className="recover-password">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label="¿Cuál es tu correo electrónico?"
          tooltip="This is a required field"
          rules={[{ required: true, message: 'Por favor ingrese un correo válido!', type: 'email' }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Correo" />
        </Form.Item>
        {loading && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>}
      </Form>
    </div>
  )
}