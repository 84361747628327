import React, { useEffect, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons';
import { Button, Select, Menu, Upload, Input, Col } from 'antd';
import ApiService from "../../../../../common/api/Api";

const { TextArea } = Input;


export const ModalPayment = (props) => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];

  const { pay } = props;
  const [description, setDescription] = useState('');
  const [progress, setProgress] = useState(0);
  const [startDate, setStartDate] = useState(formattedDate);


  const formatter = (input) => new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
  }).format(input)
    .replace('COP', '$')
    .replace(/,b/gi, '.')

  const uploadFIle = async (options) => {
    const { file } = options;
    const fmData = new FormData();

    fmData.append("media", file);
    fmData.append("group", "pay_support");
    fmData.append("parent_id", pay?.id);

    ApiService.uploadImage(options, fmData, setProgress);
  };

  return (
    <>
      <span style={{ display: 'block', marginBottom: '20px' }}>Estas seguro que deseas cambiar el estado a pagado del pago #{pay.id} por valor de {formatter(pay.total_amount)}.</span>
      <Select
        placeholder={"Detalle del pago"}
        notFoundContent={null}
        style={{ width: "100%", marginBottom: '20px' }}
        onChange={(e) => {
          props.setStatePay({ ...pay, observation: e })
          setDescription(e)
        }
        }
      >
        <Option key={3} value={'Consignación cuenta bancaria Yampi'}>
          Consignación cuenta bancaria Yampi
        </Option>
        <Option key={4} value={'Pago en oficina de Inmobiliaria'}>
          Pago en oficina de Inmobiliaria
        </Option>
        <Option key={5} value={'Otro'}>
          Otro
        </Option>
      </Select>
      {description == 'Otro' && <TextArea onChange={(e) => props.setStatePay({ ...pay, observation: e.target.value })} rows={4} placeholder="Descripción" style={{ marginBtoom: '30px' }} />}
      <div style={{ marginBottom: '20px', display: 'block' }}></div>
      <Col>
      <label>
         Fecha de recaudo
      </label>
      <label>
        <input
          style={{
            padding: "5px 31px",
            height: "auto",
            fontSize: "medium",
          }}
          type="date"
          value={startDate}
          defaultValue={formattedDate}
          onChange={(e) => {props.setTransactionDate(e.target.value) ; setStartDate(e.target.value)}}
        />
      </label>
      <div style={{ marginBottom: '20px', display: 'block' }}></div>
      </Col>
      <Col>
      <Upload customRequest={uploadFIle}>
        <Button icon={<UploadOutlined />}>Subir archivo de soporte</Button>
      </Upload>
      </Col>
      
    </>
  )
}