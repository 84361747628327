import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Form, Input, Select, InputNumber, DatePicker, Row } from "antd";
import { useDispatch } from "react-redux";
import { contractActions } from "services/Contract/ContractSlice";
import moment from "moment";

export const ConfigModal = ({ visible, contract }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [havePolicy, setHavePolicy] = useState(contract?.have_policy);
  const [haveCobranza, setHaveCobranza] = useState(contract?.have_cobranza);
  const [isRenew, setIsRenew] = useState(contract?.is_renew);
  const [collectAdmin, IsCollectAdmin] = useState(contract?.collect_admin);
  const [sendMessageRent, IsSendMessageRent] = useState(contract?.send_message_rent);
  const [sendMessageOwne, IsSendMessageOwne] = useState(contract?.send_message_owne);
  const [sendMessageAdmin, IsSendMessageAdmin] = useState(contract?.send_message_admin);
  const [sendPaymentLink, IsSendPaymentLink] = useState(contract?.send_payment_link);

  const onFinish = (values) => {
    let date_in = values.moving_at;
    let date_up = date_in ? date_in.toISOString().split("T")[0] : null;
    let payload = {
      "contract_id": contract.id,
      "day_dis_gen": values.day_dis_gen,
      "have_policy": havePolicy,
      "have_cobranza": haveCobranza,
      "is_renew": isRenew,
      "collect_admin": collectAdmin,
      "send_message_rent": sendMessageRent,
      "send_message_owne": sendMessageOwne,
      "send_message_admin": sendMessageAdmin,
      "send_Payment_link": sendPaymentLink,
    };
    if (date_up) {
      payload.moving_at = date_up;
    }
    dispatch(contractActions.editContract({ payload }));
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="moving_at"
            label="Fecha en que se tomo el inmueble"
            initialValue={contract?.moving_at ? moment(contract?.moving_at) : null}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="day_dis_gen"
            label="Generar dispersion los dias (sin carrito pagado o generado)"
            initialValue={contract?.day_dis_gen}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Renovación de contrato">
            <Select
              value={isRenew}
              onChange={(value) => setIsRenew(value)}
              options={[
                { value: 0, label: "No ha notificado renovación o cancelación" },
                { value: 1, label: "Notificó renovación" },
                { value: 2, label: "Notificó cancelación" },
                { value: 3, label: "Contrato congelado" },
              ]}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>

        {/*<Col span={12}>
          <Form.Item label="Tiene póliza">
            <Select
              value={havePolicy}
              onChange={(value) => setHavePolicy(value)}
              options={[
                { value: false, label: "NO" },
                { value: true, label: "SI" },
              ]}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>*/}

        <Col span={12}>
          <Form.Item label="Tiene cobranza">
            <Select
              value={haveCobranza}
              onChange={(value) => setHaveCobranza(value)}
              options={[
                { value: 0, label: "NO" },
                { value: 1, label: "SI" },
              ]}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>

        {/*<Col span={12}>
          <Form.Item label="Recolecta administración">
            <Select
              value={collectAdmin}
              onChange={(value) => IsCollectAdmin(value)}
              options={[
                { value: 0, label: "NO" },
                { value: 1, label: "SI" },
              ]}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Envío mensaje a inquilino">
            <Select
              value={sendMessageRent}
              onChange={(value) => IsSendMessageRent(value)}
              options={[
                { value: 0, label: "NO" },
                { value: 1, label: "SI" },
              ]}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Envío mensaje a propietario">
            <Select
              value={sendMessageOwne}
              onChange={(value) => IsSendMessageOwne(value)}
              options={[
                { value: 0, label: "NO" },
                { value: 1, label: "SI" },
              ]}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Envío mensaje a administrador">
            <Select
              value={sendMessageAdmin}
              onChange={(value) => IsSendMessageAdmin(value)}
              options={[
                { value: 0, label: "NO" },
                { value: 1, label: "SI" },
              ]}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Envío de link de pago">
            <Select
              value={sendPaymentLink}
              onChange={(value) => IsSendPaymentLink(value)}
              options={[
                { value: 0, label: "NO" },
                { value: 1, label: "SI" },
              ]}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>*/}

        <Col span={24}>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Guardar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
