import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Spin } from 'antd'

import { propertyActions } from '../../../services/Property/PropertySlice'
import { ticketActions } from '../../../services/Ticket/TicketSlice'
import { TicketModal } from '../Property/PropertyDetail/Components/TicketModal/TicketModal'
import { getColumnSearchProps } from '../../../common/utilities/getColumnSearchProps'
import { ShowButton } from '../components/ShowButton/ShowButton'
import {mediaActions} from "../../../services/Media/MediaSlice";

export const Ticket = () => {

  const history = useHistory()

  const dispatch = useDispatch()
  const { properties, pagination, filter } = useSelector(state => state.property)
  const [ticket, setTicket] = useState()
  const { tickets, status } = useSelector(state => state.ticket)
  const { page, limit, total } = pagination
  const [selectedFile, setSelectedFile] = useState();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  const handleSubmission = () => {
    const formData = new FormData();
    formData.append('media', selectedFile);
    formData.append('group', "user_picture");
    formData.append('parent_id', 1);
    console.log('dededededededede')
    dispatch(mediaActions.upload({
      media:formData.media,
      group:"user_picture",
      parent_id:1
    } ))
    console.log('enddddddddd')
  };

  useEffect(() => {
    properties.length === 0 && dispatch(propertyActions.getProperties({ page, limit, filter }))
    dispatch(ticketActions.getTickets({ page, limit, filter }))
  }, [])

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Propiedad',
      dataIndex: '',
      key: 'title',
      render: (ticket) => {
        return (
          ticket?.property?.title
        )
      }
    },
    {
      title: 'Titulo',
      dataIndex: '',
      key: 'title',
      render: (ticket) => {
        return (
          ticket?.content[0]?.title
        )
      }
    },
    /* {
     title: 'Ubicación',
     dataIndex: 'location',
     key: 'location',
   },
   {
     title: 'Estado',
     dataIndex: 'status',
     key: 'status',
   },*//*  */
    {
      title: 'Acciones',
      key: 'action',
      dataIndex: 'property_id',
      width: '10%',
      render: id => (
        <ShowButton onClick={() => history.push(`/property/${id}`)} />
      )
    }
  ]

  const handlePagination = (page, limit) => {
    dispatch(ticketActions.getTickets({ page, limit, filter }))
  }

  const tablePagination = {
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    hideOnSinglePage: true,
    current: page,
    pageSize: 20,
    showSizeChanger: false,
    total
  }

  return (
    <div className="user">
      <div>
        <div>
          <input type="file" name="file" onChange={changeHandler} />
          <div>
            <button onClick={handleSubmission}>Submit</button>
          </div>
        </div>
      </div>
      <div className="user-header">
        Mantenimientos
      </div>
      <Spin spinning={status === "loading"}>
        {tickets &&
          <Table
            columns={columns}
            dataSource={tickets}
            pagination={tablePagination}
          />}
      </Spin>
      <TicketModal ticket={ticket} setTicket={setTicket} />
    </div>
  )
}