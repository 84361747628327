import { put, all, takeLatest } from 'redux-saga/effects'
import ApiService from '../../common/api/Api'
import { mediaActions } from './MediaSlice'

function* upload({ payload }) {
    try {
        console.log("media", payload)
        const response = yield ApiService.post(`/media`,payload)
        console.log("media", response)
    } catch (err) {
        yield put(mediaActions.onError('No se pudo realizar la accion'))
    }
}

function* getMedia({ payload }) {
    try {
        console.log(payload);
      const response = yield ApiService.get(`/media/${payload.entityId}/${payload.entityName}`)
      console.log("media", response)
      if (response) {
        yield put(mediaActions.setMediaFiles(response))
      }
    } catch (err) {
      yield put(mediaActions.onError(error.toString()));
    }
  }



function* ActionWatcher() {
    yield takeLatest(mediaActions.upload, upload)
    yield takeLatest(mediaActions.getMedia, getMedia)

}

export default function* () {
    yield all([
        ActionWatcher(),
    ])
}
