import { put, all, takeLatest } from "redux-saga/effects";
import ApiService from "../../common/api/Api";
import { userActions } from "./UserSlice";
import { authActions } from "../Auth/AuthSlice";
import { getStatus } from "../../common/constants/status";

function* getUsers({ payload }) {
  yield put(userActions.onChangeStatus("loading"));
  try {
    const { page, limit, filter, op, data: Data } = payload;
    const body = { ...filter, limit };
    const response = yield ApiService.post(
      `/admin/user-filter?page=${page}`,
      op === "get" ? body : Data
    );
    let { data, total } = response;
    data = response.data.map((user, index) => ({
      ...user,
      key: index,
      name: user?.profile?.name,
      lastname: user?.profile?.lastname,
      location: user?.localization?.location?.fullname,
      genre:
        user?.profile?.genre_id === 1
          ? "Masculino"
          : user?.profile?.genre_id === 2
            ? "Femenino"
            : "Indefinido",
    }));

    const pagination = { page, limit, total };
    yield put(userActions.setUsers({ data, pagination }));
    yield put(userActions.onChangeStatus("success"));
  } catch (err) {
    yield put(userActions.onError(err.toString()));
  }
}

function* getUser({ payload }) {
  yield put(userActions.onChangeStatus("loading"));
  try {
    const response = yield ApiService.post(`/admin/user-info/${payload}`);
    const data = {
      ...response,
      name: response?.profile?.name,
      lastname: response?.profile?.lastname,
      location: response?.localization?.location?.fullname,
      genre:
        response?.profile?.genre_id === 1
          ? "Masculino"
          : response?.profile?.genre_id === 2
            ? "Femenino"
            : "Indefinido",
      payments:
        response?.payments_admin.map((payment, index) => ({
          ...payment,
          status: getStatus(payment.status_id).label,
          key: index,
        })),
    };
    yield put(userActions.setUser(data));
    yield put(userActions.onChangeStatus("success"));
  } catch (err) {
    yield put(userActions.onError(err.toString()));
  }
}

function* getStateCount({ payload }) {
  yield put(userActions.onChangeStatus("loading"));
  try {
    const response = yield ApiService.get(`/admin/count-state/${payload}`);
    yield put(userActions.setStateCount(response));
    yield put(userActions.onChangeStatus("success"));
  } catch (err) {
    yield put(userActions.onError(err.toString()));
  }
}

function* changePassword({ payload }) {
  yield put(userActions.onChangeStatus("loading"));
  try {
    const response = yield ApiService.post(`/admin/change-password`, payload);
    yield put(userActions.onChangeStatus("success"));
    return response;
  } catch (err) {
    yield put(userActions.onError(err.toString()));
  }
}

function* fetchUser({ payload }) {
  yield put(userActions.onChangeStatus("loading"));
  try {
    const user = yield ApiService.post("/user-data");
    yield put(authActions.setUserData(user));
    yield put(userActions.onChangeStatus("success"));
  } catch (err) {
    yield put(userActions.onError(err.toString()));
  }
}

function* editUser({ payload }) {
  yield put(userActions.onChangeStatus("loading"));
  try {
    const user = yield ApiService.put(
      `/admin/docid/${payload.id_docid}`,
      payload.doc
    );
    const company = yield ApiService.put(
      `/admin/profile/${payload.id_profile}`,
      payload.User
    );
    const res = yield ApiService.post(
      `/localization/${payload.id_localization}/user`,
      payload.localization
    );
    //se valida si esta el telefono
    if (payload.id_phone == null) {
      const respon = yield ApiService.post(
        `/admin/phone`, payload.phone
      );
    } else {
      const respon = yield ApiService.put(
        `/admin/phone/${payload.id_phone}`,
        payload.phone
      );
    }


    yield put(authActions.setUserData(user));
    yield put(userActions.getUser(payload.id_profile));
    yield put(userActions.onChangeStatus("success"));
  } catch (err) {
    yield put(userActions.onError(err.toString()));
  }
}

function* createUser({ payload }) {
  yield put(userActions.onChangeStatus("loading"));

  try {
    const user = yield ApiService.post(`/admin/user`, payload);
    let company = {
      user_id: user.id,
      is_active: true,
      position: payload.role_id,
    };
    const res = yield ApiService.post(`/admin/com-user`, company);

    //yield put(authActions.setUserData(user));
    yield put(userActions.getUsers({ page, limit, filter }));
    yield put(userActions.onChangeStatus("success"));
  } catch (err) {
    yield put(userActions.onError(err.toString()));
  }
}

function* addCompanyUser({ payload }) {
  yield put(userActions.onChangeStatus("loading"));

  try {
    const res = yield ApiService.put(
      `/admin/company-user/${payload.user_id}`,
      payload.payload
    );

    /* yield put(authActions.setUserData(user)) */
    yield put(userActions.getUser(payload.user_id));
    yield put(userActions.onChangeStatus("success"));
  } catch (err) {
    yield put(userActions.onError(err.toString()));
  }
}

function* ActionWatcher() {
  yield takeLatest(userActions.getUsers, getUsers);
  yield takeLatest(userActions.getUser, getUser);
  yield takeLatest(userActions.fetchUser, fetchUser);
  yield takeLatest(userActions.editUser, editUser);
  yield takeLatest(userActions.createUser, createUser);
  yield takeLatest(userActions.addCompanyUser, addCompanyUser);
  yield takeLatest(userActions.getStateCount, getStateCount);
  yield takeLatest(userActions.changePassword, changePassword);
}

export default function* () {
  yield all([ActionWatcher()]);
}
