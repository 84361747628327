import { put, all, takeLatest } from "redux-saga/effects";
import ApiService from "../../common/api/Api";
import { specialistActions } from "./SpecialistSlice";
import { getPriority } from "../../common/constants/priority";
import { getStatus } from "../../common/constants/status";
import { message } from "antd";

function* getSpecialists({ payload }) {
  yield put(specialistActions.onChangeStatus("loading"));
  try {
    const { page, limit, filter } = payload;
    const body = { ...filter, limit, role_id: 5 };
    const response = yield ApiService.post(
      `/admin/user-filter?page=${page}`,
      body
    );
    console.log("getSpecialists", response);
    let { data, total } = response;
    data = response.data.map((user, index) => ({
      ...user,
      key: index,
      name: user?.profile?.name,
      lastname: user?.profile?.lastname,
      location: user?.localization?.location?.fullname,
      genre:
        user?.profile?.genre_id === 1
          ? "Masculino"
          : user?.profile?.genre_id === 2
          ? "Femenino"
          : "Indefinido",
    }));
    const pagination = { page, limit, total };
    yield put(specialistActions.setSpecialists({ data, pagination }));
    yield put(specialistActions.onChangeStatus("success"));
  } catch (err) {
    yield put(specialistActions.onError(err.toString()));
  }
}

function* getSpecialist({ payload }) {
  yield put(specialistActions.onChangeStatus("loading"));
  try {
    const response = yield ApiService.post(`/admin/user-info/${payload}`);
    console.log("getSpecialist", response);
    const data = {
      ...response,
      name: response?.profile?.name,
      lastname: response?.profile?.lastname,
      location: response?.localization?.location?.fullname,
      genre:
        response?.profile?.genre_id === 1
          ? "Masculino"
          : response?.profile?.genre_id === 2
          ? "Femenino"
          : "Indefinido",
      payments: response?.payments.map((payment, index) => ({
        ...payment,
        status: getStatus(payment.status_id).label,
        key: index,
      })),
    };
    yield put(specialistActions.setSpecialist(data));
    yield put(specialistActions.onChangeStatus("success"));
  } catch (err) {
    yield put(specialistActions.onError(err.toString()));
  }
}

function* getSpecialistTickets({ payload }) {
  yield put(specialistActions.onChangeStatus("loading"));
  try {
    const body = { user_id: payload };
    const response = yield ApiService.post(`/admin/specialist-tickets`, body);
    console.log("getSpecialistTickets", response);
    let { data, total } = response;
    data = response?.data?.map((ticket, index) => ({
      ...ticket,
      key: index,
      title: ticket?.content[0]?.title,
      status: getStatus(ticket?.status_id).label,
      created_at: ticket.created_at,
      priority: getPriority(ticket?.priority_id).label,
    }));
    yield put(specialistActions.setSpecialistTickets(data));
    yield put(specialistActions.onChangeStatus("success"));
  } catch (err) {
    yield put(specialistActions.onError(err.toString()));
  }
}

function* createSpecialist({ payload }) {
  console.log("paylocreateSpecialistad", payload);
  let { register, profile, specialties, callback } = payload;
  register["profile"]["country_id"] = 2;
  yield put(specialistActions.onChangeStatus("loading"));
  try {
    const resp = yield ApiService.post("/register/email", register);
    console.log("NEW USEWR", resp);

    profile.docId.docidable_id = resp.id;
    profile.phone.entity_id = resp.id;
    profile.localization.userId = resp.id;

    const { genre, docId, phone, localization } = profile;

    console.log("genre", yield ApiService.put(`/profile`, genre));
    console.log("docid", yield ApiService.put(`/docid`, docId));
    console.log("phone", yield ApiService.post(`/phone`, phone));
    console.log(
      "localization",
      yield ApiService.post(
        `/localization/${localization.userId}/user`,
        localization.body
      )
    );

    if (specialties)
      yield* specialties?.map(async function (item) {
        try {
          await ApiService.post("/speciality", item);
        } catch (e) {
          return null;
        }
      });

    yield put(specialistActions.getSpecialists(1));
    yield put(specialistActions.onChangeStatus("success"));
    message.success("Se creó el especialista correctamente!");
    callback();
  } catch (err) {
    console.log("error:", err);
    if (err?.data && Object.keys(err?.data).includes("email")) {
      message.error("El correo ya existe!");
    } else {
      message.error("Hubo un error al crear un especialista");
    }
    yield put(specialistActions.onError(err.message));
  }
}

function* ActionWatcher() {
  yield takeLatest(specialistActions.getSpecialists, getSpecialists);
  yield takeLatest(specialistActions.getSpecialist, getSpecialist);
  yield takeLatest(
    specialistActions.getSpecialistTickets,
    getSpecialistTickets
  );
  yield takeLatest(specialistActions.createSpecialist, createSpecialist);
}

export default function* () {
  yield all([ActionWatcher()]);
}
