import React, { useState, useEffect, Fragment } from "react";
import { Modal, Col, Row, Divider, Button, Form, Input, Select, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { feesActions } from "services/Fees/FeesSlice";
import { contractActions } from "services/Contract/ContractSlice";
import { shoppingActions } from "services/Shopping/ShoppingSlice";
export const StepFees = ({
  next,
  props
}) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const [form] = Form.useForm();
  const [loaded, setLoaded] = useState(0);
  const [isPercent, setIsPercent] = useState(0);
  const [isPercentInmo, setIsPercentInmo] = useState(0);
  const [isPercentSecure, setIsPercentSecure] = useState(0);
  const { contextData } = useSelector(state => state.fees);
  const [isChecked, setIsChecked] = useState(true);
  const { contract } = useSelector((state) => state.contract); 
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Invertir el valor actual del estado
  };

  useEffect(() => { 
    if (contextData !== undefined && contextData?.id) {
      // se asigna la nueva configuracion al contrato
      const pay = {
        payload: {
          contract_id: contract.id,
          contract_type_id: contract.contract_type_id,
          dispersion_context_id: contextData.id,
        },
      }
      dispatch(contractActions.editContract(pay));
      const res1 = dispatch(shoppingActions.getShoppingsProperty({ role: 0, propertyId: props }));
      next() 
    }  
  }, [contextData])
 
  const onFinish = (values) => { 
    let payload =
    {
      payload: {
        "entity_id": 6,
        "taxes": 0.0,
        "country_id": 2,
        "commission": parseFloat(values.commission),
        "commission_inmo": parseFloat(values.commission_inmo),
        "commission_secure": parseFloat(values.commission_secure),
        "name": "contract_conf_com_cus",
        "description": "Configuracion de tarifas del contrato",
        "metadata": { "base_inmo": true, "inmo_id": 0 },//depende si es nuevo o no
        "type": isPercent,
        "type_inmo": isPercentInmo,
        "type_secure": isPercentSecure,
        "accountant_billing_plan" : 4
      }, 
      data_id: contract.id
    } 
    dispatch(feesActions.createDispersionContext(payload)); 
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      {/*      <label>crear un nuevo registro de configuracion</label>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      /> */}
      <Row gutter={10}>
        <Col lg={24} style={{ marginBottom: 16 ,marginTop: 30}}>
          <span>Fee de Yampi</span>
        </Col>
        <Col>
          <Form.Item name="commission" initialValue={0.025}>
            <InputNumber />
          </Form.Item>
        </Col>
        <Col>
          <Select
            value={isPercent}
            onChange={(value) => setIsPercent(value)}
            options={[
              {
                value: 0,
                label: 'Porcentual',
              },
              {
                value: 1,
                label: 'Nominal',
              },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={10}>
        <Col lg={24} style={{ marginBottom: 16 }}>Fee inmobiliaria</Col>
        <Col>
          <Form.Item name="commission_inmo" initialValue={0}>
            <InputNumber />
          </Form.Item>
        </Col>
        <Col>
          <Select
            value={isPercentInmo}
            onChange={(value) => setIsPercentInmo(value)}
            options={[
              {
                value: 0,
                label: 'Porcentual',
              },
              {
                value: 1,
                label: 'Nominal',
              },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={10}>
        <Col lg={24} style={{ marginBottom: 16 }}>Fee Aseguradora</Col>
        <Col>
          <Form.Item label="" name="commission_secure" initialValue={0.024}>
            <InputNumber />
          </Form.Item>
        </Col>
        <Col>
          <Select
            value={isPercentSecure}
            onChange={(value) => setIsPercentSecure(value)}
            options={[
              {
                value: 0,
                label: 'Porcentual',
              },
              {
                value: 1,
                label: 'Nominal',
              },
            ]}
          />
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Guardar
        </Button>
        <label>  </label>
      </Form.Item>
    </Form>
  );
};
