import React, { useEffect, useReducer } from 'react';
import { notification } from 'antd';
import { appActions } from "services/App/AppSlice";

import {
  NotificationStateContext,
  NotificationDispatchContext,
} from './NotificationContext';
import NotificationReducer from './NotificationReducer';
import { useDispatch, useSelector } from 'react-redux';

const NotificationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NotificationReducer, {
    currentSnackbar: {
      isOpen: false,
      type: 'success',
    },
  });
  const { Snackbar } = useSelector((state) => state.app)
  const dispatchActions = useDispatch()

  useEffect(() => {
    if (state.currentSnackbar.isOpen)
      notification[state.currentSnackbar.type]({
        message: state.currentSnackbar.message,
        description:
          state.currentSnackbar.description,
        onClose: () => {
          dispatch({
            type: 'CLOSE_SNACKBAR',
          })
        }
      });
    if (Snackbar.type == "OPEN_SNACKBAR") {
      notification[Snackbar.dialog]({
        message: Snackbar.message,
        description:
          Snackbar.description,
        onClose: () => {
          dispatchActions(appActions.closeSnackbar())
        }
      });
    }
  }, [state.currentSnackbar, Snackbar])

  return (
    <NotificationStateContext.Provider value={state}>
      <NotificationDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
};

export default NotificationProvider;
