import { put, all, takeLatest, select } from 'redux-saga/effects';
/* import { Alert } from 'react-native'; */
import ApiService from '../../common/api/Api';
import { notificationActions } from './NotificationSlice';

function* getNotifications({payload}) {
 
  try {
    const res = yield ApiService.request(`/user/${payload.userId}/notification?page=${payload.page}&limit=${payload.limit}`)
    yield put(notificationActions.setNotifications(res.payload))
  } catch (err) {
    yield put(notificationActions.onError(err.toString()))
  }
}

function* ActionWatcher() {
  yield takeLatest(notificationActions.getNotifications, getNotifications)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ]);
}
