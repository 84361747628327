import React, { useEffect, useState } from 'react'
import { Filter } from './sections/Filter'
import { Button, Modal, PageHeader, Spin, Table } from 'antd'
import { columns, tablePaginationBill } from './constans';
import { useSelector } from 'react-redux';
import { ModalBillDetail } from './sections/ModalBillDetail';

export const Billing = () => {


  const currentTablePagination = tablePaginationBill();
  const { pagination,list, status } = useSelector(state => state.billing);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bill, setBill] = useState(false);
  const { page,limit } = pagination

  const currentColumn = columns(isModalVisible, setIsModalVisible, bill, setBill);
  const handleOk = async () => { 
  };

  const handleCancel = async () => {
    setIsModalVisible(false);
  };



  return (
    <div className="sysblueprint">
      <PageHeader
        className="site-page-header"
        title=""
        style={{ color: '#888888', paddingLeft: 0 }}
      >
        <Filter />
      </PageHeader>
      <Spin spinning={status === "loading"}>
        <Table
          title={() =>
            <>
            </>
          }
          columns={currentColumn}
          dataSource={list}
          pagination={currentTablePagination}
          scroll={{ x: 400 }}
        />
      </Spin>
      {isModalVisible &&
        <Modal
          title={""}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1100}
          footer={null}
        >
          <ModalBillDetail
            bill={bill}
          />
        </Modal>
      }
    </div>
  )
}