import React, { useState } from "react";
import { Radio, Row, Col, Select, Checkbox, Button, DatePicker, Input, Form } from 'antd';  
import { useDispatch, useSelector } from "react-redux";
import { sysBlueprintActions } from "services/SysBlueprint/SysBlueprintSlice";

export const Filter = () => { 
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    const dispatch = useDispatch();
    const { pagination } = useSelector(state => state.sysblueprint);
    const { limit, page } = pagination;
    const [startDate, setStartDate] = useState(formattedDate);

    const onFinish = (values) => { 
        if(values.entity_type == "inmo_error"){ 
            values.entity_type = null;
            values.service = "voucher%error";
        }
        values.date = startDate.toISOString().split('T')[0];
        dispatch(sysBlueprintActions.getList({ page: 1, limit, filter: { ...values } }));
    };

    return (
        <Form
            style={{ width: 500 }}
            onFinish={onFinish}
            layout="inline"
        >
            <Form.Item
                name="entity_type"
                rules={[
                    {
                        required: true,
                    },
                ]} 
            >
                <Select
                    style={{
                        width: 300,
                    }}
                    placeholder="entidad"
                    options={[
                        {
                            value: 'inmo',
                            label: 'Facturacion electronica general',
                        }, 
                        {
                            value: 'inmo_error',
                            label: 'Facturacion electronica error',
                        }, 
                        {
                            value: 'Property',
                            label: 'Propiedades',
                        }, 
                        {
                            value: 'ShoppingCart',
                            label: 'Carritos de compra',
                        }, 
                        {
                            value: 'Contract',
                            label: 'Contratos',
                        }, 
                        {
                            value: 'Cost',
                            label: 'Costos',
                        }, 
                        
                    ]}
                /> 
            </Form.Item>
            <DatePicker onChange={setStartDate} placeholder="Fecha" />
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Buscar
                </Button>
            </Form.Item>
        </Form>
    );
};
