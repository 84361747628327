import { put, all, takeLatest, select } from 'redux-saga/effects'; 
import { twilioActions } from './TwilioSlice';
import ApiService from '../../common/api/Api'

function* sendMessage({payload}) { 
  console.log("🚀 ~ file: TwilioSaga.js:7 ~ function*sendMessage ~ data:",payload)
  try { 
    yield ApiService.requestPost(`/twilio/message`, payload)  
  } catch (err) {
    yield put(twilioActions.onError(err.toString()))
  }
}

function* ActionWatcher() {
  yield takeLatest(twilioActions.sendMessage, sendMessage)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ]);
}
