
import { useState } from 'react';
import { Card, Form, Button, Checkbox, Typography, Space, Divider } from 'antd';
import { useSelector } from 'react-redux';
import { CalendarOutlined } from '@ant-design/icons'   
const { Title, Text } = Typography;

export const Messages = () => { 
    const { companyAd } = useSelector((state) => state.company);

    const [selectedMessage, setSelectedMessage] = useState('');
    const [selectedMessageReminder, setSelectedMessageReminder] = useState('');
    const [messagePayment, setMessagePayment] = useState('');
    const [messagePaymentReminder, setMessagePaymentReminder] = useState('');

    const [selectedMessageVal, setselectedMessageVal] = useState(0); 
    const [messagePaymentReminderVal, setMessagePaymentReminderVal] = useState(0);
    
  const handleCheckboxChange = (message,value) => {
    // Aquí se deseleccionan todas las otras opciones y se selecciona solo una
    setSelectedMessage(message);
    setMessagePayment(message);
    setselectedMessageVal(value);
  };

  const handleCheckboxChangeReminder = (message,value) => {
    // Aquí se deseleccionan todas las otras opciones y se selecciona solo una
    setSelectedMessageReminder(message);
    setMessagePaymentReminder(message);
    setMessagePaymentReminderVal(value);
  };

  const handleSubmit = () => {
    console.log('Mensaje seleccionado:', selectedMessageVal);
    console.log('Mensaje seleccionado:', selectedMessageReminder);
    
  };

  const messages = {
    m1: `Hola {name}, el próximo {date_start} arranca un nuevo mes de tu contrato de arrendamiento sobre la propiedad {prop_name}. 
            Recuerda que la fecha de pago va desde {date_start} hasta el {date_payment}; si {company} tiene recaudo electrónico, 
            a continuación encontrarás el enlace de pago: _`,
    m2: `Hola {name}, hoy inicia un nuevo mes de tu contrato de arrendamiento sobre la propiedad {prop_name}. Si {company} tiene recaudo electrónico,
            a continuación encontrarás el enlace de pago: _`,
    m3: `Hola {name}, el próximo {date_start} arranca un nuevo mes de tu contrato de arrendamiento sobre la propiedad {prop_name}. 
            Recuerda que la fecha de pago va desde {date_start} hasta el {date_payment}; si {company} tiene recaudo electrónico, 
            a continuación encontrarás el enlace de pago: https://web.yampi.co/pagos/wompi/{cart_id}`, 
  };

  const messagesReminder = { 
    r1: `Hola {name}, a la fecha no hemos recibido tu pago y la fecha máxima fue {date_payment}. Recuerda que después de esa fecha pueden aplicar penalidades y recargos, 
                    además de reportarte ante la aseguradora/afianzadora y centrales de riesgo por el incumplimiento.`,
    r2: `Hola {name}, recuerda que tienes un pago pendiente de tu contrato de arrendamiento sobre la propiedad {prop_name}. 
                    No olvides que tienes plazo máximo hasta {date_payment} sin penalidad.`
  };


  return (
    <Card title="Selecciona un mensaje" bordered={false} style={{ maxWidth: 600, margin: '0 auto' }}>
      <Form layout="vertical" onFinish={handleSubmit}>
        {/* Sección de Mensajes de Pago */}
        <Title level={4}>Mensajes de Pago</Title>
        <Space direction="vertical">
          <Checkbox
            checked={selectedMessage === messages.m1}
            onChange={() => handleCheckboxChange(messages.m1,1)}
          >
            Mensaje de Pago 1
          </Checkbox>
          <Checkbox
            checked={selectedMessage === messages.m2}
            onChange={() => handleCheckboxChange(messages.m2,2)}
          >
            Mensaje de Pago 2
          </Checkbox>
          <Checkbox
            checked={selectedMessage === messages.m3}
            onChange={() => handleCheckboxChange(messages.m3,3)}
          >
            Mensaje de Pago 3
          </Checkbox>
        </Space>

        {/* Visualización del mensaje seleccionado */}
        {messagePayment && (
          <>
            <Divider />
            <Title level={5}>Mensaje de Pago Seleccionado:</Title>
            <Text>{messagePayment}</Text>
          </>
        )}

        {/* Sección de Recordatorios */}
        <Divider />
        <Title level={4}>Mensajes Recordatorio</Title>
        <Space direction="vertical">
          <Checkbox
            checked={selectedMessageReminder === messagesReminder.r1}
            onChange={() => handleCheckboxChangeReminder(messagesReminder.r1,1)}
          >
            Recordatorio 1
          </Checkbox>
          <Checkbox
            checked={selectedMessageReminder === messagesReminder.r2}
            onChange={() => handleCheckboxChangeReminder(messagesReminder.r2,2)}
          >
            Recordatorio 2
          </Checkbox>
        </Space>

        {/* Visualización del mensaje de recordatorio seleccionado */}
        {messagePaymentReminder && (
          <>
            <Divider />
            <Title level={5}>Mensaje Recordatorio Seleccionado:</Title>
            <Text>{messagePaymentReminder}</Text>
          </>
        )}

        {/* Botón de Envío */}
        <Divider />
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            actualizar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
     
}