import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Radio,
  InputNumber,
  Alert,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { propertyActions } from "../../../../../services/Property/PropertySlice";

export const StepInforProperty = ({
  next,
  selectCity,
  getLocationOnChange,
  getLocationOnSearch,
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { locations } = useSelector((state) => state.location);
  const { company } = useSelector((state) => state.user);
  const [errorTuition, setErrorTuition] = useState(null);
  const { propertyInfo, errormsg } = useSelector((state) => state.property);

  useEffect(() => {
    propertyInfo?.id && next();
  }, [propertyInfo]);

  useEffect(() => {
    if (errormsg) {
      setErrorTuition(Object.keys(errormsg));
    }
    /*     setTimeout(() => {
          setErrorTuition(null);
          dispatch(propertyActions.onError(null));
        }, 3000); */
  }, [errormsg]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 0,
      },
    },
  };

  const onFinish = async (values) => {
    setErrorTuition(null);
    dispatch(
      propertyActions.setCurrentCreateProperty({
        title: values.name,
        cod_registration: values.enrollment,
        is_ph: values.ph,
        is_test: values.is_test || false,
        priority: values.priority || 1,
        property_type: { id: values.type },
        localization: {
          location: { id: selectCity[0].id },
          latitude: selectCity[0].latitude,
          longitude: selectCity[0].longitude,
          address: values.direction,
          address_data: values.direction,
          address_extra: values.direction,
        },
      })
    );
  };

  return (
    <div style={{ marginTop: "2em" }}>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label="Nombre de la Propiedad"
          rules={[{
            required: true,
            message: "Ingresa el nombre de la propiedad mayor a 10 caracteres",
            whitespace: true,
            min: 10
          }
          ]
          }
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="enrollment"
          label="Matricula inmobiliaria"
          rules={[
            {
              required: true,
              message: "Ingresa el número de matricula mayor a 10 caracteres",
              whitespace: true,
              min: 10,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {errorTuition?.find(err => err == "cod_registration") ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Alert
              type="error"
              style={{ width: "67%", marginBottom: "2em" }}
              message={"Matricula ya Registrada"}
              showIcon
              closable
            />
          </div>
        ) : null}

        <Form.Item
          name="type"
          label="Tipo de Propiedad"
          rules={[
            { required: true, message: "Selecciona el tipo de Propiedad" },
          ]}
        >
          <Select placeholder="Seleccione el tipo de propiedad">
            <Option value="1">Apartamento</Option>
            <Option value="2">Casa</Option>
            <Option value="3">Oficina</Option>
            <Option value="4">Local Comercial</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="ph"
          label="¿Es Propiedad Horizontal?"
          rules={[{ required: true, message: "Selecciona una opción" }]}
        >
          <Radio.Group>
            <Radio value={true}>Si</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>

        {company && (
          <Form.Item
            name="is_test"
            label="¿Es Propiedad de Prueba?"
            rules={[{ required: true, message: "Selecciona una opción" }]}
          >
            <Radio.Group>
              <Radio value={true}>Si</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        )}

        {company && (
          <Form.Item
            name="priority"
            label="Prioridad"
            rules={[{ required: true, message: "Selecciona una opción" }]}
          >
            <InputNumber max={10} min={1} />
          </Form.Item>
        )}

        <Form.Item
          name="location"
          label="Ciudad, Departamento"
          rules={[{ required: true, message: "Por favor ingrese el nombre!" }]}
        >
          <Select
            showSearch
            placeholder={"Ciudad, Departamento"}
            showArrow={false}
            filterOption={false}
            onChange={getLocationOnChange}
            onSearch={getLocationOnSearch}
            notFoundContent={null}
          >
            {locations.map((location) => (
              <Option key={location.id} data={location}>
                {location.fullname}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="direction"
          label="Dirección "
          placeholder={"Cra # cll"}
          rules={[
            {
              required: true,
              message: "Ingresa una dirección",
              whitespace: true,
            },
          ]}
        >
          <Input placeholder={"Cra cll #"} />
        </Form.Item>
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Siguiente
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
