
import React, { useEffect, useState } from "react";
import { Button, Spin, Table, Modal, Descriptions, Empty, Select } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { shoppingActions } from "services/Shopping/ShoppingSlice";
import { paymentsColumns, status_id, COST_TYPE } from "./constans"
import EditPayValue from "../../Components/Edit/EditPayValue";
import { ModalPayment } from "../../Components/ModalPayment";
import { ModalDiscount } from "../../Components/ModalDiscount";
import { ModalPay } from "../../Components/ModalPay";
import { formmatterCurrency } from "common/utilities";
import { contractActions } from "services/Contract/ContractSlice";
import { ModalTypePay } from "../../Components/ModalTypePay";

export const Payments = () => {
    const dispatch = useDispatch();
    const { property } = useSelector((state) => state.property)
    const [cartsProperty, setCartsProperty] = useState([]);
    const [statePay, setStatePay] = useState(undefined);
    const [transactionDate, setTransactionDate] = useState(undefined);
    const [isModalVisiblePayment, setIsModalVisiblePayment] = useState(false)
    const [isModalVisibleDiscount, setIsModalVisibleDiscount] = useState(false)
    const [isModalVisibleTypePay, setIsModalVisibleTypePay] = useState(false)
    const [currentPay, setCurrentPay] = useState(undefined);
    const [methodPay, setMethodPay] = useState(undefined);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [value, setValue] = useState("");
    const [dayPay, setDayPay] = useState("");
    const { isYampiAdmin } = useSelector((state) => state.user);


    const { contract } = useSelector((state) => state.contract);

    const [isModalEditPay, setIsModalEditPay] = useState({
        toggle: false,
        id: null,
    });
    const { shoppingsProperty, dispersion, status: statusShopping } = useSelector(
        (state) => state.shopping
    );

    const showModalEdit = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        dispatch(contractActions.editDayPay({ contractId: contract.id, day: value }))
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModal = (method, pay) => {
        setCurrentPay(pay);
        setMethodPay(method);
        setIsModalVisible(true);
    };

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const columns = paymentsColumns({ setIsModalVisiblePayment, setStatePay, setIsModalVisibleDiscount, setIsModalEditPay, showModal });
    let cartsPropertyTemp = [];


    const handleOkPay = () => {
        setIsModalVisiblePayment(false);
        dispatch(shoppingActions.insertDispersion(statePay));
        //se actualiza el pago
        dispatch(shoppingActions.editPay({valuePay: {
            transaction_date : transactionDate,
            shopping_cart_id: statePay.id,
          },
          propertyId: property.id,}));
          setTransactionDate(undefined)
    };

    useEffect(() => {
        if (
            shoppingsProperty &&
            shoppingsProperty?.carts &&
            shoppingsProperty?.carts[0]?.carts
        ) {
            shoppingsProperty?.carts[0].carts.map((cart) => {
                cartsPropertyTemp.push({
                    ...cart,
                    status: status_id[cart.status_id - 1],
                    key: cart.id
                });
            });
            setCartsProperty(cartsPropertyTemp);
        }
    }, [shoppingsProperty]);

    const handleOkDiscount = () => {
        setIsModalVisibleDiscount(false);
    };

    useEffect(() => {
        if (contract) {
            if (contract.gen_payment_at != null) {
                setDayPay(contract.gen_payment_at)
                setValue(contract.gen_payment_at)
            } else {
                setDayPay(moment(contract?.started_at).format("DD"))
                setValue(moment(contract?.started_at).format("DD"))
            }
        }
    }, [contract])

    const handleOkTypePay = () => {
        setIsModalVisibleTypePay(false); 
    };

    return (
        <>
            {contract ?
                <Descriptions
                    bordered
                    title="Informacion de Recaudos "
                    size={"small"}
                    style={{ marginBottom: 40 }}
                    extra={
                        <>
                            {isYampiAdmin && <Button type="primary" onClick={() => showModalEdit()} style={{ marginRight: 10 }}>Editar días</Button>}
                            <Button type="primary" onClick={() => { setIsModalVisibleTypePay(true)
                            }}>Generar recaudo </Button>
                        </>
                    }
                >
                    <Descriptions.Item label="Días de recaudos">{parseInt(dayPay)} - {parseInt(dayPay) + 4}</Descriptions.Item>
                    <Descriptions.Item label="Dias de notificación">3 días antes</Descriptions.Item>
                    {contract?.costs.map(cost =>
                        <Descriptions.Item label={COST_TYPE.find(costType => costType.id == cost.cost_type_id)?.name}>{formmatterCurrency(cost.value)}</Descriptions.Item>
                    )}

                </Descriptions> :
                <Empty description="Sin contrato" />
            }

            <Spin spinning={statusShopping === "loading"}>
                <Table
                    columns={columns}
                    dataSource={cartsProperty}
                    pagination={{ pageSize: 5, hideOnSinglePage: true }}
                    size="middle"
                    scroll={{ x: 400 }}
                />
            </Spin>
            {isModalEditPay?.toggle && <Modal
                title={"EDITAR VALOR DEL RECAUDO"}
                visible={isModalEditPay.toggle}
                onCancel={() => setIsModalEditPay(false)}
                footer={null}
                width={'40%'}
                afterClose={() => {
                    dispatch(
                        shoppingActions.setEditPay(undefined)
                    );
                }}
            >
                <EditPayValue cartsProperty={isModalEditPay} handleCancel={() => setIsModalEditPay(false)} />
            </Modal >}

            <Modal
                title={"Pago terceros"}
                visible={isModalVisiblePayment}
                onOk={handleOkPay}
                onCancel={() => setIsModalVisiblePayment(false)}
                width={500}
            >
                <ModalPayment pay={statePay} setStatePay={setStatePay} setTransactionDate={setTransactionDate}
                />
            </Modal>
            <Modal
                title={"Descuentos"}
                visible={isModalVisibleDiscount}
                onOk={handleOkDiscount}
                onCancel={() => setIsModalVisibleDiscount(false)}
                width={500}
            >
                <ModalDiscount pay={statePay} />
            </Modal>
            <Modal
                title={"Generar recaudo"}
                visible={isModalVisibleTypePay}
                onOk={handleOkTypePay}
                onCancel={() => setIsModalVisibleTypePay(false)}
                width={500}
            >
                <ModalTypePay contract={property?.contract_active[0]}  />
            </Modal>
            <ModalPay
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                methodPay={methodPay}
                currentPay={currentPay}
            />
            <Modal
                title="Editar fecha de recaudo"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}>
                {contract ?
                    <Descriptions
                        bordered
                        title="Informacion de Recaudos "
                        size={"small"}
                        style={{ marginBottom: 40 }}
                    >
                        <Descriptions.Item span={3} label="Día de inicio de recaudo">
                            <Select
                                showSearch
                                value={value}
                                placeholder={"Inmobiliaria"}
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={handleChange}
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={[
                                    { value: 1, label: '1' },
                                    { value: 2, label: '2' },
                                    { value: 3, label: '3' },
                                    { value: 4, label: '4' },
                                    { value: 5, label: '5' },
                                    { value: 6, label: '6' },
                                    { value: 7, label: '7' },
                                    { value: 8, label: '8' },
                                    { value: 9, label: '9' },
                                    { value: 10, label: '10' },
                                    { value: 11, label: '11' },
                                    { value: 12, label: '12' },
                                    { value: 13, label: '13' },
                                    { value: 14, label: '14' },
                                    { value: 15, label: '15' },
                                    { value: 16, label: '16' },
                                    { value: 17, label: '17' },
                                    { value: 18, label: '18' },
                                    { value: 19, label: '19' },
                                    { value: 20, label: '20' },
                                    { value: 21, label: '21' },
                                    { value: 22, label: '22' },
                                    { value: 23, label: '23' },
                                    { value: 24, label: '24' },
                                    { value: 25, label: '25' },
                                    { value: 26, label: '26' },
                                    { value: 27, label: '27' },
                                    { value: 28, label: '28' },
                                    { value: 29, label: '29' },
                                    { value: 30, label: '30' },
                                    { value: 31, label: '31' }
                                ]
                                }
                            />

                        </Descriptions.Item>
                        <Descriptions.Item span={3} label="Dias de notificación">3 días antes</Descriptions.Item>

                    </Descriptions> :
                    <Empty description="Sin contrato" />
                }
            </Modal>
        </>
    )
}