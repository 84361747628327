import React, { useState } from 'react'
import { DollarOutlined, RedoOutlined, BankOutlined, UploadOutlined, StarOutlined } from "@ant-design/icons";
import { AiOutlineRetweet, AiOutlineCarryOut } from "react-icons/ai";
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { formmatterCurrency } from "common/utilities";
import { ShowButton } from '../components/ShowButton/ShowButton'


import moment from 'moment'
import 'moment/locale/es'

export const columns = (isModalVisible, setIsModalVisible, cart, setCart, disDownload, setDisDownload, regenerateData, setRegenerateData, selectedRows, setSelectedRows, isModalUploadVisible, setIsModalUploadVisible) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { pagination } = useSelector(state => state.dispersion)
    const { page, limit } = pagination


    // Función para manejar la selección de filas
    const handleRowSelect = (row) => {
        const index = selectedRows.findIndex(selectedRow => selectedRow.id === row.id);

        // Si la fila ya está seleccionada, la quitamos del estado
        if (index !== -1) {
            const newSelectedRows = [...selectedRows];
            newSelectedRows.splice(index, 1);
            setSelectedRows(newSelectedRows);
        } else { // Si la fila no está seleccionada, la agregamos al estado
            setSelectedRows([...selectedRows, row]);
        }
    };

    return [
        {
            title: '',
            dataIndex: '',
            key: '',
            render: (data) => {
                let errors = data.metadata[0];
                if (errors) {
                    errors = data.metadata[0].error;
                    if (data.statusId != 8 && errors.length === 0) {
                        return <div>
                            <input
                                type="checkbox"
                                checked={selectedRows.some(selectedRow => selectedRow.id === data.id)}
                                onChange={() => handleRowSelect(data)}
                            />
                        </div>
                    }
                }
            }
        },
        {
            title: 'Propiedad/carrito',
            dataIndex: '',
            key: '',
            render: (data) => { 
                return <span onClick={() => history.push(`/property/${data.propertyId}`)} style={{ color: '#423d3e', marginBottom: '12px', fontSize: '14px', fontWeight: 500, cursor: 'pointer' }}> {data.metadata[0]?.prop_name} / {data.shoppingCartId} </span>
                    
                 
            }
        },
        {
            title: 'Nombre',
            dataIndex: '',
            key: '',
            render: (data) => {
                if (data.statusId != 3) {
                    let adminData = data.metadata[0]?.admin_data;
                    let dataUser = data.metadata[0]?.data_user;
                    let label = <span> </span>;
                    let adminData_val = data.metadata[0];
                    if (adminData_val) {
                        let adminDataT = data.metadata[0].type_dis;
                        switch (adminDataT) {
                            case 0:
                            case 1:
                                label = <span>  {dataUser?.profiles[0].name}</span>;
                                break;
                            case 2:
                                label = <span>  {adminData?.bankAccountDistribution.name}</span>;
                                break;
                            case 3:
                                label = "Dispersion personalizada";
                                break;
                            case 4:
                                label = "Pago a Yampi";
                                break;
                        }
                    }
                    return label

                } else {
                    return <span> </span>
                }
            }
        },
        {
            title: 'Apellido',
            dataIndex: '',
            key: 'user',
            render: (data) => {
                if (data.statusId != 3) {
                    let adminData = data.metadata[0]?.admin_data;
                    let dataUser = data.metadata[0]?.data_user;
                    let label = <span> </span>;
                    let adminData_val = data.metadata[0];
                    if (adminData_val) {
                        let adminDataT = data.metadata[0].type_dis;
                        switch (adminDataT) {
                            case 0:
                            case 1:
                                label = <span>  {dataUser?.profiles[0].lastname}</span>;
                                break;
                            case 2:
                                label = <span>  {adminData?.bankAccountDistribution.lastname}</span>;
                                break;
                            case 3:
                                label = "Dispersion personalizada";
                                break;
                            case 4:
                                label = "Pago a Yampi";
                                break;
                        }
                    }
                    return label
                }
            }
        },
        {
            title: 'Banco',
            dataIndex: '',
            key: '',
            render: (data) => {
                if (data.statusId != 3) {
                    let adminData = data.metadata[0]?.admin_data;
                    let dataUserBank = data.metadata[0]?.data_user_bank;
                    if (adminData) {
                        if (adminData.bankAccountDistribution?.bank?.name) {
                            return <span>  {adminData.bankAccountDistribution.bank.name}</span>
                        } else {
                            return <span></span>
                        }
                    } else {
                        if (dataUserBank) {
                            return <span>  {dataUserBank.bank.name}</span>
                        } else {
                            return <span> </span>
                        }
                    }
                } else {
                    return <span> </span>
                }
            }
        },
        /*{
            title: 'Tipo de Producto o Servicio	',
            dataIndex: '',
            key: '',
            render: (data) => {
                let type_bank_account = "";
                if (data.statusId != 3) {
                    if (data.userId == "1") {
                        let adminData = data.metadata[0].admin_data;
                        if (adminData != null) {
                            if (adminData.bankAccountDistribution.typeProdServ == 3 || adminData.bankAccountDistribution.typeProdServ == 1) {
                                type_bank_account = "Ahorros"
                            }
                            if (adminData.bankAccountDistribution.typeProdServ == 4 || adminData.bankAccountDistribution.typeProdServ == 2) {
                                type_bank_account = "Corriente"
                            }
                        }
                    } else {
                        if (data.bankAccount?.bankAccountTypeId == 3 || data.bankAccount?.bankAccountTypeId == 1) {
                            type_bank_account = "Ahorros"
                        }
                        if (data.bankAccount?.bankAccountTypeId == 4 || data.bankAccount?.bankAccountTypeId == 2) {
                            type_bank_account = "Corriente"
                        }
                    }
                }
                return <span>  {type_bank_account}</span>

            }
        },*/
        {
            title: 'Numero del Producto o Servicio',
            dataIndex: '',
            key: 'bankAccount',
            render: (data) => {
                let type_bank_account = "";
                if (data.statusId != 3) {
                    let adminData = data.metadata[0]?.admin_data;
                    let dataUserBank = data.metadata[0]?.data_user_bank;
                    if (adminData) {
                        if (adminData.bankAccountDistribution?.typeProdServ) {
                            if (adminData.bankAccountDistribution.typeProdServ == 1) {
                                type_bank_account = "Ahorros"
                            }
                            if (adminData.bankAccountDistribution.typeProdServ == 2) {
                                type_bank_account = "Corriente"
                            }
                            if (adminData.bankAccountDistribution.typeProdServ == 3) {
                                type_bank_account = "Convenio"
                            }
                        }
                    } else {
                        if (dataUserBank) {
                            if (dataUserBank.bankAccountTypeId == 3 || data.bankAccount?.bankAccountTypeId == 1) {
                                type_bank_account = "Ahorros"
                            }
                            if (dataUserBank.bankAccountTypeId == 4 || dataUserBank.bankAccountTypeId == 2) {
                                type_bank_account = "Corriente"
                            }
                            if (dataUserBank.bankAccountTypeId == 5 || dataUserBank.bankAccountTypeId == 6) {
                                type_bank_account = "Convenio"
                            }
                        }
                    }
                }

                if (data.statusId != 3) {
                    let adminData = data.metadata[0]?.admin_data;
                    let dataUserBank = data.metadata[0]?.data_user_bank;
                    if (adminData) {
                        if (adminData.bankAccountDistribution?.numProdServ) {
                            return <span>  {adminData.bankAccountDistribution.numProdServ}</span>
                        } else {
                            return <span></span>
                        }
                    } else {
                        if (dataUserBank) {
                            return <span><span>  {type_bank_account}</span> : <span>{dataUserBank.number}</span></span>
                        } else {
                            return <span> </span>
                        }
                    }
                } else {
                    return <span> </span>
                }
            }
        },
        {
            title: 'Valor total pago o de la recarga',
            dataIndex: '',
            key: '',
            render: (data) => {
                return <span>  {formmatterCurrency(data.valuePay)}</span>
            }
        },
        /*{
            title: 'Correo Electronico	',
            dataIndex: '',
            key: '',
            render: (data) => {
                if (data.statusId != 3) {
                    if (data.userId == "1") {
                        let adminData = data.metadata[0].admin_data;
                        if (adminData != null) {
                            return <span>  {adminData.bankAccountDistribution.email}</span>
                        } else {
                            return <span></span>
                        }
                    } else {
                        return <span>  {data.user?.email}</span>
                    }
                } else {
                    return <span> </span>
                }

            }
        },*/
        {
            title: 'Descripcion o Detalle',
            dataIndex: '',
            key: '',
            render: (data) => {
                let metadata = data.metadata;
                let label = "";
                let adminData = data.metadata[0];
                if (adminData) {
                    adminData = data.metadata[0].type_dis;
                    switch (adminData) {
                        case 0:
                            label = "Pago a Propietario";
                            break;
                        case 1:
                            label = "Pago a inmobiliaria";
                            break;
                        case 2:
                            label = "Pago a administracion";
                            break;
                        case 3:
                            label = "Dispersion personalizada";
                            break;
                        case 4:
                            label = "Pago a Yampi";
                            break;
                    }
                }
                //{(metadata[0]) ? metadata[0].error : ""}
                return <span> {label} </span>

            }
        },
        {
            title: 'Estado',
            dataIndex: '',
            key: '',
            render: (data) => {
                let label = "";
                switch (data.statusId) {
                    case 3:
                        label = "Faltan datos";
                        break;
                    case 2:
                        label = "En transferencia/ no realizado";
                        break;
                    case 12:
                    case 8:
                        label = "Pago realizado";
                        break;
                }
                return <span> {label}</span>

            }
        },
        {
            title: 'Acciones',
            key: 'action',
            dataIndex: '',
            width: '10%',
            render: (data) => {
                //  
                let show_upload = false;
                let adminData = data.metadata[0];
                if (adminData) {
                    adminData = adminData.type_dis;
                    switch (adminData) {
                        case 2:
                            show_upload = true;
                            break;

                    }
                }
                let label = "";
                // <ShowButton icon={<AiOutlineCarryOut  />} onClick={() => { setDisDownload(data); }} /> 
                switch (data.statusId) {
                    case 3:
                    case 2:
                        label = <>
                            <ShowButton onClick={() => { setIsModalVisible(true); setCart(data) }} />
                            <br></br>
                            <ShowButton icon={<AiOutlineRetweet />} onClick={() => { setRegenerateData(data); }} />
                        </>
                        break;
                    case 12:
                    case 8:
                        if (show_upload) {
                            label = <>
                                <ShowButton onClick={() => { setIsModalVisible(true); setCart(data) }} />
                                <br></br>
                                <ShowButton icon={<UploadOutlined />} onClick={() => { setIsModalUploadVisible(true); setCart(data) }} />
                            </>
                        } else {
                            label = <>
                                <ShowButton onClick={() => { setIsModalVisible(true); setCart(data) }} />
                            </>
                        }
                        break;
                }
                return label;
            }
        }
    ]
}


export const columnsDispersionHistory = () => {
    return [
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: '',
            dataIndex: 'concept',
            key: 'concept',
        },
        {
            title: '',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: '',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: '',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
    ]
}

export const columnsDispersionData = () => {
    return [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Valor de arriendo recaudado',
            dataIndex: 'valueTotal',
            key: 'valueTotal',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Fee yampi',
            dataIndex: 'valueCom',
            key: 'valueCom',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Fee inmobiliaria',
            dataIndex: 'valueInmo',
            key: 'valueInmo',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Fee aseguradora',
            dataIndex: 'valueSafeSecure',
            key: 'valueSafeSecure',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Valor a pagar/pagado',
            dataIndex: 'valuePay',
            key: 'valuePay',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Fecha creacion',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Pagado a',
            dataIndex: 'user',
            key: 'user',
            render: (value) => (
                <span>{value.profiles[0].name + " " + value.profiles[0].lastname}</span>
            )
        },
        {
            title: 'Email',
            dataIndex: 'user',
            key: 'user',
            render: (value) => (
                <span>{value.email}</span>
            )
        },
        {
            title: 'Cuenta bancaria',
            dataIndex: 'bankAccount',
            key: 'bankAccount',
            render: (value) => (
                <span>{value?.number + ' ' + value?.bank?.name}</span>
            )
        },
        {
            title: 'Fees usados',
            dataIndex: 'dispersionContext',
            key: 'dispersionContext',
            render: (value) => (
                <span>{
                    "Fee Yampi = " + value?.commission + ' ' +
                    "Fee inmobiliaria  = " + value?.commissionInmo + ' ' +
                    "Fee aseguradora  = " + value?.commissionSecure

                }</span>
            )
        },
    ]
}

