import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { getColumnSearchProps } from '../../../common/utilities/getColumnSearchProps'
import { propertyActions } from '../../../services/Property/PropertySlice'
import { ShowButton } from '../components/ShowButton/ShowButton'



export const columns = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { pagination, filter } = useSelector(state => state.sysblueprint)
    const { limit } = pagination

    return [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'type service',
            dataIndex: 'service',
            key: 'service'
        },
        {
            title: 'metadata inicial',
            dataIndex: '',
            key: '',
            render: (data) => {
                let data_old = JSON.stringify(data.metadataOld);
                return <span>{data_old}</span>
            }
        },
        {
            title: 'metadata final',
            dataIndex: '',
            key: '',
            render: (data) => {
                let data_new = JSON.stringify(data.metadataNew);
                return <span>{data_new}</span>
            }
        },
        {
            title: 'Fecha creacion',
            dataIndex: 'createdAt',
            key: 'createdAt',

        },

    ]
}

export const tablePagination = () => {
    const dispatch = useDispatch()
    const { pagination, filter } = useSelector(state => state.property)
    const { page, total } = pagination
    const { limit } = pagination

    const handlePagination = (page, limit) => {
        dispatch(propertyActions.getProperties({ page, limit, filter }))
    }

    return {
        onChange: handlePagination,
        hideOnSinglePage: true,
        current: page,
        pageSize: limit,
        total
    }
}